import React, { useState } from 'react';
import CustomPopover from '../../../ui/CustomPopover';
import DataGridColorPicker from '../../../ui/DataGridColorPicker';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { ASSET_DATA_TYPES, REDUX_ACTIONS } from '../../../../shared/config/constants';
import { FileUpload } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';

function MobileCanvasJSFooter({ field }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [assetDataType] = useState(
    searchParams.get('assetDataType') ?? ASSET_DATA_TYPES.ACTUATORDATA
  );
  const assetDataId = assetDataType.replace('ActuatorData_', '');
  const [filterId] = useState(searchParams.get('filterId') ?? '2');
  const { preferences } = useSelector((state) => state.currentUser);

  const handleColumnRange = (fieldValue, key) => {
    const val = {};
    val[key] = fieldValue;

    const newRange = {
      [field]: val,
    };

    const objRange = preferences.datachartSettings.columnsRange
      ? { ...preferences.datachartSettings.columnsRange }
      : newRange;
    if (preferences.datachartSettings.columnsRange) {
      if (objRange[field]) {
        objRange[field][key] = fieldValue;
      } else {
        objRange[field] = val;
      }
    }

    dispatch({
      type: REDUX_ACTIONS.SET_DATA_CHART_PREFERENCES,
      payload: {
        columnsRange: objRange,
      },
      filters: {
        assetDataId,
        filterId
      }
    });
  };

  const handleResetRange = (key) => {
    handleColumnRange(null, key);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <CustomPopover
        tootipTitle="min"
        onSubmit={handleColumnRange}
        onReset={handleResetRange}
        icon={<FileUpload />}
        isMobile
      />
      <CustomPopover
        tootipTitle="max"
        onSubmit={handleColumnRange}
        onReset={handleResetRange}
        icon={<FileUpload style={{ transform: 'rotate(180deg)' }} />}
        isMobile
      />
      <div
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <DataGridColorPicker
          key={`colorPicker_${field}`}
          field={field}
          filters={{ assetDataId, filterId }}
        />
      </div>
      <span style={{ color: 'black' }}>{field}</span>
    </Box>
  );
}

MobileCanvasJSFooter.propTypes = {
  field: PropTypes.string,
};

MobileCanvasJSFooter.defaultProps = {
  field: '',
};

export default MobileCanvasJSFooter;
