import { makeStyles } from '@mui/styles';

const styles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    }
  };
});

export default styles;
