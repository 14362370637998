import { createTheme } from '@mui/material';

// colors
const primary = '#000000';
const secondary = '#4829B2';
const black = '#343a40';
const darkBlack = 'rgb(36, 40, 44)';
const background = '#f0f0f0';
const warningLight = 'rgba(253, 200, 69, .3)';
const warningMain = 'rgba(253, 200, 69, .5)';
const warningDark = 'rgb(0, 146, 201)';
const redStatus = '#ca2f28';
const amberStatus = '#cb8e2b';
const greenStatus = '#42ca28';
const defaultStatus = '#267eca';

// Alarms Icon
const alarm0 = 'green';
const alarm1 = '#FFBF00';
const alarm2 = '#FFBF00';
const alarm3 = 'red';
const alarm4 = 'red';

// border
const borderWidth = 0;
const borderColor = 'rgba(0, 0, 0, 0)';

// breakpoints
const xl = 1920;
const lg = 1280;
const md = 960;
const sm = 600;
const xs = 0;

// spacing
const spacing = 8;

const theme = createTheme({
  props: {
    MuiTypography: {
      variantMapping: {
        h1: 'h1',
        h2: 'h1',
        h3: 'h1',
        h4: 'h2',
        h5: 'h3',
        h6: 'h4',
        subtitle1: 'h5',
        subtitle2: 'h5',
        body1: 'p',
        body2: 'p',
      },
    },
  },
  spacing,
  palette: {
    primary: { main: primary },
    secondary: { main: secondary },
    common: {
      black,
      darkBlack,
    },
    warning: {
      light: warningLight,
      main: warningMain,
      dark: warningDark,
    },
    defaultStatus: { main: defaultStatus },
    greenStatus: { main: greenStatus },
    amberStatus: { main: amberStatus },
    redStatus: { main: redStatus },
    graypart: { main: 'hsla(0, 0%, 25%)' },
    greenpart: { main: '#44d622' },
    lightgray: { main: '#f0f0f0' },
    noclassification: { main: 'hsla(0, 0%, 75%)' },
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
    background: {
      default: background,
      sidebar: 'rgba(0, 0, 0, 0.05)',
      content: '#fff',
      inputBackground: '#F3F3F3',
    },
    Authentication_Success: { main: '#42ca28' },
    alarm0: { main: alarm0 },
    alarm1: { main: alarm1 },
    alarm2: { main: alarm2 },
    alarm3: { main: alarm3 },
    alarm4: { main: alarm4 },
    darkIcons: {
      main: '#000',
    },
  },
  breakpoints: {
    // Define custom breakpoint values.
    // These will apply to Material-UI components that use responsive
    // breakpoints, such as `Grid` and `Hidden`. You can also use the
    // theme breakpoint functions `up`, `down`, and `between` to create
    // media queries for these breakpoints
    values: {
      xl,
      lg,
      md,
      sm,
      xs,
    },
  },
  border: {
    borderColor,
    borderWidth,
  },
  overrides: {
    MuiButtonBase: {
      '@global': {
        '.Mui-disabled': { opacity: 0.5 },
      },
    },
    PrivateTabIndicator: {
      colorSecondary: {
        backgroundColor: 'black',
      },
    },
    MuiExpansionPanel: {
      root: {
        position: 'static',
      },
    },
    MuiTableCell: {
      root: {
        paddingLeft: spacing,
        paddingRight: spacing,
        borderBottom: `${borderWidth}px solid ${borderColor}`,
        [`@media (max-width:  ${sm}px)`]: {
          paddingLeft: spacing,
          paddingRight: spacing,
        },
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: borderColor,
        height: borderWidth,
      },
    },
    MuiPrivateNotchedOutline: {
      root: {
        borderWidth,
      },
    },
    MuiListItem: {
      divider: {
        borderBottom: `${borderWidth}px solid ${borderColor}`,
      },
    },
    MuiDialog: {
      paper: {
        width: '100%',
        maxWidth: 430,
        marginLeft: spacing,
        marginRight: spacing,
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: darkBlack,
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        [`@media (max-width:  ${sm}px)`]: {
          paddingLeft: spacing,
          paddingRight: spacing,
        },
      },
    },
    MuiInputLabel: {
      root: {
        lineHeight: 0,
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: spacing,
        // Can set italic font to change placeholder,
        // but search text will also be italic.
        // fontStyle: 'italic',
        '&:-webkit-autofill': {
          WebkitBoxShadow: '0 0 0 100px #266798 inset',
          WebkitTextFillColor: '#fff',
        },
      },
    },
    MuiFilledInput: {
      input: {
        background: '#ffffff',
      },
    },
    MuiTab: {
      wrapper: {
        textTransform: 'capitalize',
      },
    },
    MuiGrid: {
      container: { background: '#ffffff', border: '0px solid white' },
      item: { border: '0px solid white' },
    },
    MuiPaper: {
      root: {
        borderRadius: '0px',
      },
    },
    MuiCardMedia: {
      root: {
        objectFit: 'contain',
      },
    },
  },
  shape: {
    borderRadius: 0,
  },
  components: {
    MuiFilledInput: {
      styleOverrides: {
        root: {
          '& :hover': {
            backgroundColor: '#f0f0f0',
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          '& button': {
            fontWeight: 'bold',
            cursor: 'pointer',
            borderRadius: '10px',
            letterSpacing: 1.5,
            backgroundColor: '#303030 !important',
            color: '#FFF',
            ':hover': {
              backgroundColor: '#000 !important',
              color: '#FFF',
            },
          },
        },
      },
    },
  },
});

const typographySettings = [
  {
    useNextVariants: true,
    fontFamily: ['ubuntu', 'sans-serif'].join(','),
    h1: {
      fontSize: '6rem',
      [theme.breakpoints.down('md')]: {
        fontSize: '5.5rem',
      },
      fontWeight: '500',
      lineHeight: '120px',
    },
  },
  {
    h2: {
      fontSize: '3.5rem',
      [theme.breakpoints.down('md')]: {
        fontSize: '3rem',
      },
      fontWeight: '200',
      fontStyle: 'normal',
      lineHeight: '72px',
    },
  },
  {
    h3: {
      fontSize: '2.5rem',
      [theme.breakpoints.down('md')]: {
        fontSize: '2rem',
      },
      fontWeight: 'bold',
      lineHeight: '56px',
    },
  },
  {
    h4: {
      fontSize: '1.75rem',
      fontWeight: 'bold',
      lineHeight: '32px',
    },
  },
  {
    h5: {
      fontSize: '1.5rem',
      // Changed fontWeight to bold based on appearance in Figma
      fontWeight: 'bold',
      lineHeight: '32px',
    },
  },
  {
    body1: {
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: '24px',
    },
  },
  {
    body2: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: '24px',
    },
  },
  {
    button: {
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: '24px',
    },
  },
  {
    caption: {
      fontSize: '0.75rem',
      lineHeight: '16px',
    },
  },
];

typographySettings.forEach((setting) => {
  theme.typography[Object.keys(setting)[0]] = setting[Object.keys(setting)[0]];
});

export default theme;
