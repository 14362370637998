import fetch from '../../api/fetch';
import { REDUX_ACTIONS } from '../../shared/config/constants';
import { parseError } from '../../utils/fetchErrorParser';
import store from '../store';
import { enqueueSnackbar } from './snackbar';

export const listTransactionHistory =
  (payload) =>
    (dispatch) => {
      const { transactionHistory } = store.getState();
      dispatch({
        type: REDUX_ACTIONS.TRANSACTION_HISTORY_LIST_LOADING,
      });

      const body = {
        input: {
          searchValue: payload?.userEmailTerm ? payload?.userEmailTerm : transactionHistory?.searchKey || '',
          startDate: payload?.selectedStartDateTime ? payload?.selectedStartDateTime : transactionHistory?.startDate || '',
          endDate: payload?.selectedEndDateTime ? payload?.selectedEndDateTime : transactionHistory?.endDate || '',
          filters: transactionHistory?.filters?.items || [],
          limit: 100,
          page: payload?.page || 1,
          linkOperator: transactionHistory?.filters?.linkOperator || '',
          sort: transactionHistory?.sort || null
        }
      };

      const path = 'audit/fieldTicket';
      return fetch
        .post(path, body)
        .then((resp) => {
          if (!resp.ok) {
            return Promise.reject(resp);
          }
          return resp.json();
        })
        .then((data) => {
          dispatch({
            type: REDUX_ACTIONS.TRANSACTION_HISTORY_LIST_SUCCESS,
            payload: {
              transactionHistory: data.data.data,
              paginatedElements: data.data.count,
              totalCount: data.data.count
            },
          });
        })
        .catch(async (error) => {
          const errorMessage = await parseError(error);

          dispatch(
            enqueueSnackbar(
              errorMessage,
              'error',
              new Date().getTime() + Math.random()
            )
          );
          dispatch({
            type: REDUX_ACTIONS.TRANSACTION_HISTORY_LIST_ERROR,
            payload: { ...error, message: errorMessage },
          });
        });
    };

export const downloadTransactionHistory = () => (dispatch) => {
  const { transactionHistory } = store.getState();

  const body = {
    searchKey: transactionHistory?.searchKey || '',
    startDate: transactionHistory?.startDate || '',
    endDate: transactionHistory?.endDate || '',
    input: transactionHistory?.filters?.items || [],
    linkOperator: transactionHistory?.filters?.linkOperator || '',
    sort: transactionHistory?.sort || null
  };

  const path = 'audit/fieldTicket/downloadCSV';
  return fetch
    .post(path, body)
    .then((resp) => {
      if (!resp.ok) {
        return Promise.reject(resp);
      }
      return resp.json();
    })
    .then((data) => {
      // Convert JSON data to CSV format
      const blob = new Blob([data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'POT_transaction_history.csv';
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);

      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.TRANSACTION_HISTORY_LIST_ERROR,
        payload: { ...error, message: errorMessage },
      });
    });
};
export const getTransactionHistoryKPIs =
  (startDate = null, endDate = null) =>
    (dispatch) => {
      dispatch({
        type: REDUX_ACTIONS.TRANSACTION_HISTORY_KPIS_LOADING,
      });

      const body = {
        input: {
          startDate, endDate
        }
      };

      const path = 'audit/fieldTicket/KPIs';
      return fetch
        .post(path, body)
        .then((resp) => {
          if (!resp.ok) {
            return Promise.reject(resp);
          }
          return resp.json();
        })
        .then((data) => {
          const chartData = data.data.map((item, index) => {
            return { ...item, y: item.orderCount, label: item.userEmail, _id: index, division: item.division || 'Other' };
          });
          dispatch({
            type: REDUX_ACTIONS.TRANSACTION_HISTORY_KPIS_SUCCESS,
            payload: chartData
          });
        })
        .catch(async (error) => {
          const errorMessage = await parseError(error);

          dispatch(
            enqueueSnackbar(
              errorMessage,
              'error',
              new Date().getTime() + Math.random()
            )
          );
          dispatch({
            type: REDUX_ACTIONS.TRANSACTION_HISTORY_KPIS_ERROR,
            payload: { ...error, message: errorMessage },
          });
        });
    };

export const getTransactionHistoryDivisionKPIs =
    (startDate = null, endDate = null) =>
      (dispatch) => {
        dispatch({
          type: REDUX_ACTIONS.TRANSACTION_HISTORY_DIVISION_KPIS_LOADING,
        });

        const body = {
          input: {
            startDate, endDate
          }
        };

        const path = 'audit/fieldTicket/DivisionKPIs';
        return fetch
          .post(path, body)
          .then((resp) => {
            if (!resp.ok) {
              return Promise.reject(resp);
            }
            return resp.json();
          })
          .then((data) => {
            const chartData = data.data.map((item, index) => {
              return { ...item, y: item.orderCount, _id: index, division: item.division };
            });
            dispatch({
              type: REDUX_ACTIONS.TRANSACTION_HISTORY_DIVISION_KPIS_SUCCESS,
              payload: chartData
            });
          })
          .catch(async (error) => {
            const errorMessage = await parseError(error);

            dispatch(
              enqueueSnackbar(
                errorMessage,
                'error',
                new Date().getTime() + Math.random()
              )
            );
            dispatch({
              type: REDUX_ACTIONS.TRANSACTION_HISTORY_DIVISION_KPIS_ERROR,
              payload: { ...error, message: errorMessage },
            });
          });
      };
