import { FIELDBOX_KPI_DIVISION_MAP } from './constants';
import { recentDate } from './recentDate';

export const convertChartData = (inputArray) => {
  const counts = {
    '0-7': 0,
    '7-14': 0,
    '14+': 0
  };

  const statusMap = {
    1: { label: '0-7', color: 'green' },
    2: { label: '7-14', color: '#FFBF00' },
    3: { label: '14+', color: 'red' }
  };

  inputArray.forEach(item => {
    const status = item.lastChangedStatus;
    if (statusMap[status]) {
      const label = statusMap[status].label;
      counts[label] = (counts[label] || 0) + 1;
    }
  });

  return Object.keys(counts).map(label => ({
    label,
    color: statusMap[Object.keys(statusMap).find(key => statusMap[key].label === label)].color,
    y: counts[label],
    indexLabel: counts[label] ? counts[label].toString() : '',
  }));
};

export const getUniqueDivisions = (inputArray) => {
  const divisions = inputArray.map(item => item.Division);
  const uniqueDivisions = [...new Set(divisions)];
  return uniqueDivisions;
};

export const filterAndTransformChartData = (inputArray, division) => {
  const filteredArray = (division === 'ALL')
    ? inputArray
    : inputArray.filter(item => item.Division === division);

  return {
    data: filteredArray,
    chartData: convertChartData(filteredArray)
  };
};

export const generateUpdatedArrayWithMappedDivisions = (data) => {
  // creates new array with top level division mapping and LastCheckDate
  const lookupMap = new Map();
  for (const [topLevel, subLevels] of Object.entries(FIELDBOX_KPI_DIVISION_MAP)) {
    subLevels.forEach(subLevel => {
      lookupMap.set(subLevel, topLevel);
    });
  }

  const categorizedData = data.map(item => {
    const newItem = { ...item };

    const division = lookupMap.get(newItem.WarehouseCodePlant) || newItem.WarehouseCodePlant;
    if (division) {
      newItem.Division = division;
    }
    newItem.LastCheckDate = recentDate(newItem.LastCheckDate_c, newItem.LastTransactionDate);

    return newItem;
  });

  return categorizedData;
};
