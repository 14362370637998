import { makeStyles } from '@mui/styles';

const styles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#f0f0f0',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    display: 'flex',
    flexDirection: 'column',
  },
  paperMobile: {
    backgroundColor: '#f0f0f0',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '355px'
  },
  button: {
    marginTop: '30px',
    marginLeft: `${theme.spacing(1)} !important`,
    alignSelf: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  modalLabel: {
    marginBottom: '20px',
    alignSelf: 'flex-end',
  }
}));

export default styles;
