import React from 'react';
import PropTypes from 'prop-types';
import ModalDialog from '../../../ui/ModalDialog';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, List, ListItem, ListItemText, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import styles from './styles';

const AlarmsStatusDialogMobile = ({ isOpen, onCloseClick, selectedJob }) => {
  if (!selectedJob) {
    return null;
  }

  const { alarms } = selectedJob;
  const systemStatusDetails = selectedJob.systemStatusDetails || {};
  const { faults = [], warnings = [] } = systemStatusDetails;
  const classes = styles();

  return (
    <ModalDialog isOpen={isOpen} onClose={onCloseClick}>
      <IconButton onClick={onCloseClick} style={{ position: 'absolute', right: 8, top: 8 }}>
        <CloseIcon />
      </IconButton>
      <br />
      <Typography className={classes.titleFont}>{selectedJob.Rig}</Typography>
      <Typography className={classes.titleFont}>{selectedJob.Well}</Typography>
      <br />
      <Divider />
      <List>
        {faults.length > 0 && (
          <>
            <Typography style={{ display: 'flex', justifyContent: 'center' }} variant="h5">Faults</Typography>
            <br />
            {faults.map((fault, index) => (
              <ListItem key={index} className={classes.faultItem}>
                <ListItemText className={classes.listContainerFont} primary={fault} />
              </ListItem>
            ))}
            <Divider />
          </>
        )}

        {warnings.length > 0 && (
          <Box style={{ marginTop: '10px' }}>
            <Typography style={{ display: 'flex', justifyContent: 'center' }} variant="h5">Warnings</Typography>
            <br />
            {warnings.map((warning, index) => (
              <ListItem key={index} className={classes.warningItem}>
                <ListItemText className={classes.listContainerFont} primary={warning} />
              </ListItem>
            ))}
            <Divider />
          </Box>
        )}

    {alarms?.alarms?.length > 0 && (
          <Box style={{ marginTop: '10px' }}>
            <Typography style={{ display: 'flex', justifyContent: 'center' }} variant="h5">Alarms</Typography>
            <br />
            {alarms.alarms.map((alarm, index) => {
              if ((alarm.message !== '' && alarm.message !== null) &&
              (alarms.overallStatus === 1 || alarms.overallStatus === 2)) {
                return (
                  <ListItem key={index} className={classes.warningItem}>
                    <ListItemText className={classes.listContainerFont} primary={alarm.message} />
                  </ListItem>
                );
              } else if ((alarm.message !== '' && alarm.message !== null) &&
              (alarms.overallStatus === 3 || alarms.overallStatus === 4)) {
                return (
                  <ListItem key={index} className={classes.faultItem}>
                    <ListItemText className={classes.listContainerFont} primary={alarm.message} />
                  </ListItem>
                );
              } else {
                return null;
              }
            })}
            <Divider />
          </Box>
    )}
      </List>
    </ModalDialog>
  );
};

AlarmsStatusDialogMobile.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  selectedJob: PropTypes.object,
  onCloseClick: PropTypes.func.isRequired,
};

AlarmsStatusDialogMobile.defaultProps = {
  selectedJob: {},
};

export default AlarmsStatusDialogMobile;
