import { ColorPicker } from 'material-ui-color';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { REDUX_ACTIONS } from '../../../shared/config/constants';
import styles from './styles';

export default function index({ field, filters }) {
  const classes = styles();
  const dispatch = useDispatch();
  const { columnsColorDefinition } = useSelector((state) => state.currentUser.preferences.datachartSettings);
  const [selectedColor, setSelectedColor] = useState(
    columnsColorDefinition[field] ??
      '000'
  );

  useEffect(() => {
    const newColor = {};
    newColor[field] = selectedColor;
    dispatch({
      type: REDUX_ACTIONS.SET_DATA_CHART_PREFERENCES,
      payload: {
        columnsColorDefinition: {
          ...columnsColorDefinition,
          ...newColor,
        },
      },
      filters
    });
  }, [selectedColor]);

  return (
    <ColorPicker
      className={classes.pickerControl}
      size={5}
      borderWidth={0}
      value={`#${selectedColor}`}
      key={`colorPicker_${field}`}
      deferred
      hideTextfield
      onChange={(evt) => {
        setSelectedColor(evt.hex);
      }}
    />
  );
}
