import { makeStyles } from '@mui/styles';

const styles = makeStyles(() => ({
  map: {
    height: '50vh',
    border: '5px solid white'
  },
  noActionPin: {
    cursor: 'initial',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  menuTogglerBtn: {
    position: 'fixed',
    top: '82vh',
    zIndex: 2000,
    marginLeft: '-54px',
    backgroundColor: '#e0e0d1',
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: '#c3c3c3',
    },
  },
  loadingContainer: {
    width: '400px',
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

export default styles;
