import { makeStyles } from '@mui/styles';

const styles = makeStyles((theme) => {
  return {
    searchBar: {
      backgroundColor: 'transparent',
      width: '100%',
      marginRight: '1vh',
      '&.MuiFilledInput-root:hover': {
        backgroundColor: 'transparent',
      },
      '&.MuiInputBase-root :hover': {
        backgroundColor: 'transparent',
      },
      fontSize: 'larger',
    },
    dateTimePicker: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
      alignSelf: 'flex-start',
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    dateContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginRight: '-5px'
    },
    runActionContainer: {
      marginRight: '-13px'
    },
    searchContainer: {
      marginRight: '10px'
    },
    aspectRatioContainer: {
      marginLeft: '-6px'
    }
  };
});

export default styles;
