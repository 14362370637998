import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { REDUX_ACTIONS } from '../../../../../shared/config/constants';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { CancelPresentationRounded, DirectionsRun } from '@mui/icons-material';
import styles from './style';
import { getDateInfo } from '../../../../../utils/dateInfo';
import { dataLoading } from '../../../../../store/actions/dataLoading';
import { Button, Fade, Grid, Modal, TextField, Box } from '@mui/material';
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import { enqueueSnackbar } from '../../../../../store/actions/snackbar';
import { CALENDER_PROPS } from '../../../../../utils/constants';

const DateRangeModal = () => {
  const { assetData } = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = styles();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [timeDiff, setTimeDiff] = useState(0);

  React.useEffect(() => {
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 1);
    setStartDate(startDate);
  }, []);

  useEffect(() => {
    const currentStartDate = new Date(startDate);
    const currentEndDate = new Date(endDate);
    if (startDate && endDate) {
      setTimeDiff((currentEndDate.getTime() - currentStartDate.getTime()) / 1000);
    }
  }, [startDate, endDate]);

  const handleClose = () => {
    dispatch({
      type: REDUX_ACTIONS.ASSET_DATA_TOOGLE_DATES_MODAL,
      payload: false,
    });
  };
  const handleApply = () => {
    if (timeDiff > 86400) { // return if time diff is greater than 24 hrs
      dispatch(
        enqueueSnackbar(
          'Please select valid end date',
          'error',
          new Date().getTime() + Math.random()
        )
      );
      return;
    }
    const formattedStartDate = getDateInfo(startDate, '/').formattedDayTime;
    const formattedEndDate = getDateInfo(endDate, '/').formattedDayTime;
    dispatch(dataLoading(true));
    dispatch({
      type: REDUX_ACTIONS.ASSET_DATA_SET_DATES,
      payload: {
        dateRange: {
          start: formattedStartDate,
          end: formattedEndDate,
        },
      },
    });
    handleClose();
  };
  const handleStartDateUpdate = (date) => {
    setStartDate(date);
  };
  const handleEndDateUpdate = (date) => {
    setEndDate(date);
  };

  const handleTimeShift = (type) => {
    if (timeDiff > 86400) { // return if time diff is greater than 24 hrs
      dispatch(
        enqueueSnackbar(
          'Please select valid end date',
          'error',
          new Date().getTime() + Math.random()
        )
      );
      return;
    }
    const currentStartDate = new Date(startDate);
    const currentEndDate = new Date(endDate);
    setStartDate(new Date(currentStartDate.setSeconds(type === 'increase' ? (currentStartDate.getSeconds() + timeDiff) : (currentStartDate.getSeconds() - timeDiff))));
    setEndDate(new Date(currentEndDate.setSeconds(type === 'increase' ? (currentEndDate.getSeconds() + timeDiff) : (currentEndDate.getSeconds() - timeDiff))));
  };

  return (
    <Modal
      open={assetData.isDateRangeModalOpen}
      onClose={handleClose}
      className={classes.modal}
      closeAfterTransition
    >
      <Fade in={assetData.isDateRangeModalOpen}>
        <div className={classes.paper}>
          <Grid container alignItems="flex-end" justifyContent="flex-end">
            {startDate && endDate && (
            <Box>
            <Button
              onClick={() => handleTimeShift('decrease')}
            >
              <FastRewindIcon fontSize='large' htmlColor ='DimGray'/>
            </Button>
            <Button
                onClick={() => handleTimeShift('increase')}
            >
              <FastForwardIcon fontSize='large' htmlColor ='DimGray'/>
            </Button>
            </Box>
            )}
            <Box className={classes.container}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  {...CALENDER_PROPS}
                  label="Start Date"
                  value={startDate}
                  onChange={handleStartDateUpdate}
                  className={classes.dateTimePicker}
                  classes={{
                    switchHeader: classes.datePickerHeader,
                  }}
                  textField={(params) => <TextField {...params} />}
                  disableFuture
                />
                <DateTimePicker
                  {...CALENDER_PROPS}
                  label="End Date"
                  value={endDate}
                  maxDateTime={new Date(new Date(startDate).getTime() + (24 * 60 * 60 * 1000))}
                  onChange={handleEndDateUpdate}
                  className={classes.dateTimePicker}
                  textField={(params) => <TextField {...params} />}
                  minDate={startDate}
                />
              </LocalizationProvider>
            </Box>
            <Button
              onClick={handleApply}
              disabled={!startDate || !endDate || startDate >= endDate}
            >
              <DirectionsRun fontSize='large' htmlColor ='DimGray'/>
            </Button>
            <Button
              className={classes.closeModalButton}
              onClick={handleClose}
            >
              <CancelPresentationRounded fontSize='large' htmlColor ='DimGray'/>
            </Button>
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
};
export default DateRangeModal;
