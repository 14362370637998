import React from 'react';
import useStyles from './styles';
import { Box } from '@mui/material';

function SkeletonLoaderCard() {
  const classes = useStyles();

  return (
      <Box className={classes.card}>
          <Box className={classes.cardBody}>
              <p className={`${classes.cardIntro} ${classes.cardIntroSkeleton} ${classes.skeleton}`}>
              </p>
              <Box className={`${classes.cardImg} ${classes.skeleton}`}>
              </Box>
          </Box>
      </Box>
  );
}

export default SkeletonLoaderCard;
