import { REDUX_ACTIONS } from '../../../shared/config/constants';

const initialState = {
  loading: false,
  deleting: false,
  data: [],
  parts: [],
  errorMessage: '',
  loadingDelete: false,
  saved: false,
  paginatedElements: 0,
  searchTerm: '',
  initialData: []
};

const epicorTrucksReducer = (state = initialState, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.EPICOR_TRUCKS_LIST_LOADING:
      return {
        ...state,
        loading: true,
        errorMessage: ''
      };
    case REDUX_ACTIONS.EPICOR_TRUCKS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...action.payload.epicorTrucks],
        initialData: [...action.payload.epicorTrucks],
        paginatedElements: action.payload.paginatedElements,
        errorMessage: ''
      };
    case REDUX_ACTIONS.EPICOR_TRUCKS_LIST_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.message,
        data: []
      };
    case REDUX_ACTIONS.EPICOR_TRUCKS_RESET:
      return {
        ...initialState
      };
    case REDUX_ACTIONS.EPICOR_SYNC_TRUCK_LOADING:
      return {
        ...state,
        loading: true,
        syncResponseMessage: action.payload.message,
      };
    case REDUX_ACTIONS.EPICOR_SYNC_TRUCK_IDLE:
      return {
        ...state,
        loading: false,
        syncResponseMessage: action.payload.message,
      };
    case REDUX_ACTIONS.EPICOR_SYNC_TRUCK_ERROR:
      return {
        ...state,
        loading: false,
        syncResponseMessage: action.payload.message,
      };
    default:
      return state;
  }
};

export default epicorTrucksReducer;
