const columns = (renderCell, renderActions, renderValueTypeCell) => [
  {
    field: 'propertyName',
    headerName: 'Property Name',
    width: 220,
    renderCell,
    editable: true,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'valueType',
    headerName: 'Value Type',
    width: 220,
    renderCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    renderEditCell: renderValueTypeCell
  },
  {
    field: 'actions',
    headerName: 'Actions',
    headerAlign: 'center',
    align: 'center',
    renderCell: renderActions,
  },
];
export default columns;
