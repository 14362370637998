import { makeStyles } from '@mui/styles';

const styles = makeStyles(({ spacing, breakpoints }) => ({
  seamlessContainer: {
    padding: '70px 0',
    background: 'white',
    width: '100%',
    [breakpoints.down(500)]: {
      padding: '30px 0',
    }
  },
  shoppingHeader: {
    marginBottom: '48px',
    textAlign: 'center',
    [breakpoints.down(500)]: {
      fontSize: '30px',
      marginBottom: '20px',
      width: '90%',
      margin: '0 auto',
      lineHeight: 1.5
    }
  },
  cardContainer: {
    maxWidth: '1280px',
    margin: '0 auto',
  },
  card: {
    padding: spacing(2),
    textAlign: 'center',
    boxShadow: 'none',
    border: 'none',
    height: '370px',
    borderRadius: 10,
    [breakpoints.down(500)]: {
      height: '280px',
    }
  },
  icon: {
    width: '200px',
    height: '200px',
    marginBottom: '35px',
    marginTop: '20px',
    [breakpoints.down(500)]: {
      width: '150px',
      height: '150px',
      marginBottom: '20px'
    }
  },
}));

export default styles;
