import { REDUX_ACTIONS } from '../../shared/config/constants';

const initialState = {
  loading: false,
  deleting: false,
  data: [],
  errorMessage: '',
  loadingDelete: false,
  saved: false,
  paginatedElements: 0,
  saving: false,
  categories: {
    loading: false,
    data: [],
    errorMessage: '',
    paginatedElements: 0,
  },
  searchTerm: '',
  initialData: [],
  listPartsByCompany: [],
  initialPartsByCompanyData: [],
  customerPartsLoading: false
};

const partReducer = (state = initialState, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.PARTS_LIST_LOADING:
      return {
        ...state,
        loading: true,
        errorMessage: '',
      };
    case REDUX_ACTIONS.PART_CATEGORIES_GET_LOADING:
      return {
        ...state,
        categories: {
          ...state.categories,
          loading: true,
          errorMessage: '',
        },
      };
    case REDUX_ACTIONS.PARTS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...action.payload.parts],
        initialData: [...action.payload.parts],
        paginatedElements: action.payload.paginatedElements,
        errorMessage: '',
        listPartsByCompany: [...action.payload.parts],
        initialPartsByCompanyData: [...action.payload.parts]
      };
    case REDUX_ACTIONS.PART_CATEGORIES_GET_SUCCESS:
      return {
        ...state,
        categories: {
          ...state.categories,
          loading: false,
          data: [...action.payload],
          errorMessage: '',
        },
      };
    case REDUX_ACTIONS.PARTS_CUSTOMER_LIST_LOADING:
      return {
        ...state,
        customerPartsLoading: true,
        errorMessage: '',
        listPartsByCompany: []
      };
    case REDUX_ACTIONS.PARTS_CUSTOMER_LIST_SUCCESS:
      return {
        ...state,
        customerPartsLoading: false,
        listPartsByCompany: [...action.payload.parts],
        initialPartsByCompanyData: [...action.payload.parts]
      };
    case REDUX_ACTIONS.PARTS_CUSTOMER_LIST_ERROR:
      return {
        ...state,
        customerPartsLoading: false,
        errorMessage: action.payload.message,
        listPartsByCompany: [],
      };
    case REDUX_ACTIONS.PART_CATEGORIES_RESET:
      return {
        ...state,
        categories: {
          ...state.categories,
          loading: false,
          data: [...state.initialData.map(item => item.Category).filter((value, index, self) => self.indexOf(value) === index).sort()],
          errorMessage: '',
        },
      };
    case REDUX_ACTIONS.PARTS_LIST_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.message,
        data: [],
      };
    case REDUX_ACTIONS.PART_CATEGORIES_GET_ERROR:
      return {
        ...state,
        categories: {
          ...state.categories,
          loading: false,
          errorMessage: action.payload.message,
          data: [],
        },
      };
    case REDUX_ACTIONS.PARTS_LIST_ERROR_NOTIFIED:
      return {
        ...state,
        errorMessage: '',
        data: [],
      };
    case REDUX_ACTIONS.PARTS_LIST_SAVING:
      return {
        ...state,
        saving: true,
      };
    case REDUX_ACTIONS.PARTS_SET_CLASSIFICATION:
      return {
        ...state,
        saving: false,
        data: [
          ...state.data.map((item) =>
            item._id === action.payload.partId
              ? { ...item, Classification: action.payload.classification }
              : { ...item }
          ),
        ],
        initialData: [
          ...state.data.map((item) =>
            item._id === action.payload.partId
              ? { ...item, Classification: action.payload.classification }
              : { ...item }
          ),
        ],
      };
    case REDUX_ACTIONS.PARTS_FILTER_BY_COMPANY:
      return {
        ...state,
        loading: false,
        listPartsByCompany: [...state.initialData.filter(item => item.VendorId === action.payload.companyId)
        ],
      };
    case REDUX_ACTIONS.PARTS_SET_PRICE:
      return {
        ...state,
        saving: false,
        data: [
          ...state.data.map((item) =>
            item._id === action.payload.partId
              ? { ...item, Price: action.payload.price }
              : { ...item }
          ),
        ],
        initialData: [
          ...state.data.map((item) =>
            item._id === action.payload.partId
              ? { ...item, Price: action.payload.price }
              : { ...item }
          ),
        ]
      };
    case REDUX_ACTIONS.PARTS_SET_PRICE_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.message,
        data: [],
      };
    case REDUX_ACTIONS.PARTS_SEARCH_CHANGE:
      return {
        ...state,
        searchTerm: action.payload.value,
      };
    case REDUX_ACTIONS.PARTS_LIST_FILTER:
      return {
        ...state,
        data: [
          ...state.initialData.filter((item) =>
            item.Description.toUpperCase().includes(
              state.searchTerm.toUpperCase()) || item.Classification.toString() === state.searchTerm.toString() || item.PN.toUpperCase().includes(state.searchTerm.toUpperCase())
          ),
        ],
        listPartsByCompany: [
          ...state.initialPartsByCompanyData.filter((item) =>
            item.Description.toUpperCase().includes(
              state.searchTerm.toUpperCase()
            ) || item.Name.toString() === state.searchTerm.toString() || item.Category.toUpperCase().includes(state.searchTerm.toUpperCase())
          ),
        ],
      };
    case REDUX_ACTIONS.PARTS_LIST_MOBILE_FILTER:
      return {
        ...state,
        data: action.payload
          ? [
              ...state.initialData.filter((value) => value.Category === action.payload)
            ]
          : [...state.initialData],
      };
    case REDUX_ACTIONS.PARTS_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default partReducer;
