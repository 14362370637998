import { makeStyles } from '@mui/styles';

const styles = makeStyles(() => ({
  logo: {
    width: '300px',
  },
  divider: {
    marginTop: '10px',
    marginBottom: '20px',
    width: '100%',
  },
  text: {
    marginBottom: '10%',
    textAlign: 'center'
  },
  link: {
    marginTop: '30px',
    textDecoration: 'underline',
    cursor: 'pointer'
  }
}));

export default styles;
