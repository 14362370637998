import { makeStyles } from '@mui/styles';

const styles = makeStyles(() => ({
  carousel: {
    '& ul': {
      margin: 0,
    },
  },
}));

export default styles;
