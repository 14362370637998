import { REDUX_ACTIONS } from '../../shared/config/constants';

const initialState = {
  loading: false,
  data: [],
  dataTransfer: [],
  errorMessage: '',
  initialData: [],
  binNum: '',
  binDescription: '',
  maxTicketAmount: '',
  po: '',
  salesRep: '',
  salesMrg: '',
  transferBox: '',
  ticketGen: '',
};

const ticketsReducer = (state = initialState, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.TICKETS_LIST_LOADING:
      return {
        ...state,
        loading: true,
        errorMessage: '',
      };
    case REDUX_ACTIONS.SET_TICKETS_LIST:
      return {
        ...state,
        loading: false,
        data: [...action.payload.data],
        dataTransfer: [...action.payload.dataTransfer],
        initialData: [...action.payload.data],
        binNum: action.payload.binNum,
        warehouseCode: action.payload.warehouseCode,
        binDescription: action.payload.binDescription,
        maxTicketAmount: action.payload.maxTicketAmount,
        po: action.payload.po,
        AFE: action.payload.AFE,
        leaseWell: action.payload.leaseWell,
        salesRep: action.payload.salesRep,
        salesMrg: action.payload.salesMrg,
        transferBox: action.payload.transferBox,
        ticketGen: action.payload.ticketGen,
        errorMessage: '',
      };
    case REDUX_ACTIONS.TICKETS_LIST_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.message,
        data: [],
      };
    case REDUX_ACTIONS.TICKETS_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default ticketsReducer;
