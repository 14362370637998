import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton } from '@mui/material';
import { CloseRounded, DoDisturbRounded, DoneRounded } from '@mui/icons-material';

const RenderStatusField = ({ id, row, handleStatus, disabled }) => {
  return (
     <Grid container justifyContent="center">
        <IconButton
        disableFocusRipple
        disableRipple
        variant="solid"
        disabled={disabled || !(Number(row?.orderStatus) === -1 || Number(row?.orderStatus) === 0)}
        sx={{ color: Number(row?.orderStatus) === 1 ? '#000 !important' : '#BBB' }}
        onClick={() => handleStatus(row?.orderNumber, 1, id)}
      >
        <DoneRounded />
      </IconButton>
      <IconButton
        disableFocusRipple
        disableRipple
        disabled={disabled || !(Number(row?.orderStatus) === -1 || Number(row?.orderStatus) === 0)}
        sx={{ color: Number(row?.orderStatus) === 2 ? '#000 !important' : '#BBB' }}
        variant="solid"
        onClick={() => handleStatus(row?.orderNumber, 2, id)}
        >
          <CloseRounded />
        </IconButton>
        <IconButton
          disableFocusRipple
          disableRipple
          disabled={disabled || !(Number(row?.orderStatus) === -1 || Number(row?.orderStatus) === 0)}
          sx={{ color: Number(row?.orderStatus) === 3 ? '#000 !important' : '#BBB' }}
          variant="solid"
          onClick={() => handleStatus(row?.orderNumber, 3, id)}
        >
          <DoDisturbRounded />
        </IconButton>
      </Grid>
  );
};

RenderStatusField.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string
  ]),
  row: PropTypes.object,
  handleStatus: PropTypes.func,
  disabled: PropTypes.bool
};

export default RenderStatusField;
