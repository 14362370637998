const columns = (renderCell) => [
  {
    field: '_id',
    headerName: '_id',
    hide: true,
    renderCell,
  },
  {
    field: 'SN',
    headerName: 'SN',
    renderCell,
  },
  {
    field: 'Type',
    headerName: 'Type',
    renderCell,
  },
];
export default columns;
