import React from 'react';
import PropTypes from 'prop-types';
import ModalDialog from '../ModalDialog';
import { Box } from '@mui/material';

import Premium from '../../../assets/images/PRO logo PMS green only.svg';
import styles from './styles';
import { isMobile } from 'react-device-detect';

const DataLoadingModalDialog = ({
  isOpen,
}) => {
  const classes = styles();

  return (
    <ModalDialog
        isOpen={isOpen}
        isFullScreen={false}
      >
        <Box
            component="img"
            alt="Logo"
            src={Premium}
            className={isMobile ? classes.mobile_logo : classes.logo}
        />
    </ModalDialog>
  );
};

DataLoadingModalDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string,
};

export default DataLoadingModalDialog;
