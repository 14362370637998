import { Box } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import CanvasJSReact from '../../../ui/canvasjs/canvasjs.react';
import { getDateInfo } from '../../../../utils/dateInfo';
import { kFormatter } from '../../../../utils/numbers';
import MobileCanvasJSFooter from './footer';

const CanvasChart = CanvasJSReact.CanvasJSChart;

let yAxisInterval = 1800000; // set to 30 mins in milliseconds by default
function MobileCanvasJS({
  nDataSource,
  yAxis,
  rangeChanging,
  viewportMinimum,
  viewportMaximum
}) {
  const options = {
    theme: 'light2',
    animationEnabled: true,
    zoomEnabled: true,
    exportEnabled: true,
    toolbar: {
      itemBackgroundColor: '#d3d3d3',
      itemBackgroundColorOnHover: '#3e3e3e',
      buttonBorderColor: '#3e3e3e',
    },
    backgroundColor: '#f0f0f0',
    axisX: {
      viewportMinimum: 0,
      viewportMaximum: 0,
    },
    axisY: {
      viewportMinimum: null,
      viewportMaximum: null,
    },
    rangeChanged: function(e) {
      if (e.chart.axisY[0].range < 1800000) {
        yAxisInterval = null;
        e.chart.axisY[0].set('interval', null);
      } else {
        yAxisInterval = 1800000;
        e.chart.axisY[0].set('interval', yAxisInterval);
      }
    },
  };

  if (rangeChanging) {
    options.rangeChanging = rangeChanging;
  }

  if (viewportMinimum && viewportMaximum) {
    options.axisX.viewportMinimum = viewportMinimum;
    options.axisX.viewportMaximum = viewportMaximum;

    options.axisY.viewportMinimum = viewportMinimum;
    options.axisY.viewportMaximum = viewportMaximum;
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: 'safe center', overflowX: 'scroll', width: '100%', flex: 1 }}>
    {nDataSource.map((chart, index) => {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} key={`v${chart.name}`}>
            <CanvasChart
            key={`v${chart.name}`}
            containerProps={{ minWidth: '250px', maxHeight: '100vh' }}
            options={{
              ...options,
              height: window.innerHeight - 200,
              data: [chart],
              zoomType: 'y',
              axisY: {
                ...options.axisY,
                reversed: true,
                labelFontSize: 12,
                labelPlacement: 'outside',
                labelMaxWidth: 100,
                labelWrap: false,
                interval: yAxisInterval,
                minimum: chart.dataPoints[0].y,
                maximum: chart.dataPoints[chart.dataPoints.length - 1].y,
                labelFormatter: function (e) {
                  if (index !== 0) {
                    return '';
                  }
                  return (new Date(e.value)).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true });
                },
              },
              toolTip: {
                contentFormatter: function (e) {
                  return getDateInfo(new Date(e.entries[0].dataPoint.y), '-')?.formattedDayTime + ' : ' + e.entries[0].dataPoint.x;
                },
              },
              axisX: {
                ...yAxis[index],
                titleFontColor: chart?.color || '#000',
                labelFontSize: 12,
                labelPlacement: 'outside',
                labelFormatter: function (e) {
                  return kFormatter(e.value.toFixed(2));
                },
              },
            }}
            />
             <MobileCanvasJSFooter field={chart?.name} />
            </Box>
      );
    })}
</Box>
  );
}

MobileCanvasJS.propTypes = {
  nDataSource: PropTypes.array,
  yAxis: PropTypes.array,
  rangeChanging: PropTypes.func,
  viewportMinimum: PropTypes.number,
  viewportMaximum: PropTypes.number,
};

MobileCanvasJS.defaultProps = {
  nDataSource: [],
  rangeChanging: () => {},
};

export default MobileCanvasJS;
