import { makeStyles } from '@mui/styles';

const styles = makeStyles((theme) => {
  return {
    searchBar: {
      backgroundColor: 'transparent',
      [theme.breakpoints.down('sm')]: {
        width: '55vw',
      },
      width: '250px',
      '&.MuiFilledInput-root:hover': {
        backgroundColor: 'transparent',
      },
      '&.MuiInputBase-root :hover': {
        backgroundColor: 'transparent',
      },
      fontSize: 'larger'
    },
  };
});

export default styles;
