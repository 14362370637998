import { renderCustomPrice } from '../../utils/renderCustomPrice';
const columns = (renderStatusField, renderProofOfDelivery) => [
  {
    field: 'timestamp',
    headerName: 'Date',
    width: 200,
    hide: false,
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'orderNumber',
    headerName: 'Order ID',
    width: 300,
    hide: false,
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'quantity',
    headerName: 'Quantity',
    width: 200,
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'price',
    headerName: 'Price',
    width: 200,
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center',
    renderCell: renderCustomPrice,
  },
  {
    field: 'vendorName',
    headerName: 'Seller',
    width: 300,
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'orderStatus',
    headerName: 'Status',
    hide: false,
    width: 300,
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center',
    renderCell: renderStatusField
  },
  {
    field: 'POD',
    headerName: 'POD',
    hide: false,
    width: 200,
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center',
    renderCell: renderProofOfDelivery
  }
];

export default columns;
