import { Visibility, VisibilityOff } from '@mui/icons-material';
import { FormControl, Grid, IconButton, InputAdornment, OutlinedInput, Typography, styled } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const MobileResetPassword = ({
  confirmPassword,
  setConfirmPassword,
  password,
  setPassword,
  showPassword,
  handleClickShowPassword,
  handleMouseDownPassword,
  error,
  resetPasswordSuccess,
  resetPasswordError,
  errorMessage

}) => {
  const SuccessTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.Authentication_Success.main,
  }));

  return (
    <Grid item xs={12} padding={1}>
      <FormControl
        style={{ marginTop: '5px' }}
        fullWidth
        variant="filled"
        value={password}
        onChange={(value) => {
          setPassword(value.target.value);
        }}
      >
        <OutlinedInput
          id="outlined-adornment-password"
          type={showPassword ? 'text' : 'password'}
          placeholder='Password'
          sx={{
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent !important',
            },
          }}
          style={{
            backgroundColor: '#f0f0f0',
            borderRadius: '8px',
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label="Password"
        />
      </FormControl>
      <FormControl
        style={{ marginTop: '10px' }}
        fullWidth
        variant="filled"
        value={confirmPassword}
        onChange={(value) => {
          setConfirmPassword(value.target.value);
        }}
      >
        <OutlinedInput
          id="outlined-adornment-confirm-password"
          type={showPassword ? 'text' : 'password'}
          placeholder='Confirm password'
          sx={{
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent !important',
            },
          }}
          style={{
            backgroundColor: '#f0f0f0',
            borderRadius: '8px',
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label="Confirm Password"
        />
      </FormControl>
      <Typography color="error">{error}</Typography>
      {resetPasswordSuccess && (
        <SuccessTypography>Password reset successful.</SuccessTypography>
      )}
      {resetPasswordError && (
        <Typography color="error">
          {errorMessage}
        </Typography>
      )}
    </Grid>
  );
};

MobileResetPassword.propTypes = {
  password: PropTypes.string.isRequired,
  setPassword: PropTypes.func.isRequired,
  confirmPassword: PropTypes.string.isRequired,
  setConfirmPassword: PropTypes.func.isRequired,
  showPassword: PropTypes.bool.isRequired,
  handleClickShowPassword: PropTypes.func.isRequired,
  handleMouseDownPassword: PropTypes.func.isRequired,
  error: PropTypes.any,
  resetPasswordSuccess: PropTypes.any,
  resetPasswordError: PropTypes.any,
  errorMessage: PropTypes.any,
};

export default MobileResetPassword;
