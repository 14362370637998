import { makeStyles } from '@mui/styles';

const styles = makeStyles(({ breakpoints }) => {
  return {
    searchBar: {
      background: '#FFF',
      fontSize: '12px',
      padding: '5px 0',
      borderRadius: '50px',
      border: '4px solid #CCC',
      width: '30%',
      margin: '40px auto',
      [breakpoints.down(500)]: {
        width: '80%',
        margin: '30px auto',
        fontSize: '10px',
        padding: '2px 0',
      }
    },
    icon: {
      color: '#000',
      fontSize: '26px',
    },
    locationWrapper: {
      padding: '70px 0 0 0',
      width: '100%',
      textAlign: 'center',
      [breakpoints.down(500)]: {
        padding: '30px 0 0 0',
      }
    },
    inputBase: {
      flex: 1,
      fontSize: '18px',
      fontWeight: 'bold',
      paddingLeft: '20px'
    },
    locationTitle: {
      [breakpoints.down(500)]: {
        fontSize: '30px',
        marginBottom: '20px',
        width: '90%',
        margin: '0 auto',
        lineHeight: 1.5
      }
    },
    mapContainer: {
      marginTop: '50px',
      [breakpoints.down(500)]: {
        marginTop: '30px',
      }
    }
  };
});

export default styles;
