import { Typography } from '@mui/material';
import React from 'react';

export const renderCell = ({ value }) => (
  <Typography style={{ fontSize: 'medium', fontWeight: 'bold' }}>{value}</Typography>
);

export const renderCustomCell = ({ value }) => (
  // eslint-disable-next-line react/jsx-filename-extension
  <Typography style={{ fontSize: '12px', fontWeight: 'normal', textWrap: 'wrap' }}>{value}</Typography>
);

export const renderBoldFontCustomCell = ({ value }) => (
  // eslint-disable-next-line react/jsx-filename-extension
  <Typography style={{ fontSize: 'medium', fontWeight: 'bold', textWrap: 'wrap' }}>{value}</Typography>
);
