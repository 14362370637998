import React, { useEffect, useState } from 'react';
import { CardMedia, Grid, IconButton, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { REDUX_ACTIONS } from '../../shared/config/constants';
import Datagrid from '../ui/Datagrid';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { listEpicorTrucks, syncEpicorTruck } from '../../store/actions/epicor/epicorTrucks';
import { listBinsParts } from '../../store/actions/epicor/epicorBins';
import { gridColumnGenerator } from '../../utils/gridColumnGenerator';
import { extractVisibleFields } from '../../utils/extractVisibleFields';
import TrucksDetail from './TrucksDetail';
import { SyncRounded } from '@mui/icons-material';
import PropTypes from 'prop-types';
import SyncMobileDevicesModal from '../ui/ControlPanel/SyncMobileDevices/SyncMobileDevicesModal';
import styles from './styles';
import Premium from '../../assets/images/PRO logo PMS green only.svg';

const Trucks = () => {
  const classes = styles();
  const apiRef = useGridApiRef();
  const epicorTrucks = useSelector((state) => state.epicorTrucks); // NEW
  const dispatch = useDispatch();
  const [columns, setColumns] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [binNumber, setBinNumber] = useState(null);
  const [open, setOpen] = useState(false);

  const renderCell = (params) => <>{params.value}</>;
  const renderSyncCell = (props) => {
    return (
      <Grid container justifyContent="center">
        <IconButton
          variant="solid"
          disabled={epicorTrucks.loading}
          onClick={() => {
            setOpen(true);
            setBinNumber(props.row.BinNum);
          }}
        >
          <SyncRounded />
        </IconButton>
      </Grid>
    );
  };

  const syncSingleTruck = () => {
    dispatch(syncEpicorTruck(binNumber));
  };

  const syncSigleTruckCloseModal = () => {
    setOpen(false);
    dispatch({
      type: REDUX_ACTIONS.EPICOR_SYNC_TRUCK_ERROR,
      payload: {
        loading: false,
      },
    });
  };

  useEffect(() => {
    dispatch(listEpicorTrucks());
  }, []);

  const handleVisibiltyModel = (newModel) => {
    const currentColumns = extractVisibleFields(
      apiRef.current.getVisibleColumns()
    );
    setVisibleColumns(currentColumns);
    setColumnVisibilityModel(newModel);
  };

  useEffect(() => {
    if (!epicorTrucks.loading && epicorTrucks.data.length > 0) {
      let columnsList = [];

      columnsList = gridColumnGenerator(
        visibleColumns,
        epicorTrucks.data,
        renderCell,
        renderSyncCell
      );
      const nColumnsList = columnsList.map((column) => {
        if (column.field === '_id' || column.field === 'grid_id') {
          column.hide = true;
        } else {
          column.hide = false;
        }
        return column;
      });
      setColumns(nColumnsList);
      apiRef.current.updateColumns(nColumnsList);
    }
  }, [epicorTrucks.data]);

  const handleReleaseSelected = (bin) => {
    dispatch({
      type: REDUX_ACTIONS.MENU_SET_TITLE,
      payload: bin.BinNum,
    });
    dispatch(listBinsParts(bin.WarehouseCode, bin.BinNum, 'Truck'));
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Datagrid
          apiRef={apiRef}
          data={epicorTrucks.data}
          columns={columns}
          loading={epicorTrucks.loading}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) => {
            handleVisibiltyModel(newModel);
          }}
          onCol
          disableStickyHeader={false}
          autoHeight={false}
          pageSize={100}
          height={'45vh'}
          hideFooter={false}
          onRowClick={({ row }) => {
            handleReleaseSelected(row);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TrucksDetail />
      </Grid>
      <SyncMobileDevicesModal
        open={open}
        onClose={syncSigleTruckCloseModal}
        handleClose={syncSigleTruckCloseModal}
        cta1Label={'Close'}
        cta1Action={syncSigleTruckCloseModal}
        cta2Label={'Sync'}
        cta2Disabled={epicorTrucks.loading}
        cta2Action={syncSingleTruck}
        showCta2={true}
        showCta1={true}
        showDateTimeRange={false}
      >
        <CardMedia
          component="img"
          image={Premium}
          className={classes.logo}
        />

        <Typography variant="h3" gutterBottom component="div" className={classes.title}>
          {'Sync ' + binNumber + ' ?'}
        </Typography>

        { epicorTrucks.syncResponseMessage !== ''
          ? <Typography variant="h5" gutterBottom component="div" className={classes.statusMessage}>
            {epicorTrucks.syncResponseMessage}
          </Typography>
          : <div style={{
            height: 40,
          }}></div>
        }
      </SyncMobileDevicesModal>
    </Grid>
  );
};

Trucks.propTypes = {
  row: PropTypes.object,
};

export default Trucks;
