import { makeStyles } from '@mui/styles';

const styles = makeStyles(() => ({
  dialogModal: {
    maxWidth: '80%',
    margin: '0 auto',
  },
  dialogContent: {
    padding: '0 !important',
  },
}));

export default styles;
