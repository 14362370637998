import { REDUX_ACTIONS } from '../../../shared/config/constants';

const initialState = {
  loading: false,
  deleting: false,
  data: [],
  parts: [],
  errorMessage: '',
  loadingDelete: false,
  saved: false,
  paginatedElements: 0,
  searchTerm: '',
  initialData: [],
  executeSearch: false,
  loadingBin: false,
  syncResponseMessage: '',
  kpis: {
    data: [],
    initialData: [],
    loading: false,
    errorMessage: '',
    chartData: [],
    divisions: [],
    selectedDivision: 'ALL',
    searchTerm: ''
  }
};

const epicorBinsReducer = (state = initialState, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.EPICOR_BINS_LIST_LOADING:
      return {
        ...state,
        loading: true,
        errorMessage: ''
      };
    case REDUX_ACTIONS.EPICOR_BINS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        executeSearch: false,
        data: [...action.payload.epicorBins],
        initialData: [...action.payload.epicorBins],
        paginatedElements: action.payload.paginatedElements,
        errorMessage: ''
      };
    case REDUX_ACTIONS.EPICOR_BINS_LIST_ERROR:
      return {
        ...state,
        loading: false,
        executeSearch: false,
        errorMessage: action.payload.message,
        data: []
      };
    case REDUX_ACTIONS.EPICOR_BINS_LIST_ERROR_NOTIFIED:
      return {
        ...state,
        errorMessage: '',
        data: []
      };
    case REDUX_ACTIONS.EPICOR_BINS_PARTS_LOADING:
      return {
        ...state,
        loading: true,
        errorMessage: ''
      };
    case REDUX_ACTIONS.EPICOR_BINS_PARTS_SUCCESS:
      return {
        ...state,
        loading: false,
        parts: [...action.payload.parts],
        paginatedElements: action.payload.paginatedElements,
        errorMessage: ''
      };
    case REDUX_ACTIONS.EPICOR_BINS_PARTS_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.message,
        parts: []
      };
    case REDUX_ACTIONS.EPICOR_BINS_PARTS_ERROR_NOTIFIED:
      return {
        ...state,
        loading: false,
        errorMessage: '',
        parts: []
      };
    case REDUX_ACTIONS.EPICOR_BINS_SEARCH_CHANGE:
      return {
        ...state,
        searchTerm: action.payload.value,
      };
    case REDUX_ACTIONS.EPICOR_BINS_FILTER:
      return {
        ...state,
        data: [
          ...state.initialData.filter((item) =>
            item.BinNum.toUpperCase().includes(state.searchTerm.toUpperCase())
          ),
        ],
      };
    case REDUX_ACTIONS.EPICOR_BINS_SEARCH:
      return {
        ...state,
        executeSearch: true,
      };
    case REDUX_ACTIONS.EPICOR_BINS_RESET:
      return {
        ...initialState
      };
    case REDUX_ACTIONS.EPICOR_SYNC_BIN_LOADING:
      return {
        ...state,
        loadingBin: true,
        syncResponseMessage: action.payload.message,
      };
    case REDUX_ACTIONS.EPICOR_SYNC_BIN_IDLE:
      return {
        ...state,
        loadingBin: false,
        syncResponseMessage: action.payload.message,
      };
    case REDUX_ACTIONS.EPICOR_SYNC_BIN_ERROR:
      return {
        ...state,
        loadingBin: false,
        syncResponseMessage: action.payload.message,
      };
    case REDUX_ACTIONS.EPICOR_UPDATE_COORDINATES:
      return {
        ...state,
        data: state.data.map(bin =>
          bin.BinNum === action.payload.BinNum ? { ...bin, ...action.payload } : bin
        )
      };
    case REDUX_ACTIONS.FIELDBOXES_KPIS_LIST_LOADING:
      return {
        ...state,
        kpis: {
          ...state.kpis,
          loading: true,
          errorMessage: ''
        }
      };
    case REDUX_ACTIONS.FIELDBOXES_KPIS_LIST_SUCCESS:
      return {
        ...state,
        kpis: {
          ...state.kpis,
          loading: false,
          executeSearch: false,
          initialData: [...action.payload.data],
          data: [...action.payload.data],
          chartData: [...action.payload.chartData],
          divisions: [...action.payload.divisions],
          paginatedElements: action.payload.paginatedElements,
          errorMessage: ''
        }
      };
    case REDUX_ACTIONS.FIELDBOXES_KPIS_LIST_ERROR:
      return {
        ...state,
        kpis: {
          ...state.kpis,
          loading: false,
          executeSearch: false,
          errorMessage: action.payload.message,
          data: []
        }
      };
    case REDUX_ACTIONS.FIELDBOXES_KPIS_SET_DIVISION:
      return {
        ...state,
        kpis: {
          ...state.kpis,
          loading: false,
          data: [...action.payload.data],
          chartData: [...action.payload.chartData],
          selectedDivision: action.payload.selectedDivision,
          searchTerm: '',
        }
      };
    case REDUX_ACTIONS.FIELDBOXES_KPIS_LIST_SEARCH_CHANGE:
      return {
        ...state,
        kpis: {
          ...state.kpis,
          searchTerm: action.payload,
        }
      };
    case REDUX_ACTIONS.FIELDBOXES_KPIS_LIST_FILTER: {
      const searchTerm = state.kpis.searchTerm.toUpperCase();
      const initialData = state.kpis.initialData;

      const filteredData = initialData.filter(item => {
        const matchesSearchTerm = searchTerm === '' || item?.BinNum?.toUpperCase().includes(searchTerm);
        const matchesDivision = state.kpis.selectedDivision === 'ALL' || item.Division === state.kpis.selectedDivision;
        return matchesSearchTerm && matchesDivision;
      });

      return {
        ...state,
        kpis: {
          ...state.kpis,
          data: [...filteredData]
        }
      };
    }

    default:
      return state;
  }
};

export default epicorBinsReducer;
