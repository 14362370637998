import * as React from 'react';
import styles from './styles';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Toolbar,
} from '@mui/material';
import ControlPanel from '../../../ui/ControlPanel';

export default function MobileNavbar() {
  const classes = styles();

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar component="nav">
          <Toolbar
            sx={{ padding: 0, width: '100%' }}
            className={clsx(classes.toolBar)}
          >
            <ControlPanel isMobile />
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
}
