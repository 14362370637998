import React, { useEffect, useRef } from 'react';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import CanvasJSReact from '../ui/canvasjs/canvasjs.react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getDateInfo } from '../../utils/dateInfo';
import Datagrid from '../ui/Datagrid';
import { gridClasses } from '@mui/x-data-grid';
import { REDUX_ACTIONS } from '../../shared/config/constants';
import { getCheckInsDivisionKPIs, getCheckInsEmailKPIs } from '../../store/actions/checkins';
import columns from './table_config';
import { renderCell } from '../ui/Datagrid/table_config';

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const chartDefaults = {
  exportEnabled: true,
  animationEnabled: true,
  theme: 'light2',
};

const CheckInsKPI = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(location.search);
  const startDate = searchParams.get('startDate');
  const endDate = searchParams.get('endDate');
  const { checkInsDivisionKPIs, checkInsDivisionKPIsLoading, checkInsEmailKPIs, checkInsEmailKPIsLoading } = useSelector((state) => state.checkIns);
  const chartRef = useRef();
  const pageTitle = `Check Ins: ${getDateInfo(new Date(startDate), '/').formated} - ${getDateInfo(new Date(endDate), '/').formated}`;

  useEffect(() => {
    dispatch({
      type: REDUX_ACTIONS.MENU_SET_TITLE,
      payload: pageTitle,
    });
    dispatch(getCheckInsEmailKPIs(startDate, endDate));
    dispatch(getCheckInsDivisionKPIs(startDate, endDate));
  }, []);

  return (
    <Grid display={'flex'} flexDirection={'column'}>
      {checkInsEmailKPIsLoading || checkInsDivisionKPIsLoading
        ? (
          <Grid container justifyContent={'center'} alignItems={'center'} sx={{ height: '400px' }}>
            <CircularProgress />
          </Grid>
          )
        : (
          <>
          {/* Bar chart - Check Ins KPIs */}
            {checkInsEmailKPIs.length
              ? (
              <>
                <Box sx={{ flex: 1 }}>
                  <CanvasJSChart
                  ref={chartRef}
                  options={{
                    ...chartDefaults,
                    height: 300,
                    axisX: {
                      title: 'Email',
                      reversed: true,
                    },
                    axisY: {
                      title: 'Total Check Ins Count',
                      includeZero: true,
                    },
                    data: [{
                      type: 'bar',
                      dataPoints: checkInsEmailKPIs
                    }]
                  }} />
                </Box>
              </>
                )
              : (
              <Typography variant='h6' sx={{ textAlign: 'center' }}>
                No data
              </Typography>
                )}

            {/* Pie chart - Check Ins division KPIs */}
            {checkInsDivisionKPIs.length
              ? (
              <>
                <Box sx={{ flex: 1, marginTop: 1 }}>
                  <CanvasJSChart
                  options={{
                    ...chartDefaults,
                    height: 300,
                    data: [{
                      type: 'pie',
                      startAngle: 75,
                      toolTipContent: '<b>{division}</b>: {y}',
                      legendText: '{label}',
                      indexLabelFontSize: 16,
                      indexLabel: '{division} - #percent%',
                      dataPoints: checkInsDivisionKPIs
                    }]
                  }} />
                </Box>
              </>
                )
              : (
              <Typography variant='h6' sx={{ textAlign: 'center' }}>
                No data
              </Typography>
                )}
                            <Grid item xs={12}>
              <Datagrid
                data={checkInsEmailKPIs}
                getRowId={(row) => row._id}
                columns={columns(renderCell)}
                loading={checkInsEmailKPIsLoading}
                autoHeight={false}
                pageSize={10}
                height={'40vh'}
                getRowHeight={() => 'auto'}
                sx={{
                  '.MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 'bold !important',
                  },
                  [`& .${gridClasses.cell}`]: {
                    py: 1,
                  },
                }}
                onRowClick={({ row }) => {
                  chartRef?.current?.chart?.toolTip?.showAtX(Number(row.x));
                }}
                toolbar={{
                  options: {
                    columns: true,
                    filters: true,
                    export: {
                      enabled: true
                    },
                  },
                }}
              />
            </Grid>
          </>
          )}
    </Grid>
  );
};

export default CheckInsKPI;
