import { makeStyles } from '@mui/styles';

const styles = makeStyles(({ spacing }) => ({
  pressed: {
    border: 'solid 1px gray',
    color: 'gray',
    backgroundColor: 'lightgray',
    borderRadius: spacing(0.5),
  },
}));

export default styles;
