import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import styles from './styles';

const ButtonComponent = (props) => {
  const {
    label,
    style,
    onClick,
    className,
    fullWidth = false,
    disabled = false,
    type = 'button',
    autoFocus = false,
    variant = 'contained',
    size = 'medium',
  } = props;
  const classes = styles();
  const sizeClass = classes[size] || classes.medium;

  return (
    <Button
      onClick={onClick}
      className={`${classes.primary} ${sizeClass} ${className}`}
      fullWidth={fullWidth}
      disabled={disabled}
      type={type}
      autoFocus={autoFocus}
      variant={variant}
      style={style}
    >
      {label}
    </Button>
  );
};

ButtonComponent.propTypes = {
  label: PropTypes.any,
  style: PropTypes.any,
  onClick: PropTypes.func,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  autoFocus: PropTypes.bool,
  variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
  size: PropTypes.any,
};
export default ButtonComponent;
