/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { Grid, Typography, IconButton, gridClasses } from '@mui/material';
import ModalDialog from '../ui/ModalDialog/index';
import { CancelPresentationRounded, DeleteRounded, SaveRounded } from '@mui/icons-material';
import PropTypes from 'prop-types';
import styles from './style';
import Datagrid from '../ui/Datagrid';
import { renderCell } from '../ui/Datagrid/table_config';
import { addNewGeometryRow, createGeometry, deleteGeometryRow, importWellsGeometryCSVFIle, listWellsGeometry, updateGeometry } from '../../store/actions/wellsGeometry';
import columns from './table_config';
import SelectEditInputTextField from '../ui/SelectEditInputTextField';
import SelectEditValueTypes from '../ui/SelectEditValueTypes';
import { enqueueSnackbar } from '../../store/actions/snackbar';
import { WELLS_GEOMETRY_TYPE } from '../../utils/constants';
const GeometryModal = ({
  onCloseClick,
  geometryData,
  isOpen
}) => {
  const dispatch = useDispatch();
  const classes = styles();
  const geometry = useSelector((state) => state.wellsGeometry);
  const apiRef = useGridApiRef();
  const [properties, setProperties] = useState([]);
  const [invalidRows, setInvalidRows] = useState({});

  useEffect(() => {
    if (!geometry.loading) {
      dispatch(listWellsGeometry(geometryData?.row?._id));
    }
  }, []);

  useEffect(() => {
    if (geometry?.data) {
      setProperties(geometry?.data);
    }
  }, [geometry?.data]);

  useEffect(() => {
    if (properties[0]?.type === '') {
      const id = properties[0]._id;
      setTimeout(() => {
        apiRef.current.scrollToIndexes({
          rowIndex: 0
        });
        apiRef.current.setCellMode(id, 'type', 'edit');
        setTimeout(() => {
          apiRef.current.setCellFocus(id, 'type');
        }, 50);
      }, 50);
      apiRef.current.setPage(0);
    }
  }, [properties?.length]);

  const handleAddSurvey = () => {
    if (properties[0]?.isNewRow) return;
    dispatch(addNewGeometryRow());
  };

  const removeSurvey = (row) => {
    if (row?.isNewRow) {
      const updatedGeometry = properties.filter(item => item._id !== row?._id);
      setProperties(updatedGeometry);
      dispatch(listWellsGeometry(geometryData?.row?._id));
    } else {
      dispatch(deleteGeometryRow(row?._id, geometryData?.row?._id));
    }
  };

  const showError = (message, rowId) => {
    dispatch(enqueueSnackbar(message, 'error'));
    setInvalidRows(prev => ({ ...prev, [rowId]: true }));
    return false;
  };

  const checkGeometryType = (row) => {
    if (!row.type) {
      return showError('Type is required', row._id);
    }
    return true;
  };

  const checkTopMD = (row, index) => {
    if (row.top_md === undefined || row.top_md === null) {
      return showError('Top MD is required', row._id);
    }
    if (row?.isNewRow) {
      if (index === 0 && row?.top_md !== 0 && properties.length === 1) {
        return showError('Start MD of the first item should be 0', row._id);
      }
    } else {
      if (index === 0 && row?.top_md !== 0) {
        return showError('Start MD of the first item should be 0', row._id);
      }
    }
    return true;
  };

  const checkShoeMD = (row) => {
    if (row.shoe_md === undefined || row.shoe_md === null) {
      return showError('Shoe MD is required', row._id);
    }
    if (row.shoe_md <= row.top_md) {
      return showError('End MD should be greater than Start MD', row._id);
    }
    return true;
  };

  const checkID = (row) => {
    if (row.id < 1) {
      return showError('ID should be greater than 1', row._id);
    }
    if (row.id > 50) {
      return showError('ID should be smaller than 50"', row._id);
    }
    return true;
  };

  const checkGeometryConstraints = (row, properties, currentIndex) => {
    if (properties.length) {
      if (row.isNewRow) {
        if (row.id > properties.at(-1).id) {
          return showError(`ID ${row.id} should be less than next row ID ${properties.at(-1).id}`, row._id);
        }
        if (row.top_md > properties.at(-1).shoe_md) {
          return showError(`Next row Start MD should not be larger than previous row End MD ${properties.at(-1).shoe_md}`, row._id);
        }
      } else {
        if (row.id < properties.at(currentIndex + 1)?.id) {
          return showError(`ID should be greater than next row ID ${properties.at(currentIndex + 1).id}`, row._id);
        }
        if (currentIndex > 0 && row.id > properties.at(currentIndex - 1)?.id) {
          return showError(`ID should be less than previous row ID ${properties.at(currentIndex - 1).id}`, row._id);
        }
        if (row.top_md > properties.at(currentIndex - 1).shoe_md) {
          return showError(`Next row Start MD should not be larger than previous row End MD ${properties.at(currentIndex - 1).shoe_md}`, row._id);
        }
      }
    }
    return true;
  };

  const validateGeometryData = (row, properties) => {
    const currentIndex = properties.findIndex(item => item._id === row._id);

    return (
      checkGeometryType(row) &&
      checkTopMD(row, currentIndex) &&
      checkShoeMD(row) &&
      checkID(row) &&
      checkGeometryConstraints(row, properties, currentIndex)
    );
  };

  const checkGeometryDataValues = (row) => {
    const invalidFields = [];
    if (!row?.type) invalidFields.push('Type');
    if (row?.top_md === undefined || row?.top_md === null || row?.top_md === '') invalidFields.push('Start MD');
    if (row?.shoe_md === undefined || row?.shoe_md === null || row?.shoe_md === '') invalidFields.push('End MD');
    if (row?.id === undefined || row?.id === null || row?.id === '') invalidFields.push('ID');

    if (invalidFields.length === 0) {
      for (const key in row) {
        if (typeof row[key] === 'string' && !isNaN(row[key])) {
          row[key] = row[key] === '' ? '' : Number(row[key]);
        }
      }
      return validateGeometryData(row, properties);
    } else {
      setInvalidRows(prev => ({ ...prev, [row._id]: true }));
      dispatch(
        enqueueSnackbar(
          `Please enter the following details: ${invalidFields.join(', ')}`,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      return false;
    }
  };

  const saveGeometry = (row) => {
    if (checkGeometryDataValues(row)) {
      if (row?.isNewRow) {
        delete row?.isNewRow;
        dispatch(createGeometry(row, geometryData?.row?._id));
        setInvalidRows(prev => ({ ...prev, [row._id]: false }));
      } else {
        dispatch(updateGeometry(row, geometryData?.row?._id));
        setInvalidRows(prev => ({ ...prev, [row._id]: false }));
      }
    }
  };

  const getModalTitle = () => {
    return (
      <>
      <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            {geometryData?.row?.name || ''}
          </Grid>
          <Grid item>
          <IconButton
              onClick={onCloseClick}
            >
              <CancelPresentationRounded fontSize='large'/>
            </IconButton>
          </Grid>
        </Grid>
      </>
    );
  };
  const renderActions = ({
    row
  }) => {
    return (
      <Grid container justifyContent="center">
        <IconButton
          variant="solid"
          disabled={geometry.loading}
          onClick={() => {
            saveGeometry(row);
          }}
        >
          <SaveRounded />
        </IconButton>
        <IconButton
        variant="solid"
        disabled={geometry.loading}
        onClick={() => {
          removeSurvey(row);
        }}
      >
        <DeleteRounded/>
      </IconButton>
      </Grid>
    );
  };
  const uploadCSVFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      e.target.value = null;
      dispatch(importWellsGeometryCSVFIle(file, geometryData?.row?._id));
    }
  };

  const updateData = (id, field, value) => {
    setProperties(prevProperties => {
      const updatedProperties = prevProperties.map(item => {
        if (item._id === id) {
          return {
            ...item,
            [field]: value,
          };
        }
        return item;
      });
      return updatedProperties;
    });
  };

  const renderTypeCell = (params) => {
    return (
      <SelectEditValueTypes
        {...params}
        closeCellAfterSelect={true}
        updateData={updateData}
        data={WELLS_GEOMETRY_TYPE} // limit to 50 chars when adding
      />
    );
  };

  const renderValueCell = (params) => {
    return <SelectEditInputTextField numberFormatOnly {...params} updateData={updateData} />;
  };

  if (apiRef.current === null) apiRef.current = {};

  return (
    <ModalDialog
      title={getModalTitle()}
      isOpen={isOpen}
      onClose={onCloseClick}
      dialogClass={classes.dialogModal}
    >
      <Grid container>
      <Grid item className={classes.customTool} xs={1} justifyContent="space-between" >
            <Grid item>
              <Typography variant='button' fontWeight='700' className={geometry.loading || properties[0]?.isNewRow ? classes.disableIcon : ''} onClick={handleAddSurvey}>+ Add</Typography>
            </Grid>
            <Grid item>
              <label className={geometry.loading ? classes.disableImportLabel : classes.importLabel} htmlFor='getCSVFile'>Import</label>
              <input type='file' id='getCSVFile' accept={'.csv'} style={{ display: 'none' }} onChange={uploadCSVFile} />
            </Grid>
          </Grid>
        <Grid item xs={12}>
          <Datagrid
            apiRef={apiRef}
            data={properties}
            sx={{
              marginLeft: '-6px',
              '.MuiDataGrid-cell': {
                fontSize: '1rem',
                fontWeight: 900
              },
              '.MuiDataGrid-columnHeaderTitle': {
                fontSize: '1.2rem',
                fontWeight: '900 !important',
              },
              [`& .${gridClasses.cell}`]: {
                py: 1,
              },
            }}
            loading={geometry.loading}
            columns={columns(renderCell, renderActions, renderTypeCell, renderValueCell)}
            onRowClick={(params, evt) => {
              evt.preventDefault();
              evt.stopPropagation();
            }}
            disableStickyHeader={false}
            autoHeight={false}
            getRowClassName={(params) => {
              return invalidRows[params.id] ? classes.invalidRow : '';
            }}
            autoheight
            height={'50vh'}
            editMode="cell"
            pageSize={100}
            toolbar={{
              options: {
                columns: false,
                filters: false,
                density: false,
                export: false
              },
            }}
          />
        </Grid>
      </Grid>
    </ModalDialog>
  );
};
GeometryModal.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  surveyData: PropTypes.object,
  isOpen: PropTypes.bool.isRequired
};
GeometryModal.defaultProps = {
  surveyData: {}
};
export default GeometryModal;
