import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listJobs } from '../../../store/actions/jobs';
import { Box, Grid } from '@mui/material';
import Map from '../../map';
import { JOBS_SYSTEM_STATUS } from '../../../utils/constants';
import pin from '../../../assets/images/pin.png';
import pinGreen from '../../../assets/images/pin_green.png';
import pinRed from '../../../assets/images/pin_red.png';
import pinAmber from '../../../assets/images/pin_amber.png';
import { Icon } from 'leaflet';
import JobsCard from '../ui/JobsCard';
import InfiniteScrollList from '../ui/InfiniteScrollList';
import usePagination from '../../../hooks/usePagination';
import styles from './styles';
import ScrollToTopButton from '../../ui/ScrollToTopButton';

const pins = {
  default: pin,
  green: pinGreen,
  red: pinRed,
  amber: pinAmber,
};

const getAssetTypesLabel = (assets) => assets.map((a) => a.type).join(', ');

function JobsMobile() {
  const classes = styles();
  const dispatch = useDispatch();
  const jobs = useSelector((state) => state.jobs);
  const mapType = process.env.REACT_APP_JOBS_PAGE_MAP;
  const [jobMarker, setJobMarker] = useState([]);
  const [initialMarker, setInitialMarker] = useState(true);
  const [rowId, setRowId] = useState(null);
  const { pageNumber, changePage, pageData, pageCount } = usePagination(jobs.data, 10);
  const [paginatedJobs, setPaginatedJobs] = useState([]);
  const [loader, setLoader] = useState(false);
  const [iconPinSize, setIconPinSize] = useState([12, 16]);
  const [scrollTarget, setScrollTarget] = useState(undefined);

  useEffect(() => {
    if (!jobs.loading && jobs.data.length === 0) {
      dispatch(listJobs());
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(listJobs());
    }, 120000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if ((jobs.marker_data.length && initialMarker)) {
      setJobMarker(jobs.marker_data);
      setInitialMarker(false);
    }
    if (!jobs.markerFilter && !initialMarker) {
      setJobMarker(jobs.marker_data);
    }
  }, [jobs.marker_data]);

  useEffect(() => {
    if (!jobs.loading && pageNumber !== 0) {
      setPaginatedJobs((prev) => [...prev, ...pageData(pageNumber)]);
    }
  }, [pageNumber]);

  useEffect(() => {
    if (!jobs.loading) {
      changePage(0);
      setPaginatedJobs(jobs.data.slice(0, 10));
    }
  }, [jobs.loading, jobs.data.length]);

  const getPinColor = (status) => {
    switch (status) {
      case JOBS_SYSTEM_STATUS.NONE:
        return 'green';
      case JOBS_SYSTEM_STATUS.FAULTS_ONLY:
      case JOBS_SYSTEM_STATUS.BOTH:
        return 'red';
      case JOBS_SYSTEM_STATUS.WARNINGS_ONLY:
        return 'amber';
      default:
        return 'default';
    }
  };

  const getMapPin = (status) => {
    const pinColor = getPinColor(status);
    const pinIcon = pinColor ? pins[pinColor] : pins.default;

    return (
      pinIcon &&
      new Icon({
        iconUrl: pinIcon,
        iconSize: iconPinSize,
        popupAnchor: [0, -5],
      })
    );
  };

  const loadNextJobs = () => {
    if (!loader && paginatedJobs.length > 1 && pageNumber < pageCount) {
      setLoader(true);
      setTimeout(() => {
        changePage(pageNumber + 1);
        setLoader(false);
      }, 1000);
    }
    setLoader(false);
  };

  return (
    <Grid
      className={classes.jobsContainer}
    >
      <ScrollToTopButton
        scrollTarget={scrollTarget}
        style={{
          position: 'absolute',
          marginTop: '10px',
          right: 10
        }}
        size='small'
      />
      <Map
        mapType={mapType}
        mapData={jobMarker}
        markerData={jobMarker}
        data={jobs}
        latitudeField={'Latitude'}
        longitudeField={'Longitude'}
        mapPinField={'systemStatus'}
        popUpLabel={['Rig']}
        getAssetType={'assets'}
        getAssetTypesLabel={getAssetTypesLabel}
        getMapPin={getMapPin}
        rowId={rowId}
        mapPinField1={'systemStatus'}
        isMobile
        setIconPinSize={setIconPinSize}
      />
      <Box
        ref={node => {
          if (node) {
            setScrollTarget(node);
          }
        }}
        className={classes.jobsListScroll}
      >
        <InfiniteScrollList
          currentPage={pageNumber}
          loadMore={loadNextJobs}
          hasMore={!loader && paginatedJobs.length > 1 && pageNumber < pageCount}
          refParentScroll
        >
          <Box id="back-to-top-anchor" />
          {paginatedJobs?.map((item) => {
            return (
              <JobsCard
                key={item?.grid_id}
                job={item}
                getPinColor={getPinColor}
                setRowId={setRowId}
              />
            );
          })}
        </InfiniteScrollList>
      </Box>
    </Grid>
  );
}

export default JobsMobile;
