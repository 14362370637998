import { makeStyles } from '@mui/styles';
const styles = makeStyles(() => ({
  rigNameContainer: {
    backgroundColor: '#F2F2F7',
    padding: '2px',
    border: '0.5px solid #ebebf0'
  },
  wellNameContainer: {
    backgroundColor: '#FFF',
    paddingLeft: '10px'
  },
  rigname: {
    color: '#999',
    fontSize: '13px'
  },
  wellname: {
    fontSize: '15px',
    fontWeight: 'bold'
  },
  wellDetails: {
    fontSize: '12px',
    color: '#878787'
  }
}));

export default styles;
