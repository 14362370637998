import { USER_TYPE } from '../../utils/constants';
import { renderCustomPrice } from '../../utils/renderCustomPrice';
const masterColumns = (renderStatusField, renderProofOfDelivery) => [
  {
    field: 'timestamp',
    headerName: 'Date',
    width: 300,
    hide: false,
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'orderNumber',
    headerName: 'Order ID',
    width: 300,
    hide: false,
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'quantity',
    headerName: 'Quantity',
    width: 200,
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'price',
    headerName: 'Total Price',
    width: 200,
    renderCell: renderCustomPrice,
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'vendorName',
    headerName: 'Seller',
    width: 200,
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'orderStatus',
    headerName: 'Status',
    hide: false,
    width: 300,
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center',
    renderCell: renderStatusField
  },
  {
    field: 'POD',
    headerName: 'POD',
    hide: false,
    width: 200,
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center',
    renderCell: renderProofOfDelivery
  }
];

const detailColumns = (renderCell, renderActions, userType, renderDescriptionCell) => [
  {
    field: 'Classification',
    headerName: 'Classification',
    hide: true,
    type: 'number',
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: '_id',
    headerName: '_id',
    hide: true,
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'Image',
    headerName: 'Image',
    hide: true,
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'PN',
    headerName: 'PN',
    width: 120,
    hide: true,
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'Category',
    headerName: 'Category',
    width: 140,
    hide: true,
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'Name',
    headerName: 'Name',
    width: 140,
    renderCell,
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'Size',
    headerName: 'Size',
    width: 120,
    hide: false,
    renderCell,
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'Price',
    headerName: 'Price',
    width: 120,
    hide: false,
    renderCell: renderCustomPrice,
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'Description',
    headerName: 'Description',
    hide: false,
    width: 600,
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center',
    // eslint-disable-next-line
    renderCell: userType === USER_TYPE.CUSTOMER_USER || userType === USER_TYPE.CUSTOMER_RIG || userType === USER_TYPE.CUSTOMER_MANAGER || userType === USER_TYPE.CUSTOMER_ADMIN || userType === USER_TYPE.PREMIUM_ADMIN || userType === USER_TYPE.PREMIUM_MARKETPLACE_ADMIN || userType === USER_TYPE.SELLER_MARKETPLACE_ADMIN  ? renderDescriptionCell : renderCell,
  },
  {
    field: 'vendorName',
    headerName: 'Seller',
    hide: false,
    width: 300,
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center',
    renderCell
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 300,
    // eslint-disable-next-line
    hide: userType === USER_TYPE.PREMIUM_ADMIN || userType === USER_TYPE.PREMIUM_MARKETPLACE_ADMIN || userType === USER_TYPE.SELLER_MARKETPLACE_ADMIN || userType === USER_TYPE.CUSTOMER_MANAGER || userType === USER_TYPE.CUSTOMER_ADMIN ||  !renderActions  ? true : false,
    headerAlign: 'center',
    renderCell: renderActions,
  },
];

export { masterColumns, detailColumns };
