import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  IconButton,
  FormControl,
  FilledInput,
  InputLabel,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded';
import RefreshIcon from '@mui/icons-material/Refresh';
import SignatureCanvas from 'react-signature-canvas';
import SalesOrderProcessingModalDialog from '../SalesOrderProcessingModalDialog';
import { createEpicorSaleOrders } from '../../../store/actions/epicor/epicorSaleOrders';
import CurrencyFormat from 'react-currency-format';

import { useDispatch, useSelector } from 'react-redux';

import styles from './styles';

const TicketsView = ({ binNum, onCloseModal }) => {
  const classes = styles();
  const tickets = useSelector((state) => state.tickets);
  const signatureField = useRef([]);
  const [printedName, setPrintedName] = useState([]);
  const [printNameErrorMessage, setPrintNameErrorMessage] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState([]);
  const [signatures, setSignatures] = useState(signatureField);
  const [loadingDialog, setLoadingDialog] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [sigFieldValidation, setSigFieldValidation] = useState([]);
  const [orderCreated, setOrderCreated] = useState(null);
  const [listOrderCreated, setListOrderCreated] = useState('');
  const [listPacksCreated, setListPacksCreated] = useState('');

  const dispatch = useDispatch();
  const {
    saleOrdersPostSuccess,
    saleOrdersPostErrorMessage,
    saleOrdersPostError,
  } = useSelector((state) => state.epicorSaleOrders);
  const { newOrderNum, salesOrdersCreated } = useSelector(
    (state) => state.epicorSaleOrders
  );

  useEffect(() => {
    if (saleOrdersPostSuccess) {
      setIsProcessing(false);
      setOrderCreated(newOrderNum);

      const orderCreated = salesOrdersCreated.map((order) => {
        return order.SaleOrder.OrderNum;
      });

      const packsCreated = salesOrdersCreated.map((order) => {
        return order.ShipParts.PackNum;
      });

      const unique = Array.from(new Set(orderCreated));
      const uniquePacks = Array.from(new Set(packsCreated));
      setListOrderCreated(unique.join(', '));
      setListPacksCreated(uniquePacks.join(', '));
    }
    if (saleOrdersPostError) {
      setIsProcessing(false);
    }
  }, [saleOrdersPostSuccess, saleOrdersPostError]);

  useEffect(() => {
    const signatureFields = tickets.data.map((t, i) => {
      const obj = {};
      obj.id = i;
      obj.message = '';
      return obj;
    });
    setSigFieldValidation(signatureFields);

    const printedNameFields = tickets.data.map(() => {
      return '';
    });
    setPrintedName(printedNameFields);

    const additionalInfoFields = tickets.data.map(() => {
      return '';
    });
    setAdditionalInfo(additionalInfoFields);
  }, [tickets]);

  const closeLoadingDialog = (postResult) => {
    setLoadingDialog(false);
    onCloseModal();
    if (postResult) {
      window.open(
        `/FieldTickets?BinNum=${binNum}&OrderNum=${orderCreated}`,
        '_blank'
      );
    }
  };

  const handleReset = (idx) => () => {
    signatureField.current[idx].clear();
    const newSignatures = { ...signatureField };
    setSignatures(newSignatures);
  };

  const handleCreateSalesOrder = () => {
    const saleOrderData = {
      BinNum: tickets.binNum,
      WarehouseCode: tickets.warehouseCode,
      Type: 5,
      Tickets: [],
      Truck: tickets.transferBox,
      Transfer: [],
    };
    const listOfSignatures = [];
    const transferData = [];
    let printedNameFilled = true;
    const ticketPrepared = tickets.data.map((t, i) => {
      const obj = {};
      obj.PO = tickets.po;
      obj.TicketId = `${tickets.ticketGen}_${t.numTicket}`;
      obj.salesRep = tickets.salesRep;
      obj.salesMgr = tickets.salesMrg;
      obj.signature = signatureField.current[i].toDataURL();
      obj.printedName = printedName[i];
      obj.additionalInfo = additionalInfo[i];
      obj.AFE = tickets.AFE;
      obj.leaseWell = tickets.leaseWell;
      const signature = {};
      if (signatureField.current[i].isEmpty()) {
        signature.id = i;
      } else {
        signature.id = i;
        signature.message = '';
      }
      listOfSignatures.push(signature);

      if (!printedName[i]) {
        setPrintNameErrorMessage('*Required');
        printedNameFilled = false;
      } else {
        setPrintNameErrorMessage('');
      }

      obj.parts = t.papers.map((p, _idx) => {
        const part = {};
        part.PartNum = p.partNumber;
        part.Qty = p.billOut;
        part.UnitPrice = p.price;
        if (p.added && p.added > 0) {
          transferData.push({ PartNum: p.partNumber, Qty: p.added });
        }
        return part;
      });
      return obj;
    });

    setSigFieldValidation(listOfSignatures);
    if (printedNameFilled) {
      saleOrderData.Tickets = ticketPrepared;
      saleOrderData.Transfer = tickets.dataTransfer;
      setLoadingDialog(true);
      setIsProcessing(true);
      dispatch(createEpicorSaleOrders(saleOrderData));
    }
  };

  const handlePrintedName = (e, index) => {
    const newPrintedName = [...printedName];
    newPrintedName[index] = e.target.value;
    setPrintedName(newPrintedName);
  };

  const handleAdditionalInfo = (e, index) => {
    const newAdditionalInfo = [...additionalInfo];
    newAdditionalInfo[index] = e.target.value;
    setAdditionalInfo(newAdditionalInfo);
  };

  return (
    <Grid item xs={12}>
      <div className={classes.ticketsContainer}>
        <Grid container justifyContent="right">
          <Grid item xs={6} textAlign={'right'} style={{ paddingRight: 15 }}>
            <IconButton
              aria-label="close"
              onClick={handleCreateSalesOrder}
            >
              <ShoppingCartRoundedIcon
                sx={{ fontSize: 45 }}
              />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container justifyContent="left">
          <Box
            sx={{
              width: '96%',
              height: '80vh',
              marginTop: '20px',
            }}
          >
            <Grid item xs={12} style={{ paddingBottom: 5 }}>
              {tickets.data.map((ticket, index) => {
                const initVal = 0;
                const getTotalByTicket = () =>
                  ticket.papers.reduce((acc, t) => {
                    return acc + t.totalPrice;
                  }, initVal);

                return (
                  <Grid
                    key={index}
                    container
                    justifyContent="left"
                    style={{
                      borderBottom: 'solid 4px #999',
                      marginBottom: 20,
                      paddingBottom: 10,
                    }}
                  >
                    <Grid
                      item
                      xs={8}
                      textAlign={'left'}
                      style={{ padding: 5, fontSize: 16, color: '#666' }}
                    >
                      Ticket {`${tickets.ticketGen}_${ticket.numTicket}`}
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      textAlign={'right'}
                      style={{ padding: 5, fontSize: 24, color: '#666' }}
                    >
                      <strong>
                        <CurrencyFormat
                          value={getTotalByTicket().toString()}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'$'}
                        />
                      </strong>
                    </Grid>
                    {ticket.papers.map((paper, id) => {
                      return (
                        <Paper
                          key={id}
                          elevation={3}
                          sx={{
                            width: '100%',
                            marginTop: '5px',
                            fontSize: 14,
                            color: '#666',
                            marginBottom: '5px',
                          }}
                        >
                          <Grid container justifyContent="left">
                            <Grid item xs={6} style={{ padding: 5 }}>
                              <strong>{paper.partNumber}</strong>
                            </Grid>
                            <Grid item xs={6} style={{ padding: 5 }}>
                              Bill Out <strong>{paper.billOut}</strong>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            justifyContent="left"
                            style={{ paddingBottom: 25 }}
                          >
                            <Grid item xs={6} style={{ padding: 5 }}>
                              {paper.partDescription.toLowerCase()}
                            </Grid>
                            <Grid item xs={6} style={{ padding: 5 }}>
                              Price{' '}
                              <strong>
                                <CurrencyFormat
                                  value={paper.price}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  prefix={'$'}
                                />
                              </strong>
                            </Grid>
                            <Grid item xs={6}></Grid>
                            <Grid item xs={6} style={{ padding: 5 }}>
                              Total Price{' '}
                              <strong>
                                <CurrencyFormat
                                  value={paper.totalPrice}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  prefix={'$'}
                                />
                              </strong>
                            </Grid>
                          </Grid>
                        </Paper>
                      );
                    })}

                    <Grid
                      container
                      justifyContent="left"
                      style={{ marginTop: 15, marginBottom: 15 }}
                    >
                      <Grid item xs={12}>
                        <FormControl style={{ width: '100%' }}>
                          <InputLabel htmlFor="outlined-adornment-search">
                            Printed Name *
                          </InputLabel>
                          <FilledInput
                            className={classes.printedName}
                            id="outlined-adornment-search"
                            type="text"
                            label="Printed Name"
                            value={printedName[index] || ''}
                            onChange={(event) => {
                              handlePrintedName(event, index);
                            }}
                          />
                          { setPrintNameErrorMessage !== ''
                            ? <Grid item xs={12} color={'#ff0000'}>
                              {printNameErrorMessage}
                            </Grid>
                            : null }
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justifyContent="left"
                      style={{ marginTop: 15, marginBottom: 15 }}
                    >
                      <Grid item xs={12}>
                        <FormControl style={{ width: '100%' }}>
                          <InputLabel htmlFor="outlined-adornment-search">
                            Additional Info
                          </InputLabel>
                          <FilledInput
                            className={classes.printedName}
                            inputProps={{ maxLength: 60 }}
                            id="outlined-adornment-search"
                            type="text"
                            label="Additional Info"
                            value={additionalInfo[index] || ''}
                            onChange={(event) => {
                              handleAdditionalInfo(event, index);
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Box
                      sx={{
                        width: '100%',
                        height: '100px',
                        marginTop: '5px',
                      }}
                    >
                      <Grid container justifyContent="left">
                        <Grid item xs={10}>
                          <SignatureCanvas
                            penColor="black"
                            onEnd={() => {
                              const newSignatures = { ...signatureField };
                              setSignatures(newSignatures);
                            }}
                            canvasProps={{
                              height: 100,
                              className: classes.sigCanvas,
                            }}
                            ref={(el) => (signatureField.current[index] = el)}
                          />
                        </Grid>
                        <Grid item xs={2} textAlign="center">
                          <IconButton
                            aria-label="close"
                            onClick={handleReset(index)}
                            className={classes.refreshButton}
                          >
                            <RefreshIcon sx={{ fontSize: 35 }} />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Box>
                    <Grid item xs={12} color={'#ff0000'}>
                      {sigFieldValidation && sigFieldValidation[index]?.message}
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Grid>
      </div>
      <SalesOrderProcessingModalDialog
        isOpen={loadingDialog}
        isProcessing={isProcessing}
        postResult={saleOrdersPostSuccess}
        postMessage={saleOrdersPostErrorMessage}
        closeLoadingDialog={closeLoadingDialog}
        listOrderCreated={listOrderCreated}
        listPacksCreated={listPacksCreated}
      />
    </Grid>
  );
};

TicketsView.propTypes = {
  binNum: PropTypes.string,
  onCloseModal: PropTypes.func,
  children: PropTypes.node,
};

export default TicketsView;
