export const csvFileToArray = (csv, preserveType) => {
  const rows = csv.split('\n').map(rowElement => rowElement.replace(/\r/g, ''));
  const headers = rows[0].split(',').map(header => header.trim());
  const arrayOfObjects = rows.slice(1).map(row => {
    const values = row.split(',');
    const obj = {};
    headers.forEach((header, index) => {
      obj[header] = preserveType ? values[index].trim() : Number(values[index].trim());
    });
    return obj;
  });
  return arrayOfObjects;
};
