import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { REDUX_ACTIONS } from '../../shared/config/constants';
import Ramps from './tabsPage/Ramps';
import PropTypes from 'prop-types';

const TruFlowSettings = () => {
  const { menu } = useSelector((state) => state);
  const [tabPage, setTabPage] = useState(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    const pageTitle = searchParams.get('ringName');
    if (pageTitle) {
      dispatch({
        type: REDUX_ACTIONS.MENU_SET_TITLE,
        payload: pageTitle,
      });
    }

    return () => {
      dispatch({
        type: REDUX_ACTIONS.MENU_SET_TITLE,
        payload: '',
      });
    };
  }, []);

  useEffect(() => {
    if (menu.tab === 'Ramps') {
      setTabPage(<Ramps />);
    } else {
      setTabPage(null);
    }
  }, [menu.tab]);

  return tabPage || '';
};

TruFlowSettings.propTypes = {
  row: PropTypes.object,
};

export default TruFlowSettings;
