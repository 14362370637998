import { useEffect, useState } from 'react';
import { DirectionsRunRounded, AspectRatioRounded } from '@mui/icons-material';
import {
  Box,
  Grid,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  FilledInput,
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import styles from './styles';
import useAspectRatio from '../../../../hooks/useAspectRatio';
import { listAlarmHistory } from '../../../../store/actions/alarmHistory';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { CALENDER_PROPS } from '../../../../utils/constants';
import { REDUX_ACTIONS } from '../../../../shared/config/constants';

const AlarmsHistoryControls = () => {
  const classes = styles();
  const dispatch = useDispatch();
  const location = useLocation();

  const { preferences } = useSelector((state) => state.currentUser);

  const [selectedStartDateTime, setSelectedStartDateTime] = useState(null);
  const [selectedEndDateTime, setSelectedEnDateTime] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);

  const handleSearchTerm = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleStartTime = (date) => {
    setSelectedStartDateTime(date);
  };
  const handleEndTime = (date) => {
    setSelectedEnDateTime(date);
  };

  const handleSearch = () => {
    const body = {
      searchValue: searchTerm,
      startDate: selectedStartDateTime,
      endDate: selectedEndDateTime
    };
    dispatch({
      type: REDUX_ACTIONS.ALARM_HISTORY_SEARCH_CONTROL,
      payload: body,
    });
    dispatch({
      type: REDUX_ACTIONS.ALARM_HISTORY_SET_CURRENT_PAGE,
      payload: 1
    });
    dispatch(listAlarmHistory(body));
  };

  const handleAspectRatio = useAspectRatio();

  useEffect(() => {
    const start = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
    const end = new Date();
    const body = {
      searchValue: searchTerm,
      startDate: start,
      endDate: end
    };
    setSelectedStartDateTime(start);
    setSelectedEnDateTime(end);
    dispatch(listAlarmHistory(body));
  }, [location.pathname]);

  return (
    <Grid alignItems="flex-end" justifyContent="flex-end" sx={{ marginLeft: 'auto' }}>
      <Box className={classes.container}>
        <Box>
          <FormControl>
            <InputLabel
              htmlFor="outlined-adornment-search"
              inputprops={{ classes: { root: classes.searchBar } }}
            >
              Search
            </InputLabel>
            <FilledInput
              className={classes.searchBar}
              id="outlined-adornment-search"
              type="text"
              label="Search"
              value={searchTerm || ''}
              onChange={handleSearchTerm}
            />
          </FormControl>
        </Box>
        <Box className={classes.dateContainer}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              {...CALENDER_PROPS}
              label="Start Date"
              value={selectedStartDateTime}
              onChange={handleStartTime}
              className={classes.dateTimePicker}
              classes={{
                switchHeader: classes.datePickerHeader,
              }}
              disableFuture
              textField={(params) => <TextField {...params} />}
            />
            <DateTimePicker
              {...CALENDER_PROPS}
              label="End Date"
              value={selectedEndDateTime}
              onChange={handleEndTime}
              className={classes.dateTimePicker}
              disabled={!selectedStartDateTime}
              disableFuture
              minDate={selectedStartDateTime}
              textField={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Box>
        <Box className={classes.runActionContainer}>
          <IconButton
            size="large"
            aria-label="orders"
            onClick={handleSearch}
          >
            <DirectionsRunRounded />
          </IconButton>
        </Box>
        <Box className={classes.aspectRatioContainer}>
          <IconButton
            edge="end"
            onClick={() => {
              handleAspectRatio(!preferences.aspectChanged);
            }}
          >
            <AspectRatioRounded />
          </IconButton>
        </Box>
      </Box>
    </Grid>
  );
};

export default AlarmsHistoryControls;
