import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';

const styles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 20
  },
  paper: {
    backgroundColor: '#f0f0f0',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '1350px'
  },
  dateTimePicker: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    alignSelf: 'flex-start',
  },
  button: {
    marginTop: '30px',
    borderRadius: '0',
    margin: theme.spacing(1),
    backgroundColor: grey[600],
    '&:hover': {
      backgroundColor: grey[700],
    },
    color: 'white',
    alignSelf: 'center',
    '& .MuiButton-label': {
      fontSize: '13px',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  modalLabel: {
    marginBottom: '20px',
    alignSelf: 'flex-end',
  }
}));

export default styles;
