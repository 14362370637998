import { makeStyles } from '@mui/styles';
const styles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 20
  },
  paper: {
    backgroundColor: '#f0f0f0',
    boxShadow: theme.shadows[5],
    padding: '40px',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '1232px'
  },
  dateTimePicker: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    alignSelf: 'flex-start',
  },
  closeModalButton: {
    marginLeft: '40px',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));
export default styles;
