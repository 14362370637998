/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Autocomplete, Box, Chip, TextField } from '@mui/material';
import { fetchCompanyUsers } from '../../../store/actions/users';
import { useDispatch, useSelector } from 'react-redux';
import { CancelPresentation } from '@mui/icons-material';

const EmailsAutocompleteComponent = ({ title, data, onChange, options }) => {
  const [inputValue, setInputValue] = React.useState('');

  return (
        <Box sx={{ mb: 2.5 }}>
        <Typography sx={{ fontWeight: 'bold', fontSize: '15px' }} gutterBottom>
            {title}
          </Typography>
                  <Autocomplete
                      multiple
                      id={title}
                      options={options}
                      value={data}
                      onChange={onChange}
                      inputValue={inputValue}
                      onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                      }}
                      freeSolo
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => {
                          const { key, ...tagProps } = getTagProps({ index });
                          return (
                                  <Chip variant="outlined" label={option} key={key} {...tagProps} />
                          );
                        })
                      }
                      renderInput={(params) => (
                          <TextField
                              {...params}
                              variant="outlined"
                              label=""
                              placeholder={'Add ' + title}
                          />
                      )}
                  />
        </Box>
  );
};

export default function PersonnelDialog({ open, handleClose, rowData, handleSave }) {
  const [orderApprovers, setOrderApprovers] = useState([]);
  const [quoteApprovers, setQuoteApprovers] = useState([]);
  const [admins, setAdmins] = useState([]);
  const dispatch = useDispatch();
  const companyUsers = useSelector((state) => state.users.companyUsers);

  useEffect(() => {
    setOrderApprovers(rowData?.orderApprovers || []);
    setQuoteApprovers(rowData?.quoteApprovers || []);
    setAdmins(rowData?.admin || []);
  }, [rowData]);

  useEffect(() => {
    dispatch(fetchCompanyUsers(rowData?.companyId || rowData?._id));
  }, []);

  return (
    <React.Fragment>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth='md'
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {rowData.name || rowData.firstName}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CancelPresentation />
        </IconButton>
        <DialogContent dividers sx={{ minWidth: '600px' }}>
            <EmailsAutocompleteComponent
                title='Order Approvers'
                data={orderApprovers}
                onChange={(event, values) => setOrderApprovers([...values])}
                options={companyUsers}
            />
            <EmailsAutocompleteComponent
                title='Quote Approvers'
                data={quoteApprovers}
                onChange={(event, values) => setQuoteApprovers([...values])}
                options={companyUsers}
            />
            <EmailsAutocompleteComponent
                title='Admins'
                data={admins}
                onChange={(event, values) => setAdmins([...values])}
                options={companyUsers}
            />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => {
            const updatedData = { ...rowData };
            updatedData.orderApprovers = orderApprovers;
            updatedData.quoteApprovers = quoteApprovers;
            updatedData.admin = admins;
            handleSave(updatedData);
            handleClose();
          }}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
