import { loginUser, logoutUser } from './../store/actions';
import PropTypes from 'prop-types';
import { REDUX_ACTIONS } from './../shared/config/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import AuthContext from './AuthContext';

const AuthProvider = ({ children }) => {
  const user = useSelector((state) => ({ ...state.currentUser }));

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleLogin = async (user) => {
    dispatch(loginUser(user));
  };

  useEffect(() => {
    const inMemmoryUser = JSON.parse(localStorage.getItem('user'));
    if (inMemmoryUser) {
      dispatch({
        type: REDUX_ACTIONS.SET_USER,
        payload: { ...inMemmoryUser },
      });
    }
  }, []);

  const handleLogout = () => {
    dispatch(logoutUser());
    navigate('/Login');
  };

  const value = {
    user,
    onLogin: handleLogin,
    onLogout: handleLogout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.object.isRequired,
};

export default AuthProvider;
