import fetch from '../../../api/fetch';
import { REDUX_ACTIONS } from '../../../shared/config/constants';
import { parseError } from '../../../utils/fetchErrorParser';
import { enqueueSnackbar } from '../snackbar';

export const listEpicorReleases = (params) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.EPICOR_RELEASES_LIST_LOADING,
  });

  const body = {
    type: 'GET',
    url: '/releases',
    params: {
      top: 2500,
      company: 'POT',
      filter: `BinNum_c eq '${params.binNumValue}'`,
    },
  };

  return fetch
    .post('epicorProxy', body)
    .then((resp) => {
      if (!resp.ok) {
        return Promise.reject(resp);
      }
      return resp.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.EPICOR_RELEASES_LIST_SUCCESS,
        payload: {
          epicorReleases: data.data,
          paginatedElements: data.data.length,
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);

      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.EPICOR_RELEASES_LIST_ERROR,
        payload: { ...error, message: errorMessage },
      });
    });
};
