import { makeStyles } from '@mui/styles';

const styles = makeStyles(() => ({
  actionButton: {
    position: 'relative',
    bottom: 15
  }
}));

export default styles;
