import { useEffect, useRef, useState } from 'react';
import { TagRounded, Check, Close, FastRewind, Eject, FastForward } from '@mui/icons-material';
import { getAssetData, postTag } from '../../../../store/actions/assetData';
import {
  Box,
  Drawer,
  IconButton,
  TextField,
} from '@mui/material';
import Switch from '@mui/material/Switch';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../../../hooks/useAuth';
import { ASSET_DATA_TYPES, REDUX_ACTIONS } from '../../../../shared/config/constants';
import { stringifyDeepEqual } from '../../../../utils/objectHelpers';
import styles from './styles';

const AssetDataMobileControls = () => {
  const classes = styles();
  const dispatch = useDispatch();
  const assetData = useSelector((state) => state.assetData);
  const { columnVisibilityModel } = useSelector((state) => state.currentUser.preferences.datachartSettings);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [assetDataType] = useState(
    searchParams.get('assetDataType') ?? ASSET_DATA_TYPES.ACTUATORDATA
  );
  const assetDataId = assetDataType.replace('ActuatorData_', '');
  const [filterId] = useState(searchParams.get('filterId') ?? '2');
  const { user } = useAuth();
  const [hashtag, setHashtag] = useState(false);
  const [columns, setColumns] = useState(false);
  const [switches, setSwitches] = useState({});
  const [eventText, setEventText] = useState('');
  const [enableForward, setEnableForward] = useState(false);
  const counter = useRef(0);
  const actionType = useRef(null);

  useEffect(() => {
    if (!Object.keys(switches).length) {
      setSwitches(columnVisibilityModel);
    } else if (Object.keys(switches).length && !stringifyDeepEqual(switches, columnVisibilityModel)) {
      dispatch({
        type: REDUX_ACTIONS.SET_DATA_CHART_PREFERENCES,
        payload: {
          columnsInitialState: columnVisibilityModel,
          columnVisibilityModel: switches,
          datakeys: Object.keys(switches).filter(key => switches[key] === true && key !== 'timestamp')
        },
        filters: {
          assetDataId,
          filterId
        }
      });
    }
  }, [switches, columnVisibilityModel]);

  const handleSwitchToggle = (key) => {
    setSwitches(prevState => ({
      ...prevState,
      [key]: !prevState[key]
    }));
  };

  const handleOnChange = (e) => {
    setEventText(e.target.value);
  };

  const handleOnSubmit = () => {
    const filterId = searchParams.get('filterId');
    const jobId = searchParams.get('assetDataType').split('_')[1];
    const start = assetData.data[0].timestamp;
    const end = assetData.data[assetData.data.length - 1].timestamp;
    const event = eventText;

    dispatch(postTag(user, jobId, start, end, event, filterId));
    setHashtag(false);
  };

  const handleClose = () => {
    setHashtag(false);
  };

  const handlePauseEvent = (_evt) => {
    actionType.current = null;
    setEnableForward(false);
    if (assetData.dateRange.paused) {
      dispatch({
        type: REDUX_ACTIONS.ASSET_DATA_SET_DATES,
        payload: {
          dateRange: {
            start: null,
            end: null,
          },
        },
      });
      dispatch(
        getAssetData(
          assetDataType,
          {
            preset: assetData.dateRange.preset,
          },
          true,
          false
        )
      );
      counter.current = 0;
    }
    dispatch({
      type: REDUX_ACTIONS.ASSET_DATA_TOOGLE_PAUSE,
    });
    dispatch({
      type: REDUX_ACTIONS.SET_DATA_CHART_PREFERENCES,
      payload: {
        viewportMinimum: null,
        viewportMaximum: null,
      },
    });
  };

  const setDateRange = (type) => {
    actionType.current = type;
    if (assetData.data.length === 0) {
      dispatch({
        type: REDUX_ACTIONS.ASSET_DATA_SET_DATES,
        payload: {
          dateRange: {
            start: null,
            end: null,
          },
        },
      });
      return;
    }

    const sortedData = assetData.data;

    if (type === 'backward') {
      const start = assetData.dateRange.start
        ? new Date(assetData.dateRange.start)
        : new Date(sortedData[0].timestamp);
      const end = new Date(
        start.getTime() - assetData.dateRange.preset * 60000
      );

      counter.current++;

      dispatch({
        type: REDUX_ACTIONS.ASSET_DATA_SET_DATES,
        payload: {
          dateRange: {
            start: end.toLocaleString(),
            end: start.toLocaleString(),
          },
        },
      });
      dispatch(
        getAssetData(
          assetDataType,
          {
            preset: assetData.dateRange.preset,
            start: end.toLocaleString(),
            end: start.toLocaleString()
          },
          true,
          false
        )
      );
    } else {
      const start = assetData.dateRange.end
        ? new Date(assetData.dateRange.end)
        : new Date(sortedData[sortedData.length - 1].timestamp);
      const end = new Date(
        new Date(sortedData[sortedData.length - 1].timestamp).getTime() +
          assetData.dateRange.preset * 60000
      );

      counter.current--;

      if (!counter.current) {
        setEnableForward(false);
      }

      dispatch({
        type: REDUX_ACTIONS.ASSET_DATA_SET_DATES,
        payload: {
          dateRange: {
            start: start.toLocaleString(),
            end: end.toLocaleString(),
          },
        },
      });

      dispatch(
        getAssetData(
          assetDataType,
          {
            preset: assetData.dateRange.preset,
            start: start.toLocaleString(),
            end: end.toLocaleString()
          },
          true,
          false
        )
      );
    }
  };

  return (
      <>
        <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <IconButton
          size="small"
          onClick={() => setColumns(true)}
          className={classes.iconSpace}
        >
          <StackedLineChartIcon />
        </IconButton>
        <IconButton
          size="small"
          className={classes.iconSpace}
          onClick={() => setHashtag(true)}
        >
          <TagRounded />
        </IconButton>
          <IconButton
            size="small"
            className={actionType.current === 'backward' ? classes.pressed : classes.iconSpace}
            aria-label="orders"
            onClick={() => {
              setDateRange('backward');
              setEnableForward(true);
              dispatch({
                type: REDUX_ACTIONS.ASSET_DATA_PAUSE,
              });
            }}
          >
            <FastRewind />
          </IconButton>
          <IconButton
            size="small"
            aria-label="orders"
            onClick={handlePauseEvent}
            className={assetData.dateRange.paused ? classes.pressed : classes.iconSpace}
          >
            <Eject
              sx={{
                transform: 'rotate(90deg)',
              }}
            />
          </IconButton>
          <IconButton
            size="small"
            aria-label="orders"
            disabled={!enableForward}
            className={actionType.current === 'forward' && enableForward ? classes.pressed : classes.iconSpace}
            onClick={() => {
              setDateRange('forward');
              dispatch({
                type: REDUX_ACTIONS.ASSET_DATA_PAUSE,
              });
            }}
          >
            <FastForward />
          </IconButton>
          <Drawer
            anchor={'bottom'}
            open={hashtag}
            onClose={() => setHashtag(false)}
          >
          <IconButton
            edge="end"
            onClick={handleClose}
            sx={{ margin: 1, marginLeft: 'auto', }}
          >
            <Close />
          </IconButton>
          <Box sx={{ padding: 2, display: 'flex', alignItems: 'center' }}>
            <TextField
              fullWidth
              variant='outlined'
              onChange={handleOnChange}
              aria-label="minimum height"
              minRows={3}
              multiline
              placeholder="Hashtag"
            />
             <IconButton
                edge="end"
                onClick={handleOnSubmit}
              >
                <Check />
              </IconButton>
          </Box>
          </Drawer>
        <Drawer
          anchor={'left'}
          open={columns}
          onClose={() => setColumns(false)}
        >
          <Box sx={{ padding: 2 }}>
          {Object.entries(switches).map(([key, value]) => {
            if (key === 'timestamp') return null;
            return (
              <Box key={key}>
                <Switch
                    checked={value}
                    onChange={() => handleSwitchToggle(key)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                  {key}
              </Box>
            );
          })}
          </Box>
        </Drawer>
        </Box>
      </>
  );
};

export default AssetDataMobileControls;
