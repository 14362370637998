import { makeStyles } from '@mui/styles';
const styles = makeStyles(() => ({
  jobsContainer: {
    paddingTop: '158px',
    maxHeight: '100vh',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },
  jobsListScroll: {
    overflowY: 'auto',
    height: '100%',
    paddingBottom: '10px'
  }
}));

export default styles;
