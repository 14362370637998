const columns = (renderCell) => [
  {
    field: 'label',
    headerName: 'Email ID',
    width: 350,
    renderCell,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'y',
    headerName: 'Count',
    width: 150,
    renderCell,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'division',
    headerName: 'Division',
    width: 300,
    renderCell,
    headerAlign: 'center',
    align: 'center'
  },
];

export default columns;
