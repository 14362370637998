import { REDUX_ACTIONS } from '../../shared/config/constants';

export const enqueueSnackbar = (
  message,
  variant,
  key = new Date().getTime() + Math.random()
) => {
  return {
    type: REDUX_ACTIONS.SNACKBAR_ENQUEUE,
    notification: {
      message,
      key,
      options: {
        variant,
        key,
      },
    },
  };
};

export const closeSnackbar = (key) => ({
  type: REDUX_ACTIONS.SNACKBAR_CLOSE,
  dismissAll: !key, // dismiss all if no key has been defined
  key,
});

export const removeSnackbar = (key) => ({
  type: REDUX_ACTIONS.SNACKBAR_REMOVE,
  key,
});
