import { REDUX_ACTIONS } from '../../shared/config/constants';

const initialState = {
  notifications: [],
};

const snackbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.SNACKBAR_ENQUEUE:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            key: action.key,
            ...action.notification,
          },
        ],
      };
    case REDUX_ACTIONS.SNACKBAR_CLOSE:
      return {
        ...state,
        notifications: state.notifications.map((notification) =>
          action.dismissAll || notification.key === action.key
            ? { ...notification, dismissed: true }
            : { ...notification }
        ),
      };
    case REDUX_ACTIONS.SNACKBAR_REMOVE:
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification.key !== action.key
        ),
      };
    default:
      return state;
  }
};

export default snackbarReducer;
