import { makeStyles } from '@mui/styles';

const styles = makeStyles(({ breakpoints }) => {
  return {
    searchBarContainer: {
      height: '100%',
      width: 'auto',
      display: 'block',
      paddingTop: '10px',
      overflowY: 'auto',
      background: '#f0f0f0',
      '&::-webkit-scrollbar': {
        width: '10px',
        backgroundColor: '#F5F5F5'
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
        backgroundColor: '#F5F5F5',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#666',
        border: '2px solid #555'
      },
      [breakpoints.down(500)]: {
        padding: '0px',
        width: '100%',
      }
    },
    searchBar: {
      background: '#FFF',
      fontSize: '12px',
      padding: '5px 0',
      borderRadius: '50px',
      border: '4px solid #CCC',
      flex: 1,
      [breakpoints.down(500)]: {
        fontSize: '10px',
        padding: '0',
      }
    },
    icon: {
      color: '#000',
      fontSize: '26px',
      [breakpoints.down(500)]: {
        fontSize: '20px',
        margin: '0 10px',
      }
    },
    cardsContainer: {
      display: 'flex',
      width: '1280px',
      margin: 'auto',
      marginTop: '80px',
      flexWrap: 'wrap',
      background: '#f0f0f0',
      justifyContent: 'center',
      [breakpoints.down(500)]: {
        width: '100%',
        marginTop: '90px',
        margin: '0 auto',
        padding: 0,
      }
    },
    logo: {
      height: 65,
      border: 'none',
      [breakpoints.down(500)]: {
        height: 50,
        marginRight: 8
      }
    },
    fixedSearch: {
      position: 'fixed',
      zIndex: 1000,
      backgroundColor: '#F0F0F0',
      maxWidth: '1280px',
      top: 0,
      padding: '14px',
      margin: 0,
      boxShadow: '0 5px 5px rgba(0,0,0,0.1), 0 1px 2px rgba(0,0,0,0.1)',
      [breakpoints.down(500)]: {
        padding: '10px',
      }
    }
  };
});

export default styles;
