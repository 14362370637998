/* eslint-disable no-prototype-builtins */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ModalDialog from '../ModalDialog';
import { Box, Checkbox, FilledInput, FormControlLabel, FormGroup, Grid, InputAdornment } from '@mui/material';
import { SPECIAL_INSTRUCTIONS } from '../../../utils/constants';
import _ from 'lodash';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';

const SpecialInstructionsDialog = ({
  isOpen,
  data,
  onCloseClick,
  handleSave
}) => {
  const [checkedValues, setCheckedValues] = useState({});
  const [notificationAmount, setNotificationAmount] = useState(data?.specialInstructions ? data?.specialInstructions['2'] : 0);

  useEffect(() => {
    if (isOpen && data?.specialInstructions) {
      setCheckedValues(data?.specialInstructions || {});
    }
  }, [isOpen]);

  const handleChange = (event) => {
    const value = Number(event.target.value);
    setCheckedValues((prevValues) => {
      const newValues = { ...prevValues };
      // eslint-disable-next-line no-prototype-builtins
      if (newValues.hasOwnProperty(value)) {
        delete newValues[value];
      } else {
        newValues[value] = value === 2 ? 0 : '';
      }
      return newValues;
    });
  };

  const onDialogCloseClick = () => {
    onCloseClick();
  };

  const createDialogButtons = () => [
    {
      text: 'Cancel',
      placeholder: 'Cancel',
      'aria-label': 'Cancel',
      onClick: onDialogCloseClick,
    },
    {
      text: 'Ok',
      placeholder: 'Ok',
      'aria-label': 'ok',
      onClick: () => {
        if (!_.isEqual(data?.specialInstructions, checkedValues)) {
          const updatedData = { ...data };
          updatedData.specialInstructions = { ...checkedValues };
          handleSave(updatedData);
        }
        onCloseClick();
      },
    },
  ];

  return (
    <ModalDialog
      title={`${data?.name || data?.companyName} - Special Instructions`}
      isOpen={isOpen}
      onClose={onDialogCloseClick}
      buttons={createDialogButtons()}
      maxWidth={'sm'}
    >
          <Grid sx={{ padding: 4 }}>
              <FormGroup>
                  {SPECIAL_INSTRUCTIONS.map((item) => (
                   <Box key={item.value} display='flex' alignItems='center' sx={{ mb: 2 }}>
                      <FormControlLabel
                          control={
                              <Checkbox
                                  value={item.value}
                                  checked={checkedValues.hasOwnProperty(item.value)}
                                  onChange={handleChange}
                              />
                          }
                          label={item.label}
                          componentsProps={{ typography: { variant: 'body2' } }}
                      />
                      {item.value === 2 && checkedValues.hasOwnProperty(2) && (
                        <Box>
                          <FilledInput
                            id="outlined-adornment-search"
                            type="number"
                            label="Notification Amount"
                            value={notificationAmount}
                            onChange={(e) => {
                              setNotificationAmount(e.target.value);
                              setCheckedValues((prevValues) => {
                                const newValues = { ...prevValues };
                                newValues['2'] = Number(e.target.value);
                                return newValues;
                              });
                            }}
                            inputProps={{ style: { fontSize: 18, margin: 0, padding: 4 } }}
                            sx={{
                              width: '140px',
                              '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                display: 'none',
                              },
                              '& input[type=number]': {
                                MozAppearance: 'textfield',
                              },
                            }}
                            startAdornment={
                              <InputAdornment position="start">
                                  <AttachMoneyOutlinedIcon fontSize='small' />
                              </InputAdornment>
                            }
                          />
                        </Box>
                      )}
                    </Box>
                  ))}
              </FormGroup>
        </Grid>
    </ModalDialog>
  );
};

SpecialInstructionsDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  data: PropTypes.object,
  onCloseClick: PropTypes.func.isRequired,
  handleSave: PropTypes.func
};

export default SpecialInstructionsDialog;
