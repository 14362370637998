/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import 'leaflet/dist/leaflet.css';
import {
  DataGridPro,
  GridColumnMenu,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarExportContainer,
} from '@mui/x-data-grid-pro';
import styles from './styles';
import { renderCell } from './table_config';
import PropTypes from 'prop-types';
import { Paper, styled, ListItemText, MenuItem, Button } from '@mui/material';
import { CustomCsvExport } from '../../../utils/customCSVexport';
import { useDispatch } from 'react-redux';
import { downloadTransactionHistory } from '../../../store/actions/transactionHistory';

const Item = styled(Paper)(({ theme, containerheight }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: containerheight ?? '45vh',
}));

const CustomUserItem = () => {
  return (
    <MenuItem>
      <ListItemText>Select Color</ListItemText>
    </MenuItem>
  );
};

const CustomColumnMenu = (props) => {
  return (
    <GridColumnMenu
      {...props}
      slots={{ ColumnMenuUserItem: CustomUserItem }}
      slotProps={{ displayOrder: 15 }}
    />
  );
};

const Datagrid = (props) => {
  const classes = styles();
  const dispatch = useDispatch();
  const [_pageSize] = useState(props.pageSize ?? 6);
  const [gridProps, setGridProps] = useState({});
  const cleannedProps = { ...props };
  const columnVisibilityModel = props.columnVisibilityModel;

  useEffect(() => {
    if (Object.keys(columnVisibilityModel).length > 0) {
      setGridProps((state) => ({
        ...state,
        columnVisibilityModel: { ...columnVisibilityModel },
      }));
    }
  }, [columnVisibilityModel]);
  delete cleannedProps.columnVisibilityModel;

  const CustomToolbar = () => {
    const renderToolbarOptions = () => {
      if (cleannedProps.toolbar) {
        const CustomButton = ({ onClick, children }) => (
          // filter undesired props like hideMenu
          <Button onClick={onClick} style={{ padding: '5px' }}>
            {children}
          </Button>
        );
        const CustomExportButton = () => (
          <GridToolbarExportContainer>
            <CustomButton
              onClick={
                () => {
                  if (cleannedProps.toolbar.options.export.downloadTransactionHistory) {
                    dispatch(downloadTransactionHistory());
                  } else {
                    CustomCsvExport(
                      cleannedProps.toolbar.options.export.assetData,
                      [
                        'timestamp',
                        ...cleannedProps.toolbar.options.export.selectedColumns.datakeys
                      ]
                    );
                  }
                }
              }
              style={{ padding: '5px' }}
            >
              Export CSV
            </CustomButton>
          </GridToolbarExportContainer>
        );

        return (
          <GridToolbarContainer>
            {cleannedProps.toolbar.options?.columns && (
              <GridToolbarColumnsButton />
            )}
            {cleannedProps.toolbar.options?.filters && (
              <GridToolbarFilterButton />
            )}
            {cleannedProps.toolbar.options?.density && (
              <GridToolbarDensitySelector />
            )}
          {cleannedProps.toolbar.options.export.enabled &&
              (cleannedProps.toolbar.options.export.options
                ? <CustomExportButton />
                : <GridToolbarExport />
              )
            }
          </GridToolbarContainer>
        );
      } else {
        return <GridToolbarContainer></GridToolbarContainer>;
      }
    };

    return renderToolbarOptions();
  };

  return (
    <Item elevation={0} containerheight={props.height}>
      <DataGridPro
        autoHeight
        sx={{ '.MuiDataGrid-columnHeaderTitle': { fontWeight: 'bolder' } }}
        NoRowsOverlay={() => <label>No data to display</label>}
        pageSize={_pageSize}
        getRowId={(row) => row._id}
        /**
            NOTE: The position for the {...gridProps} call matter since we required some properties or methods to be overriten
            for some cases, please keep that in mind when need to add, remove or move any property
        */
        {...gridProps}
        rowsPerPageOptions={[6, 10, 25, 50, 100, 1000]}
        pagination
        className={classes.dataGrid}
        {...cleannedProps}
        rows={props.data}
        columns={
          props.columns instanceof Function
            ? props.columns(renderCell)
            : props.columns
        }
        slots={{ columnMenu: CustomColumnMenu }}
        components={{ Toolbar: CustomToolbar }}
      />
    </Item>
  );
};

Datagrid.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.oneOfType([
    PropTypes.func.isRequired,
    PropTypes.array.isRequired,
  ]),
  columnVisibilityModel: PropTypes.object,
  pageSize: PropTypes.number,
  height: PropTypes.string,
};

Datagrid.defaultProps = {
  loading: false,
  columnVisibilityModel: {},
  initialState: {},
};

export default Datagrid;
