import {
  Eject,
  FastForward,
  FastRewind,
  QueryStats,
  History
} from '@mui/icons-material';
import { Slider, styled, Stack, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { REDUX_ACTIONS } from '../../../../shared/config/constants';
import {
  DEFAULT_MINUTES_PRESET,
  DEFAULT_PRESET,
  HOURS_RANGE_PRESETS,
  MINUTES_RANGE_PRESETS,
} from '../../../../utils/constants';
import { useLocation } from 'react-router-dom';
import styles from './styles';
import DateRangeModal from './DateRangeModal';

const CustomSlider = styled(Slider)({
  color: 'gray',
  height: 10,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 15,
    width: 10,
    borderRadius: 0,
    backgroundColor: 'gray',
    // border: 'none',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-markLabel': {
    fontSize: '1.0rem',
  },
  '& .MuiSlider-markLabelActive': {
    fontSize: '1.0rem',
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: '0.5rem',
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: 'gray',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

const DateRangeSlider = () => {
  const classes = styles();
  const assetData = useSelector((state) => state.assetData);
  const { preferences } = useSelector((state) => state.currentUser);
  const [isBackward, setIsBackward] = useState();
  const [counter, setCounter] = useState(0);
  const dispatch = useDispatch();
  const [isPresetInHours, setIsPresetInHours] = useState(true);
  const location = useLocation();

  const setDateRange = () => {
    if (assetData.data.length === 0) {
      dispatch({
        type: REDUX_ACTIONS.ASSET_DATA_SET_DATES,
        payload: {
          dateRange: {
            start: null,
            end: null,
          },
        },
      });
      return;
    }

    // TODO check if data is already sorted from the request
    // const sortedData = assetData.data.sort(
    //  (asset) => new Date(asset.timestamp)
    // );

    const sortedData = assetData.data;

    if (typeof isBackward === 'undefined' || isBackward) {
      const start = assetData.dateRange.start
        ? new Date(assetData.dateRange.start)
        : new Date(sortedData[0].timestamp);
      const end = new Date(
        start.getTime() - assetData.dateRange.preset * 60000
      );

      dispatch({
        type: REDUX_ACTIONS.ASSET_DATA_SET_DATES,
        payload: {
          dateRange: {
            start: end.toLocaleString(),
            end: start.toLocaleString(),
          },
        },
      });
    } else {
      const start = assetData.dateRange.end
        ? new Date(assetData.dateRange.end)
        : new Date(sortedData[sortedData.length - 1].timestamp);
      const end = new Date(
        new Date(sortedData[sortedData.length - 1].timestamp).getTime() +
          assetData.dateRange.preset * 60000
      );

      dispatch({
        type: REDUX_ACTIONS.ASSET_DATA_SET_DATES,
        payload: {
          dateRange: {
            start: start.toLocaleString(),
            end: end.toLocaleString(),
          },
        },
      });
    }
  };

  const handleSliderChange = (evt, value) => {
    setIsBackward(undefined);
    dispatch({
      type: REDUX_ACTIONS.ASSET_DATA_SET_PRESET,
      payload: value,
    });
    dispatch({
      type: REDUX_ACTIONS.ASSET_DATA_SET_DATES,
      payload: {
        dateRange: {
          start: null,
          end: null,
        },
      },
    });
    if (assetData.dateRange.paused) {
      dispatch({
        type: REDUX_ACTIONS.ASSET_DATA_TOOGLE_PAUSE,
      });
    }
  };

  const handlePauseEvent = (_evt) => {
    if (assetData.dateRange.paused) {
      setIsBackward(undefined);
      dispatch({
        type: REDUX_ACTIONS.ASSET_DATA_SET_DATES,
        payload: {
          dateRange: {
            start: null,
            end: null,
          },
        },
      });
    }
    dispatch({
      type: REDUX_ACTIONS.ASSET_DATA_TOOGLE_PAUSE,
    });
    dispatch({
      type: REDUX_ACTIONS.SET_DATA_CHART_PREFERENCES,
      payload: {
        viewportMinimum: null,
        viewportMaximum: null,
      },
    });
  };

  const handleOpenDateRangeModal = (_evt) => {
    dispatch({
      type: REDUX_ACTIONS.ASSET_DATA_TOOGLE_DATES_MODAL,
      payload: true,
    });
  };

  useEffect(() => {
    setDateRange();
  }, [counter]);

  useEffect(() => {
    dispatch({
      type: REDUX_ACTIONS.ASSET_DATA_SET_DATES,
      payload: {
        dateRange: {
          start: null,
          end: null,
        },
      },
    });
  }, [assetData.dateRange.preset]);

  const handleTogglePresets = (_evt) => {
    setIsPresetInHours((state) => !state);
  };
  useEffect(() => {
    const newDefaultPreset = isPresetInHours
      ? DEFAULT_PRESET
      : DEFAULT_MINUTES_PRESET;
    handleSliderChange(null, newDefaultPreset);
  }, [isPresetInHours]);

  return preferences.chartViewMode !== 3
    ? (
    <Box
      sx={{
        display: 'flex',
        marginLeft: 'auto',
      }}
    >
      <Box display="flex">
      <IconButton
          size="large"
          aria-label="orders"
          onClick={() => {
            if (!assetData.dateRange.paused) {
              handlePauseEvent();
            }
            handleOpenDateRangeModal();
          }
          }
        >
          <History />
        </IconButton>
        <IconButton
          size="large"
          aria-label="orders"
          onClick={() => {
            setIsBackward(true);
            setCounter((state) => ++state);
            dispatch({
              type: REDUX_ACTIONS.ASSET_DATA_PAUSE,
            });
          }}
        >
          <FastRewind className={isBackward ? classes.pressed : ''} />
        </IconButton>
        <IconButton size="large"
          aria-label="orders" onClick={handlePauseEvent}>
          <Eject
            sx={{
              transform: 'rotate(90deg)',
            }}
            className={assetData.dateRange.paused ? classes.pressed : ''}
          />
        </IconButton>
        {location.pathname !== '/Data' && (
          <IconButton
          size="large"
          aria-label="orders"
            onClick={() => {
              setIsBackward(false);
              setCounter((state) => --state);
              dispatch({
                type: REDUX_ACTIONS.ASSET_DATA_PAUSE,
              });
            }}
          >
            <FastForward
              className={
                typeof isBackward !== 'undefined' && !isBackward
                  ? classes.pressed
                  : ''
              }
            />
          </IconButton>
        )}
      </Box>
      <Stack direction="row">
        <CustomSlider
          aria-label="Preset"
          step={null}
          valueLabelDisplay="off"
          marks={isPresetInHours ? HOURS_RANGE_PRESETS : MINUTES_RANGE_PRESETS}
          track={false}
          size="medium"
          value={assetData.dateRange.preset}
          onChangeCommitted={handleSliderChange}
          min={
            isPresetInHours
              ? Math.min(...HOURS_RANGE_PRESETS.map((o) => o.value))
              : Math.min(...MINUTES_RANGE_PRESETS.map((o) => o.value))
          }
          max={
            isPresetInHours
              ? Math.max(...HOURS_RANGE_PRESETS.map((o) => o.value))
              : Math.max(...MINUTES_RANGE_PRESETS.map((o) => o.value))
          }
          sx={{ width: '30vh', margin: '14px', marginTop: 0 }}
        />
        <IconButton sx={{ marginRight: '14px' }} size="large"
          aria-label="orders" onClick={handleTogglePresets}>
          <QueryStats />
        </IconButton>
      </Stack>
      {assetData.isDateRangeModalOpen ? <DateRangeModal /> : null}
    </Box>
      )
    : (
    <></>
      );
};

export default DateRangeSlider;
