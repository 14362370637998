import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import styles from './styles';

const SelectEditValueTypes = (props) => {
  const { id, value, field, data, updateData } = props;
  const apiRef = useGridApiContext();
  const classes = styles();

  const handleChange = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    updateData(
      id,
      field,
      event.target.value,
    );
    if (props?.closeCellAfterSelect) {
      apiRef.current.setCellMode(id, field, 'view');
    }
  };

  return (
    <FormControl
      variant="filled"
      className={classes.inputField}
      sx={{ bgcolor: 'transparent' }}
    >
      <InputLabel id={`signal-label-${id}`}>Select One</InputLabel>
      <Select
        labelId={`signal-select-${id}`}
        id={`signal-select-${id}`}
        value={value}
        onChange={handleChange}
        label={value}
        disableUnderline
      >
        {data?.map((item, idItem) => (
          <MenuItem key={`item_${item}_${idItem}`} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

SelectEditValueTypes.propTypes = {
  field: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  value: PropTypes.any,
  data: PropTypes.any,
  updateData: PropTypes.func,
  closeCellAfterSelect: PropTypes.any
};

export default SelectEditValueTypes;
