const columns = (renderSignalCell, renderOperatorCell, renderActionCell, renderValueCell) => [
  {
    field: 'Action',
    headerName: 'Action',
    renderCell: renderActionCell,
    width: 100,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'field',
    headerName: 'Signal',
    renderEditCell: renderSignalCell,
    editable: true,
    width: 160,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'operatorValue',
    headerName: 'Operator',
    width: 80,
    hide: false,
    editable: true,
    renderEditCell: renderOperatorCell,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'value',
    headerName: 'Value',
    width: 150,
    hide: false,
    editable: true,
    renderEditCell: renderValueCell,
    headerAlign: 'center',
    align: 'center',
  },
];

export default columns;
