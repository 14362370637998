import { makeStyles } from '@mui/styles';

const styles = makeStyles(({ breakpoints }) => {
  return {
    subscribeWrapper: {
      width: '100%',
      textAlign: 'center',
      backgroundColor: '#f0f0f0',
      color: '#303030',
      padding: '70px 0',
      [breakpoints.down(500)]: {
        padding: '30px 0',
        width: '90%',
        margin: '0 auto'
      }
    },
    subscribeTitle: {
      margin: '14px 0',
    },
    subscribeDesc: {
      margin: '40px 0',
      fontWeight: 'normal',
      [breakpoints.down(500)]: {
        margin: '20px 0',
        fontSize: '20px'
      }
    },
    searchBar: {
      background: '#FFF',
      fontSize: '12px',
      padding: '5px 0',
      borderRadius: '8px',
      border: '4px solid #CCC',
      width: '30%',
      margin: '40px auto',
      [breakpoints.down(500)]: {
        margin: '40px auto 0 auto',
        fontSize: '20px',
        width: '80%',
        padding: '2px 0',
      }
    },
    inputBase: {
      flex: 1,
      fontSize: '18px',
      fontWeight: 'bold',
      paddingLeft: '10px',
      outline: 'none',
      border: 'none'
    },
    button: {
      marginTop: '50px !important',
      maxWidth: '200px',
    },
    errorMessage: {
      color: '#FD3F3F',
      position: 'absolute',
      marginTop: '15px',
      fontSize: '14px',
      fontWeight: 'bold',
      letterSpacing: 1.5,
      [breakpoints.down(500)]: {
        fontSize: '12px',
        marginTop: '10px',
      }
    },
    checkboxText: {
      fontSize: '18px',
      [breakpoints.down(500)]: {
        fontSize: '14px',
        lineHeight: 1.5,
        textAlign: 'start'
      }
    }
  };
});

export default styles;
