import fetch from '../../api/fetch';
import { REDUX_ACTIONS } from '../../shared/config/constants';
import store from '../store';
import { enqueueSnackbar } from './snackbar';
import { parseError } from '../../utils/fetchErrorParser';

export const listOrders = (userData) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.ORDERS_LIST_LOADING,
  });

  const body = {
    collectionName: 'Orders',
    userId: userData.userId,
    companyId: userData.companyId,
  };

  return fetch
    .post('orders/orders', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }

      return result.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.ORDERS_LIST_SUCCESS,
        payload: {
          orders: data.data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)),
          paginatedElements: data.data.length,
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.ORDERS_LIST_ERROR,
        payload: { ...error, message: errorMessage },
      });
    });
};
/* prettier-ignore */
export const listOrderParts = (companyId, idList = []) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.ORDERS_GREEN_PARTS_DETAILS_LOADING,
  });

  const body = { companyId, idList };

  return fetch
    .post('parts/partsCompany', body)
    .then((resp) => {
      if (resp.status === 200) {
        return resp.text();
      }
      return Promise.reject(resp.text());
    })
    .then((data) => {
      const parsedData = JSON.parse(data);
      dispatch({
        type: REDUX_ACTIONS.ORDERS_SET_GREEN_PARTS_DETAILS_SUCCESS,
        payload: {
          parts: parsedData.data,
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.ORDERS_GREEN_PARTS_ERROR_NOTIFIED,
        payload: { ...error, message: 'Error' },
      });
    });
};

export const addToGreenList = (itemId) => (dispatch) => {
  const { orders } = store.getState();
  orders.greenList.push(itemId);
  dispatch({
    type: REDUX_ACTIONS.SET_SHOPPING_CART_PREFERENCES,
    payload: { orders: orders.greenList },
  });
  return dispatch({
    type: REDUX_ACTIONS.ORDERS_SET_GREEN_LIST,
    payload: orders.greenList,
  });
};

export const removeFromGreenList = (itemId) => (dispatch) => {
  const { orders } = store.getState();
  const indexToremove = orders.greenList.indexOf(itemId);

  if (indexToremove !== -1) {
    orders.greenList.splice(indexToremove, 1);
    dispatch({
      type: REDUX_ACTIONS.SET_SHOPPING_CART_PREFERENCES,
      payload: { orders: orders.greenList },
    });
    return dispatch({
      type: REDUX_ACTIONS.ORDERS_SET_GREEN_LIST,
      payload: orders.greenList,
    });
  } else {
    return dispatch({
      type: REDUX_ACTIONS.ORDERS_SET_GREEN_ITEM_NOT_FOUND,
      payload: true,
    });
  }
};

export const postOrderParts = (userData, partsIdArray) => (dispatch) => {
  const { orders } = store.getState();
  const vendorsArray = partsIdArray.map((part) => {
    return {
      part: part._id,
      vendorId: part.VendorId,
      price: part.Price,
    };
  });
  const postParts = partsIdArray.map((item) => item._id);
  let currentParts = orders.greenList;

  function removeItemOnce(arr, value) {
    const index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

  postParts.forEach((item) => {
    currentParts = removeItemOnce(currentParts, item);
  });

  dispatch({
    type: REDUX_ACTIONS.ORDER_PARTS_POSTING,
  });

  const body = {
    userId: userData.userId,
    collectionName: 'Orders',
    order: partsIdArray,
    vendors: vendorsArray,
  };

  return fetch
    .post('orders/order-create', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }

      return result.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.ORDER_PARTS_POST_SUCCESS,
        payload: data,
        remainingOrderItems: currentParts
      });

      dispatch({
        type: REDUX_ACTIONS.SET_SHOPPING_CART_PREFERENCES,
        payload: { orders: currentParts },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.ORDER_PARTS_POST_ERROR,
        payload: { ...error, message: errorMessage },
      });
    });
};

export const downloadOrderPDF = (orderNum) => (dispatch) => {
  const body = {
    orderNum,
  };

  dispatch({
    type: REDUX_ACTIONS.DOWNLOAD_ORDER_LOADING,
    payload: {
      status: 'loading',
    },
  });

  return fetch
    .downloadPost('marketplace/download', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.blob();
    })
    .then((blob) => {
      // Create a download link for the PDF file
      const url = window.URL.createObjectURL(blob);
      const fileName = `${orderNum}.pdf`;

      // Create an anchor element and set the download attribute
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;

      // Append the link to the document, click it, and remove it
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      dispatch({
        type: REDUX_ACTIONS.DOWNLOAD_ORDER_IDLE,
        payload: {
          status: 'idle',
        },
      });
      dispatch(
        enqueueSnackbar(
          'File Downloaded Successfully.',
          'success',
          new Date().getTime() + Math.random()
        )
      );
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.DOWNLOAD_ORDER_ERROR,
        payload: {
          status: 'error',
        },
      });
    });
};

export const postOrderStatus = (body, key, handleStatus) => () => {
  const headers = {
    Authorization: `Bearer ${key}`,
  };

  handleStatus({
    loading: true,
    error: false
  });

  return fetch
    .post('orders/status', body, headers)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }

      return result.json();
    })
    .then(() => {
      handleStatus({
        loading: false,
        error: false
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      handleStatus({
        loading: false,
        error: true,
        errorMessage
      });
    });
};

export const updateOrderList = (data, selectedOrder, setSelectedOrder, type) => (dispatch) => {
  const { currentUser } = store.getState();
  const orders = [...data];
  const elem = orders.find((item) => item?.orderNumber === selectedOrder?.orderNumber);
  if (elem) elem.orderStatus = selectedOrder.status;
  return fetch
    .post(`${type}/setStatus`, {
      input: {
        userId: currentUser?.userId,
        status: Number(selectedOrder.status),
        orderNumber: selectedOrder.orderNumber
      }
    })
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      setSelectedOrder(null);
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      setSelectedOrder(null);
    });
};

export const downloadProofOfDelivery = (orderNum) => (dispatch) => {
  const body = {
    input: {
      orderNumber: orderNum
    }
  };

  dispatch({
    type: REDUX_ACTIONS.DOWNLOAD_ORDER_LOADING,
    payload: {
      status: 'loading',
    },
  });

  return fetch
    .downloadPost('orders/pod/download', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.blob();
    })
    .then((blob) => {
      // Create a download link for the PDF file
      const url = window.URL.createObjectURL(blob);
      const fileName = `POD_${orderNum}.${blob.type.split('/')[1]}`;
      // Create an anchor element and set the download attribute
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;

      // Append the link to the document, click it, and remove it
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      dispatch({
        type: REDUX_ACTIONS.DOWNLOAD_ORDER_IDLE,
        payload: {
          status: 'idle',
        },
      });
      dispatch(
        enqueueSnackbar(
          'File Downloaded Successfully.',
          'success',
          new Date().getTime() + Math.random()
        )
      );
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.DOWNLOAD_ORDER_ERROR,
        payload: {
          status: 'error',
        },
      });
    });
};
