import { makeStyles } from '@mui/styles';

const styles = makeStyles(({ breakpoints }) => {
  return {
    marketplaceWrapper: {
      marginTop: '100px',
      [breakpoints.down(500)]: {
        marginTop: '80px'
      }
    },
  };
});

export default styles;
