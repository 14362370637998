import { REDUX_ACTIONS } from '../../shared/config/constants';

const initialState = {
  loading: false,
  deleting: false,
  data: [],
  errorMessage: '',
  loadingDelete: false,
  saved: false,
  paginatedElements: 0,
  currentAlarm: {
    assets: [],
  },
  initialData: [],
};

const alarmsReducer = (state = initialState, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.ALARMS_LIST_LOADING:
      return {
        ...state,
        loading: true,
        errorMessage: '',
      };
    case REDUX_ACTIONS.SET_CURRENT_ALARM:
      return {
        ...state,
        currentAlarm: {
          ...state.currentAlarm,
          _id: action.payload.alarmId,
        },
      };
    case REDUX_ACTIONS.ALARMS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...action.payload.data],
        initialData: [...action.payload.data],
        paginatedElements: action.payload.paginatedElements,
        errorMessage: '',
      };
    case REDUX_ACTIONS.ALARMS_LIST_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.message,
        data: [],
      };
    case REDUX_ACTIONS.ALARMS_LIST_ERROR_NOTIFIED:
      return {
        ...state,
        errorMessage: '',
        data: [],
      };
    case REDUX_ACTIONS.ALARMS_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default alarmsReducer;
