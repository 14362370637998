import React, { useEffect, useState } from 'react';
import styles from './styles';
import {
  Card,
  CardHeader,
  Grid,
  IconButton,
} from '@mui/material';
import { AddCircleOutline, DeleteRounded, PrintRounded } from '@mui/icons-material';
import Datagrid from '../ui/Datagrid';
import columns from './table_config';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { OPERATOR_SYMBOLS_PRESET } from '../../utils/constants';
import { createCustomActuatorReport } from '../../store/actions/actuatorReport';
import SelectEditValueTypes from '../ui/SelectEditValueTypes';
import SelectEditInputTextField from '../ui/SelectEditInputTextField';

function generateRandom() {
  const length = 24;
  const charset = 'abcdefghijklmnopqrstuvwxyz0123456789';
  let retVal = '';
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
}

const JobsReportCustomCard = ({ jobId, signals, reportStartDate, reportEndDate, title, collection }) => {
  const classes = styles();
  const dispatch = useDispatch();
  const apiRef = useGridApiRef();
  const [properties, setProperties] = useState([]);
  const [showPrint, setShowPrint] = useState(false);
  const [rowId, setRowId] = useState('');
  const actuatorReport = useSelector((state) => state.actuatorReport);

  useEffect(() => {
    if (properties[0]?.field === '') {
      const id = properties[0]?._id;
      setTimeout(() => {
        apiRef.current.scrollToIndexes({
          rowIndex: 1
        });
        apiRef.current.setCellMode(id, 'field', 'edit');
        setTimeout(() => {
          apiRef.current.setCellFocus(id, 'field');
        }, 50);
      }, 50);
    }
  }, [properties.length]);

  useEffect(() => {
    if (checkData(properties) && properties.length > 0 && checkDateValidation()) {
      setShowPrint(false);
    } else {
      setShowPrint(true);
    }
  }, [properties, reportEndDate, reportStartDate]);

  const checkDateValidation = () => {
    if (reportEndDate && reportStartDate) {
      const startDate = new Date(reportStartDate);
      const endDate = new Date(reportEndDate);
      const differenceInTime = endDate.getTime() - startDate.getTime();
      const differenceInDays = differenceInTime / (1000 * 3600 * 24 + (60 * 1000));
      if (differenceInDays > 7 || endDate > new Date()) {
        return false;
      } else {
        return true;
      }
    }
  };

  const handleAddProperty = () => {
    const currentProperties = [...properties];
    const nextId = currentProperties.length - 1;
    const newRow = {
      field: '',
      operatorValue: '',
      value: '',
      grid_id: nextId + 1,
      _id: generateRandom(),
    };
    currentProperties.unshift(newRow);
    setProperties(currentProperties);
  };

  const checkData = (currentProperties) => {
    return currentProperties.some((item) => { return item.field && item.value && item.operatorValue; });
  };

  const updateData = (id, field, value) => {
    setProperties(prevProperties => {
      const updatedProperties = prevProperties.map(item => {
        if (item._id === id) {
          return {
            ...item,
            [field]: value
          };
        }
        return item;
      });
      return updatedProperties;
    });
  };

  const handleDeleteData = (row) => {
    const currentProperties = [...properties];
    const filtereProperties = currentProperties.filter((item) => item._id !== row.id);
    setProperties(filtereProperties);
  };

  const renderSignalCell = (params) => {
    return <SelectEditValueTypes closeCellAfterSelect={true} {...params} data={signals} updateData={updateData} />;
  };

  const renderOperatorCell = (params) => {
    return <SelectEditValueTypes closeCellAfterSelect={true} {...params} data={['<', '<=', '>', '>=', '=', '!=']} updateData={updateData} />;
  };

  const renderValueCell = (params) => {
    return <SelectEditInputTextField {...params} updateData={updateData} />;
  };

  const renderActionCell = (props) => {
    return (
    <Grid item container>
            <IconButton
              onClick={() => {
                handleDeleteData(props);
              }}
            >
              <DeleteRounded fontSize='medium' />
            </IconButton>
            </Grid>
    );
  };

  const handlePrintPayload = () => {
    const modifiedSignal = [];
    for (const row of apiRef.current.getSelectedRows().values()) {
      modifiedSignal.push(row);
    }
    if (jobId !== '' && reportStartDate !== '' && reportEndDate !== '' && properties.length !== 0) {
      const customReportSignalData = properties.map((item) => { return item._id === rowId ? { ...modifiedSignal[0] } : item; })
        .filter((item) => item.field && item.operatorValue && item.value)
        .map((item) => {
          const operatorLabel = OPERATOR_SYMBOLS_PRESET.find((preset) => preset.operator === item.operatorValue)?.label;
          return {
            ...item,
            operatorValue: operatorLabel || item.operatorValue
          };
        });
      const postBody = {
        jobId,
        start: reportStartDate,
        end: reportEndDate,
        userQuery: customReportSignalData,
        collection
      };
      if (customReportSignalData.length) {
        dispatch(createCustomActuatorReport(postBody));
      }
    }
  };

  return (
      <Card
        sx={{ maxWidth: 700, flexGrow: 1, maxHeight: 500 }}
        className={classes.card}
        key={'filter_card_custom'}
      >
        <Grid item xs={12} className={classes.cardHeaderConatiner}>
          <CardHeader
            title={title}
            titleTypographyProps={{ variant: 'h6' }}
          />
          <IconButton
            disabled={!reportEndDate || !reportStartDate || showPrint || reportEndDate < reportStartDate || actuatorReport?.actuator_reportLoading}
            onClick={() => {
              setTimeout(() => {
                handlePrintPayload();
              }, 2000);
            }}>
            <PrintRounded fontSize='large' />
          </IconButton>
        </Grid>
        <Datagrid
            apiRef={apiRef}
            data={properties}
            getRowId={(row) => row._id}
            columns={columns(renderSignalCell, renderOperatorCell, renderActionCell, renderValueCell)}
            autoHeight={false}
            height={'325px'}
            hideFooter
            pageSize={10}
            toolbar={{
              options: {
                columns: false,
                filters: false,
                density: false,
                export: false,
              },
            }}
            onRowClick={(rowProps) => {
              setRowId(rowProps.id);
            }}
            onCellEditCommit={({ id, value, field }) => {
              updateData(id, field, value);
            }}
            editMode="cell"
          />
        <Grid item xs={1} container>
          <IconButton onClick={() => {
            handleAddProperty();
          }}>
            <AddCircleOutline/>
          </IconButton>
        </Grid>
      </Card>
  );
};

JobsReportCustomCard.propTypes = {
  title: PropTypes.string,
  jobId: PropTypes.string,
  signals: PropTypes.array,
  reportEndDate: PropTypes.any,
  reportStartDate: PropTypes.any,
  collection: PropTypes.string
};

export default JobsReportCustomCard;
