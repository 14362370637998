import fetch from '../../api/fetch';
import { REDUX_ACTIONS } from '../../shared/config/constants';
import { UNASSIGNED_ASSET_JOB_ID } from '../../utils/constants';
import { parseError } from '../../utils/fetchErrorParser';
import store from '../store';
import { enqueueSnackbar } from './snackbar';

export const listJobs = () => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.JOBS_LIST_LOADING,
  });

  const body = { collectionName: 'Jobs' };

  return fetch
    .post('jobs/jobs', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.JOBS_LIST_SUCCESS,
        payload: {
          data: data.data,
          paginatedElements: data.data.length,
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.JOBS_LIST_ERROR,
        payload: { ...error, message: 'Error' },
      });
    });
};

export const addNewJob = () => (dispatch) => {
  const { jobs } = store.getState();
  const newJobs = [...jobs.data];
  const nextId = newJobs.length - 1;

  function generateRandom() {
    const length = 24;
    const charset = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let retVal = '';
    for (let i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  const newRow = {
    Operator: '',
    Well: '',
    Location: '',
    Latitude: 0,
    Longitude: 0,
    Rig: '',
    Equipment: '',
    CompanyId: '',
    assets: [],
    systemStatus: null,
    data: '',
    lastUpdate: new Date(),
    grid_id: nextId + 1,
    _id: generateRandom(),
    isNewRow: true,
  };
  newJobs.unshift(newRow);

  dispatch({
    type: REDUX_ACTIONS.JOBS_LIST_SUCCESS,
    payload: {
      data: newJobs,
      paginatedElements: newJobs.length,
    },
  });
};

export const createNewJob = (body) => (dispatch) => {
  const input = {
    job: {
      Operator: body?.Operator,
      Well: body?.Well,
      wellsInfoId: body?.wellsInfoId,
      Latitude: body?.Latitude,
      Longitude: body?.Longitude,
      Rig: body?.Rig,
      Location: body?.Location || 'NA',
      Equipment: body?.Equipment || 'NA',
      CompanyId: body?.CompanyId || 'NA'
    },
  };
  return fetch
    .post('jobs/job-create', input)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(listJobs());
      dispatch(
        enqueueSnackbar(
          'Job Added Successfully.',
          'success',
          new Date().getTime() + Math.random()
        )
      );
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
    });
};

export const updateJobDetails = (body) => (dispatch) => {
  const input = {
    job: {
      Operator: body?.Operator,
      Well: body?.Well,
      wellsInfoId: body?.wellsInfoId,
      Latitude: body?.Latitude,
      Longitude: body?.Longitude,
      Rig: body?.Rig,
      Location: body?.Location || 'NA',
      Equipment: body?.Equipment || 'NA',
      CompanyId: body?.CompanyId || 'NA'
    },
    jobId: body?._id
  };
  return fetch
    .put('jobs', input)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(listJobs());
      dispatch(
        enqueueSnackbar(
          'Job Updated Successfully.',
          'success',
          new Date().getTime() + Math.random()
        )
      );
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
    });
};

export const getJobById = (jobId) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.JOBS_LIST_LOADING,
  });

  const body = {
    collectionName: 'Jobs',
    jobId,
  };

  return fetch
    .post('jobs/jobs', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.JOBS_LIST_SINGLE_ITEM_SUCCESS,
        payload: {
          data: data.data,
          paginatedElements: data.data.length,
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.JOBS_LIST_ERROR,
        payload: { ...error, message: 'Error' },
      });
    });
};

export const getJobAssets = (jobId) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.JOB_ASSETS_LIST_LOADING,
  });

  const body = { jobId };

  return fetch
    .post('assets/assets', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.JOB_ASSETS_LIST_SUCCESS,
        payload: {
          data: data.data,
          paginatedElements: data.data.length,
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );

      dispatch({
        type: REDUX_ACTIONS.JOB_ASSETS_LIST_ERROR,
        payload: { ...error, message: 'Error' },
      });
    });
};

export const assignJobAssets = (jobId, assets) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.JOB_ASSETS_SAVING,
  });

  const assetsIds = assets.map((asset) => asset._id);

  const body = { assets: {} };
  body.assets[jobId] = assetsIds;

  return fetch
    .put('assets/assets', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      if (result.status === 204) {
        return Promise.resolve();
      }
      return result.json();
    })
    .then(() => {
      if (jobId !== UNASSIGNED_ASSET_JOB_ID) {
        dispatch({
          type: REDUX_ACTIONS.JOB_ASSETS_SAVE_SUCCESS,
          payload: {
            data: assets,
          },
        });
        dispatch({
          type: REDUX_ACTIONS.ASSETS_SAVE_JOB_ASSET_SUCCESS,
          payload: {
            data: assetsIds,
          },
        });
      } else {
        dispatch({
          type: REDUX_ACTIONS.JOB_ASSETS_REMOVE_SUCCESS,
          payload: {
            data: assetsIds,
          },
        });
        dispatch({
          type: REDUX_ACTIONS.ASSETS_REMOVE_JOB_ASSET_SUCCESS,
          payload: {
            data: assets,
          },
        });
      }
      dispatch(listJobs());
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );

      dispatch({
        type: REDUX_ACTIONS.JOB_ASSETS_SAVE_ERROR,
        payload: { ...error, message: 'Error' },
      });
    });
};

export const listSignalsData = (jobId) => (dispatch) => {
  const body = {
    jobId,
    input: { responseType: 'split' },
  };

  return fetch
    .post('jobs/data', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.JOB_SIGNALS_DATA_LIST_SUCCESS,
        payload: {
          data: data.data,
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );

      dispatch({
        type: REDUX_ACTIONS.JOB_SIGNALS_DATA_LIST_ERROR,
        payload: { ...error, message: 'Error' },
      });
    });
};
