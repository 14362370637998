import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import ModalDialog from '../ModalDialog';
import Datagrid from '../Datagrid';
import { gridClasses } from '@mui/x-data-grid';
import { renderCell, renderCustomCell } from '../Datagrid/table_config.js';
import { Grid } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import columns from './table_config';
import { useDispatch, useSelector } from 'react-redux';
import { listBinsParts } from '../../../store/actions/epicor/epicorBins';
import { listEpicorTrucks } from '../../../store/actions/epicor/epicorTrucks';
import { listEpicorWorkForce } from '../../../store/actions/epicor/epicorWorkForce';
import { ticketNumGenerator } from '../../../utils/ticketNumGenerator';
import _ from 'lodash';

const BinPartsModalDialog = ({
  isOpen,
  bin,
  onCloseClick,
  onActionClick,
  ticketView,
  setTicketView,
  maxTicketAmount,
  setMaxTicketAmount,
  transferBoxValue,
  setTransferBoxValue,
  poValue,
  setPoValue,
  validationFields,
  salesRepValue,
  setSalesRepValue,
  salesManagerValue,
  setSalesManagerValue,
  setValidationFields,
  AFEValue,
  leaseWellValue,
  setAFEValue,
  setLeaseWellValue,
}) => {
  const dispatch = useDispatch();
  const { loading, parts } = useSelector((state) => state.epicorBins);
  const epicorTrucks = useSelector((state) => state.epicorTrucks);
  const epicorWorkForce = useSelector((state) => state.epicorWorkForce);
  const apiRef = useGridApiRef();
  const [data, setData] = useState(parts);
  const [trucks, setTrucks] = useState(epicorTrucks.data);
  const [workForce, setWorkForce] = useState(epicorWorkForce.data);
  const salesManagerRef = useRef();
  const salesRepRef = useRef();

  useEffect(() => {
    if (isOpen) {
      dispatch(listBinsParts(bin.WarehouseCode, bin.BinNum, 'Field Box'));
      dispatch(listEpicorTrucks());

      const params = {
        topValue: 500,
        filterValue: 'InActive eq false',
      };
      dispatch(listEpicorWorkForce(params));
    }
  }, [isOpen]);

  useEffect(() => {
    setData(parts);
  }, [parts]);

  useEffect(() => {
    setTrucks(epicorTrucks.data);
    setWorkForce(epicorWorkForce.data);
  }, [epicorTrucks.data, epicorWorkForce.data]);

  const checkAllFilledFields = () => {
    const result = validationFields.some((obj) => obj.filled === false);
    return result;
  };

  const getPositionOnTicketsList = (
    ticketsArray,
    totalPrice,
    maxTicketAmount
  ) => {
    const ticketIndex = ticketsArray.findIndex((ticket) => {
      const initialValue = 0;
      const reducedTotal = ticket.papers.reduce((partialResult, p) => {
        return partialResult + p.price * p.billOut;
      }, initialValue);

      return reducedTotal + totalPrice <= maxTicketAmount;
    });
    return ticketIndex;
  };

  const separateTicketsByBillOut = (
    counter,
    part,
    ticketsArray,
    maxTicketAmount
  ) => {
    const newTicket = { numTicket: counter, papers: [] };
    newTicket.papers.push({
      billOut: 1,
      partDescription: part.partDescription,
      partNumber: part.partNumber,
      price: part.price,
      totalPrice: part.price,
    });
    ticketsArray.push(newTicket);

    for (let bO = 2; bO <= part.billOut; bO++) {
      const ticketIndex = getPositionOnTicketsList(
        ticketsArray,
        part.price,
        maxTicketAmount
      );

      if (ticketIndex >= 0) {
        ticketsArray[ticketIndex].papers.push({
          billOut: 1,
          partDescription: part.partDescription,
          partNumber: part.partNumber,
          price: part.price,
          totalPrice: part.price,
        });
      } else {
        counter++;
        const newTicket = { numTicket: counter, papers: [] };
        newTicket.papers.push({
          billOut: 1,
          partDescription: part.partDescription,
          partNumber: part.partNumber,
          price: part.price,
          totalPrice: part.price,
        });
        ticketsArray.push(newTicket);
      }
    }
    return { tickets: ticketsArray, counter };
  };

  const getUnifiedPapersByTicket = (tickets) => {
    let unifiedPapers = [];
    for (let n = 0; n < tickets.length; n++) {
      const array = tickets[n].papers;

      const merged = _.uniqWith(array, (pre, cur) => {
        if (pre.partNumber === cur.partNumber) {
          cur.totalPrice += cur.price;
          return true;
        }
        return false;
      });

      const list = merged.map((list) => {
        const nList = { ...list };
        nList.billOut = 0;
        if (nList.totalPrice && nList.price) {
          nList.billOut = nList.totalPrice / nList.price;
        }
        return nList;
      });
      tickets[n].papers = list;
    }

    unifiedPapers = tickets;

    return unifiedPapers;
  };

  const handleTicketButtonClick = (e) => {
    onActionClick(e);
    const isAllFilled = checkAllFilledFields();
    const atLeastOne = data.find((element) => {
      return parseInt(element.newCount) >= 0;
    });

    if (atLeastOne) {
      if (poValue && salesManagerValue && salesRepValue && !isAllFilled) {
        const papersList = [];
        const transferList = [];
        for (let p = 0; p < data.length; p++) {
          const part = data[p];
          if (part.billOut) {
            const totalPrice = part.BasePrice * part.billOut;
            const paper = {
              partNumber: part.PartNum,
              partDescription: part.PartDescription,
              billOut: part.billOut,
              price: part.BasePrice,
              totalPrice,
              added: part.added,
            };
            papersList.push(paper);
          }

          if (part.added) {
            const transferData = {
              PartNum: part.PartNum,
              Qty: part.added,
            };
            transferList.push(transferData);
          }
        }

        let ticketsArray = [];
        if (maxTicketAmount && parseInt(maxTicketAmount)) {
          let counter = 1;
          for (const part of papersList) {
            if (ticketsArray.length === 0) {
              if (part.totalPrice > parseInt(maxTicketAmount)) {
                const separted = separateTicketsByBillOut(
                  counter,
                  part,
                  ticketsArray,
                  maxTicketAmount
                );
                ticketsArray = separted.tickets;
                counter = separted.counter;
              } else {
                const newTicket = { numTicket: counter, papers: [] };
                newTicket.papers.push(part);
                ticketsArray.push(newTicket);
              }
            } else {
              if (part.totalPrice > parseInt(maxTicketAmount)) {
                counter++;
                const separted = separateTicketsByBillOut(
                  counter,
                  part,
                  ticketsArray,
                  maxTicketAmount
                );
                ticketsArray = separted.tickets;
                counter = separted.counter;
              } else {
                const ticketIndex = getPositionOnTicketsList(
                  ticketsArray,
                  part.totalPrice,
                  maxTicketAmount
                );

                if (ticketIndex >= 0) {
                  ticketsArray[ticketIndex].papers.push(part);
                } else {
                  counter++;
                  const newTicket = { numTicket: counter, papers: [] };
                  newTicket.papers.push(part);
                  ticketsArray.push(newTicket);
                }
              }
            }
          }
        } else {
          ticketsArray = [{ numTicket: 1, papers: papersList }];
        }

        dispatch({
          type: 'SET_TICKETS_LIST',
          payload: {
            data: getUnifiedPapersByTicket(ticketsArray),
            dataTransfer: transferList,
            binNum: bin.BinNum,
            warehouseCode: bin.WarehouseCode,
            binDescription: bin.Description,
            maxTicketAmount,
            po: poValue,
            AFE: AFEValue,
            leaseWell: leaseWellValue,
            salesRep: salesRepValue,
            salesMrg: salesManagerValue,
            transferBox: transferBoxValue,
            ticketGen: ticketNumGenerator(new Date(), ''),
          },
        });
        setTicketView(true);
      } else {
        const newValidation = validationFields.map((element) => {
          if (element.filled) {
            element.message = '';
          } else {
            element.message = '*Required';
            if (element.name === 'SalesRep') {
              element.message = 'Must be different than Sales Rep';
              if (salesRepValue !== salesManagerValue) {
                element.message = '*Required';
              }
            }

            if (element.name === 'SalesManager') {
              element.message = 'Must be different than Sales Rep';
              if (salesManagerValue !== salesRepValue) {
                element.message = '*Required';
              }
            }
          }
          return element;
        });

        setValidationFields(newValidation);
      }
    } else {
      setTicketView(false);
    }
  };

  const onSelectionChange = (e) => {
    setTransferBoxValue(e.target.value);
  };

  const changeFieldStatus = (element) => {
    const newValidation = [...validationFields];
    const field = newValidation.find((obj) => obj.name === element.name);
    if (element.value) {
      field.filled = true;
      field.message = '';
      if (element.name === 'SalesRep') {
        if (element.value === salesManagerValue) {
          field.filled = false;
          field.message = 'Must be different than Sales Manager';
        }
        const field2 = newValidation.find((obj) => obj.name === 'SalesManager');
        field2.filled = true;
        field2.message = '';
        if (salesManagerRef.current.textContent === '') {
          field2.filled = false;
        }
      }
      if (element.name === 'SalesManager') {
        if (element.value === salesRepValue) {
          field.filled = false;
          field.message = 'Must be different than Sales Rep';
        }
        const field2 = newValidation.find((obj) => obj.name === 'SalesRep');
        field2.filled = true;
        field2.message = '';
        if (salesRepRef.current.textContent === '') {
          field2.filled = false;
        }
      }
    } else {
      field.filled = false;
      field.message = '*Required';
    }
    setValidationFields(newValidation);
  };

  const onSalesRepChange = (e) => {
    setSalesRepValue(e.target.value);
    changeFieldStatus(e.target);
  };

  const onSalesManagerChange = (e) => {
    setSalesManagerValue(e.target.value);
    changeFieldStatus(e.target);
  };

  const onMaxTicketAmountChange = (e) => {
    setMaxTicketAmount(e.target.value);
  };

  const onPoValueChange = (e) => {
    changeFieldStatus(e.target);
    setPoValue(e.target.value);
  };

  const onAFEValueChange = (e) => {
    // changeFieldStatus(e.target);
    setAFEValue(e.target.value);
  };

  const onLeaseWellalueChange = (e) => {
    // changeFieldStatus(e.target);
    setLeaseWellValue(e.target.value);
  };

  const onCloseModal = () => {
    onCloseClick();
    setTransferBoxValue('');
    setPoValue('');
    setSalesRepValue('');
    setSalesManagerValue('');
    setValidationFields([
      { name: 'PO', filled: false, message: '' },
      { name: 'SalesRep', filled: false, message: '' },
      { name: 'SalesManager', filled: false, message: '' },
    ]);
  };

  const handleCellEditCommit = (params) => {
    if (params.field === 'newCount' || params.field === 'added') {
      const rowIndex = data.findIndex((row) => row._id === params.id);
      if (rowIndex !== -1) {
        const updatedRow = { ...data[rowIndex] };
        updatedRow[params.field] = parseInt(params.value, 10);
        if (params.field === 'newCount') {
          const newCount = updatedRow.newCount;
          if (isNaN(newCount)) {
            updatedRow.newCount = '';
            if (updatedRow.total && updatedRow.added) {
              updatedRow.total = 0 + updatedRow.added;
            } else {
              updatedRow.total = '';
            }
            updatedRow.billOut = '';
          } else {
            if (
              updatedRow.newCount < updatedRow.QtyOnHand &&
              updatedRow.newCount >= 0
            ) {
              updatedRow.newCount = newCount;
              updatedRow.billOut = updatedRow.QtyOnHand - updatedRow.newCount;
              const added = updatedRow.added ? updatedRow.added : 0;
              updatedRow.total = updatedRow.newCount + added;
            } else {
              updatedRow.newCount = '';
              updatedRow.billOut = '';
              updatedRow.total = '';
            }
          }
        } else if (params.field === 'added') {
          if (isNaN(updatedRow.added) || updatedRow.added <= 0) {
            updatedRow.added = '';
          }

          if (!updatedRow.billOut && updatedRow.added <= 0) {
            updatedRow.total = '';
          }

          if (!updatedRow.billOut) {
            updatedRow.total = updatedRow.added + updatedRow.QtyOnHand;
          } else {
            const newCount = updatedRow.newCount ? updatedRow.newCount : 0;
            updatedRow.total = newCount + updatedRow.added;
          }
        }
        setData((prevData) => {
          const newData = [...prevData];
          newData[rowIndex] = updatedRow;
          return newData;
        });
      }
    }
  };

  const createDialogButtons = () => [
    {
      text: 'Cancel',
      style: { marginLeft: 10 },
      placeholder: 'Cancel',
      'aria-label': 'Cancel',
      onClick: onCloseModal,
    },
    {
      text: 'Ticket',
      placeholder: 'Ticket',
      'aria-label': 'Ticket',
      onClick: handleTicketButtonClick,
    },
  ];

  const createExtraFields = () => [
    {
      type: 'text',
      label: 'Max Ticket $',
      value: maxTicketAmount,
      handleChange: onMaxTicketAmountChange,
    },
    {
      type: 'text',
      label: 'PO',
      value: poValue,
      inputName: 'PO',
      handleChange: onPoValueChange,
    },
    {
      type: 'text',
      label: 'AFE',
      value: AFEValue,
      inputName: 'AFE',
      handleChange: onAFEValueChange,
    },
    {
      type: 'text',
      label: 'Lease/Well',
      value: leaseWellValue,
      inputName: 'leaseWell',
      handleChange: onLeaseWellalueChange,
    },
    {
      type: 'select',
      label: 'Transfer Box',
      value: transferBoxValue,
      data: trucks,
      fieldName: 'Description',
      keyValue: 'BinNum',
      inputName: 'BinNum',
      handleChange: onSelectionChange,
    },
    {
      type: 'select',
      label: 'Sales Rep',
      value: salesRepValue,
      data: workForce,
      fieldName: 'Name',
      keyValue: 'SalesRepCode',
      inputName: 'SalesRep',
      ref: salesRepRef,
      handleChange: onSalesRepChange,
    },
    {
      type: 'select',
      label: 'Sales Manager',
      value: salesManagerValue,
      data: workForce,
      fieldName: 'Name',
      keyValue: 'SalesRepCode',
      inputName: 'SalesManager',
      ref: salesManagerRef,
      handleChange: onSalesManagerChange,
    },
  ];

  return (
    !loading && (
      <ModalDialog
        title={`${bin.BinNum} ${bin.Description}`}
        isOpen={isOpen}
        onClose={onCloseClick}
        buttons={createDialogButtons()}
        extraFields={createExtraFields()}
        ticketView={ticketView}
        validationFields={validationFields}
      >
        <Grid item xs={12}>
          <Datagrid
            data={data}
            autoHeight={false}
            columns={columns(
              renderCell,
              renderCustomCell,
              transferBoxValue
            )}
            apiRef={apiRef}
            getRowId={(row) => row._id}
            editMode="cell"
            onCellEditCommit={handleCellEditCommit}
            disableStickyHeader={false}
            pageSize={100}
            height={'70vh'}
            getRowHeight={() => 'auto'}
            sx={{
              '.MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold !important',
              },
              [`& .${gridClasses.cell}`]: {
                py: 1,
              },
            }}
            toolbar={{
              options: {
                columns: true,
                filters: true,
                density: true,
                export: true,
              },
            }}
          />
        </Grid>
      </ModalDialog>
    )
  );
};

BinPartsModalDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  bin: PropTypes.object.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  onActionClick: PropTypes.func.isRequired,
  saveButtonText: PropTypes.string,
  setTicketView: PropTypes.func,
  ticketView: PropTypes.bool,
  maxTicketAmount: PropTypes.string,
  setMaxTicketAmount: PropTypes.func,
  transferBoxValue: PropTypes.string,
  setTransferBoxValue: PropTypes.func,
  poValue: PropTypes.string,
  setPoValue: PropTypes.func,
  salesRepValue: PropTypes.string,
  setSalesRepValue: PropTypes.func,
  salesManagerValue: PropTypes.string,
  setSalesManagerValue: PropTypes.func,
  AFEValue: PropTypes.string,
  setAFEValue: PropTypes.func,
  leaseWellValue: PropTypes.string,
  setLeaseWellValue: PropTypes.func,
  validationFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      filled: PropTypes.bool,
      message: PropTypes.string,
    })
  ),
  setValidationFields: PropTypes.func,
};

export default BinPartsModalDialog;
