export const recentDate = (date1, date2) => {
  // Check if any of the dates is undefined or empty
  if (!date1) return date2 || '';
  if (!date2) return date1;
  // Convert date strings to Date objects for comparison
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  // Compare the dates and return the datestrings
  return d1 > d2 ? date1 : date2;
};
