import React from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import { useAuth } from '../../../../hooks/useAuth';
import {
  AspectRatioRounded,
  AddBusinessRounded
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import useAspectRatio from '../../../../hooks/useAspectRatio';
import { addNewCompanyRow } from '../../../../store/actions/companies';
import { USER_TYPE } from '../../../../utils/constants';
import styles from './styles';

const CompanyControls = () => {
  const dispatch = useDispatch();
  const { preferences } = useSelector((state) => state.currentUser);
  const { user } = useAuth();
  const companies = useSelector((state) => state.companies);
  const handleAspectRatio = useAspectRatio();
  const classes = styles();

  const handleAddToCompany = () => {
    dispatch(addNewCompanyRow());
  };

  return (
    <Box sx={{ marginLeft: 'auto' }}>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Box className={classes.container}>
            <Box>
              {user.type === USER_TYPE.PREMIUM_ADMIN && <IconButton
                disabled={companies.loading}
                size="large"
                aria-label="orders"
                onClick={() => {
                  handleAddToCompany();
                }}
              >
                <AddBusinessRounded />
              </IconButton>}
            </Box>
            <Box>
              <IconButton
                edge="end"
                onClick={() => {
                  handleAspectRatio(!preferences.aspectChanged);
                }}
              >
                <AspectRatioRounded />
              </IconButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CompanyControls;
