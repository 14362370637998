export const ticketNumGenerator = (date, separator) => {
  let day = date.getDate();
  let month = date.getMonth() + 1;
  const year = date.getFullYear();

  if (day < 10) {
    day = '0' + day;
  }
  if (month < 10) {
    month = '0' + month;
  }
  const time = date.toTimeString().split(':').join('');
  const timeData = time.split(' ');

  return month + separator + day + separator + year + timeData[0];
};
