import fetch from '../../api/fetch';
import { REDUX_ACTIONS } from '../../shared/config/constants';
import store from '../store';
import { parseError } from '../../utils/fetchErrorParser';
import { enqueueSnackbar } from './snackbar';

export const listQuotes = (userData) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.QUOTES_LIST_LOADING,
  });

  const body = {
    collectionName: 'Quotes',
    userId: userData.userId,
    companyId: userData.companyId,
  };

  return fetch
    .post('quotes/quotes', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }

      return result.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.QUOTES_LIST_SUCCESS,
        payload: {
          quotes: data.data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)),
          paginatedElements: data.data.length,
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );

      dispatch({
        type: REDUX_ACTIONS.QUOTES_LIST_ERROR,
        payload: { ...error, message: 'Error' },
      });
    });
};
/* prettier-ignore */
export const listQuoteParts = (companyId, idList = []) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.QUOTES_GRAY_PARTS_DETAILS_LOADING,
  });

  const body = { companyId, idList };

  return fetch
    .post('parts/partsCompany', body)
    .then((resp) => {
      if (!resp.ok) {
        return Promise.reject(resp);
      }
      return resp.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.QUOTES_SET_GRAY_PARTS_DETAILS_SUCCESS,
        payload: {
          parts: data.data,
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(enqueueSnackbar(errorMessage, 'error', new Date().getTime() + Math.random()));
      dispatch({
        type: REDUX_ACTIONS.QUOTES_GRAY_PARTS_ERROR_NOTIFIED,
        payload: { ...error, message: 'Error' },
      });
    });
};

export const addToGrayList = (itemId) => (dispatch) => {
  const { quotes } = store.getState();
  quotes.grayList.push(itemId);
  dispatch({
    type: REDUX_ACTIONS.SET_SHOPPING_CART_PREFERENCES,
    payload: { quotes: quotes.grayList },
  });

  return dispatch({
    type: REDUX_ACTIONS.ORDERS_SET_GRAY_LIST,
    payload: quotes.grayList,
  });
};

export const removeFromGrayList = (itemId) => (dispatch) => {
  const { quotes } = store.getState();
  const indexToremove = quotes.grayList.indexOf(itemId);

  if (indexToremove !== -1) {
    quotes.grayList.splice(indexToremove, 1);
    dispatch({
      type: REDUX_ACTIONS.SET_SHOPPING_CART_PREFERENCES,
      payload: { quotes: quotes.grayList },
    });
    return dispatch({
      type: REDUX_ACTIONS.ORDERS_SET_GRAY_LIST,
      payload: quotes.grayList,
    });
  } else {
    return dispatch({
      type: REDUX_ACTIONS.ORDERS_SET_GRAY_ITEM_NOT_FOUND,
      payload: true,
    });
  }
};

export const postQuoteParts = (userData, partsIdArray) => (dispatch) => {
  const vendorsArray = partsIdArray.map(part => {
    return {
      part: part._id,
      vendorId: part.VendorId,
      price: part.Price
    };
  });

  dispatch({
    type: REDUX_ACTIONS.QUOTE_PARTS_POSTING,
  });

  const body = {
    userId: userData.userId,
    collectionName: 'Quotes',
    order: partsIdArray,
    vendors: vendorsArray
  };

  return fetch
    .post('orders/order-create', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }

      return result.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.QUOTE_PARTS_POST_SUCCESS,
        payload: data
      });
      dispatch({
        type: REDUX_ACTIONS.SET_SHOPPING_CART_PREFERENCES,
        payload: { quotes: [] },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.QUOTE_PARTS_POST_ERROR,
        payload: { ...error, message: errorMessage },
      });
    });
};

export const downloadQuotePDF = (orderNum) => (dispatch) => {
  const body = {
    orderNum,
  };

  dispatch({
    type: REDUX_ACTIONS.DOWNLOAD_QUOTE_LOADING,
    payload: {
      status: 'loading',
    },
  });
  return fetch
    .downloadPost('marketplace/download', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.blob();
    })
    .then((blob) => {
      // Create a download link for the PDF file
      const url = window.URL.createObjectURL(blob);
      const fileName = `${orderNum}.pdf`;
      // Create an anchor element and set the download attribute
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      // Append the link to the document, click it, and remove it
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      dispatch({
        type: REDUX_ACTIONS.DOWNLOAD_QUOTE_IDLE,
        payload: {
          status: 'idle',
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.DOWNLOAD_QUOTE_ERROR,
        payload: {
          status: 'idle',
        },
      });
    });
};
