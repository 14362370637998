import { REDUX_ACTIONS } from '../../shared/config/constants';

const initialState = {
  loading: false,
  errorMessage: '',
  data: [],
  marker_data: [],
  paginatedElements: 0,
  searchTerm: '',
  initialData: [],
  syncResponseMessage: '',
};

const rigsReducer = (state = initialState, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.RIGS_LIST_LOADING:
      return {
        ...state,
        loading: true,
        errorMessage: '',
      };

    case REDUX_ACTIONS.RIGS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...action.payload.data],
        marker_data: [...action.payload.data],
        initialData: [...action.payload.data],
        paginatedElements: action.payload.paginatedElements,
        errorMessage: '',
      };

    case REDUX_ACTIONS.RIGS_LIST_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.message,
        data: [],
        marker_data: []
      };

    case REDUX_ACTIONS.RIGS_SEARCH_CHANGE:
      return {
        ...state,
        searchTerm: action.payload.value,
      };

    case REDUX_ACTIONS.RIGS_FILTER:
      return {
        ...state,
        data: [
          ...state.initialData.filter((item) =>
            item?.RigName_Number?.toUpperCase().includes(state.searchTerm.toUpperCase())
          ),
        ],
        marker_data: [
          ...state.initialData.filter((item) =>
            item?.RigName_Number?.toUpperCase().includes(state.searchTerm.toUpperCase())
          ),
        ],
      };

    case REDUX_ACTIONS.RIGS_MARKER_FILTER:
      return {
        ...state,
        marker_data: [
          ...action.payload.data
        ],
      };

    case REDUX_ACTIONS.RIGS_RESET:
      return {
        ...initialState,
      };

    case REDUX_ACTIONS.RIGS_SYNC_LOADING:
      return {
        ...state,
        loadingRigSync: true,
        syncResponseMessage: action.payload.message,
      };

    case REDUX_ACTIONS.RIGS_SYNC_IDLE:
      return {
        ...state,
        loadingRigSync: false,
        syncResponseMessage: action.payload.message,
      };

    case REDUX_ACTIONS.RIGS_SYNC_ERROR:
      return {
        ...state,
        loadingRigSync: false,
        syncResponseMessage: action.payload.message,
      };

    default:
      return state;
  }
};

export default rigsReducer;
