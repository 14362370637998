import fetch from '../../api/fetch';
import store from '../store';
import { REDUX_ACTIONS } from '../../shared/config/constants';
import { parseError } from '../../utils/fetchErrorParser';
import { enqueueSnackbar } from './snackbar';

export const listAlarms = (jobId) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.ALARMS_LIST_LOADING,
  });

  const body = { jobId };

  return fetch
    .post('alarms/list', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.ALARMS_LIST_SUCCESS,
        payload: {
          data: data.data,
          paginatedElements: data.data.length,
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.ALARMS_LIST_ERROR,
        payload: { ...error, message: 'Error' },
      });
    });
};

export const listSignals = (jobId) => (dispatch) => {
  const body = { jobId };

  return fetch
    .post('jobs/data', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.JOB_SIGNALS_LIST_SUCCESS,
        payload: {
          data: data.data,
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );

      dispatch({
        type: REDUX_ACTIONS.JOB_SIGNALS_LIST_ERROR,
        payload: { ...error, message: 'Error' },
      });
    });
};

export const addNewAlarmRow = () => (dispatch) => {
  const { alarms, jobs, currentUser } = store.getState();
  const newAlarms = [...alarms.data];
  const nextId = newAlarms.length - 1;

  function generateRandom() {
    const length = 24;
    const charset = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let retVal = '';
    for (let i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  const newRow = {
    signal: '',
    low: '',
    lowLow: '',
    high: '',
    highHigh: '',
    state: '',
    recipients: '',
    message: '',
    userId: currentUser.userId,
    jobId: jobs.currentJob._id,
    grid_id: nextId + 1,
    _id: generateRandom(),
    isNewRow: true,
  };
  newAlarms.push(newRow);

  dispatch({
    type: REDUX_ACTIONS.ALARMS_LIST_SUCCESS,
    payload: {
      data: newAlarms,
      paginatedElements: newAlarms.length,
    },
  });
};

export const saveAlarms = () => (dispatch) => {
  const { alarms, jobs } = store.getState();

  const listOfAlarms = alarms.data.filter(function(item) {
    return item.signal;
  }).map((item) => {
    let row = {};
    if (item.isNewRow) {
      // eslint-disable-next-line
      const { _id, isNewRow, grid_id, ...obj } = item;
      row = obj;
    } else {
      // eslint-disable-next-line
      const { grid_id, ...obj } = item;
      row = obj;
    }

    return item.signal && row;
  });

  const body = { alarms: listOfAlarms };

  return fetch
    .post('alarms', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(listAlarms(jobs.currentJob._id));
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
    });
};

export const deleteAlarmRow = () => (dispatch) => {
  const { alarms, jobs } = store.getState();

  const body = { alarmId: alarms.currentAlarm._id };

  return fetch
    .deleteAction('alarms/alarm', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(listAlarms(jobs.currentJob._id));
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
    });
};
