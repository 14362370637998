import { makeStyles } from '@mui/styles';

const styles = makeStyles(() => ({
  searchBar: {
    backgroundColor: 'transparent',
    width: '15vh',
    height: '40px',
    padding: '25px 0',
    '&.MuiFilledInput-root:hover': {
      backgroundColor: 'transparent',
    },
    '&.MuiInputBase-root :hover': {
      backgroundColor: 'transparent',
    },
    fontSize: 'larger',
  },
}));

export default styles;
