import * as React from 'react';
import PropTypes from 'prop-types';
import { useState, Fragment, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { MenuItem } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { PAGES_DATA, USER_TYPE } from '../../../../utils/constants';
import styles from './styles';
import { useSelector } from 'react-redux';
import { NavSubMenuItems } from '../NavSubMenu';

const renderMenuLabel = (label, userType) =>
  label === 'Shop'
    ? userType === USER_TYPE.CUSTOMER_MANAGER ||
        userType === USER_TYPE.CUSTOMER_ADMIN ||
        userType === USER_TYPE.CUSTOMER_USER ||
        userType === USER_TYPE.CUSTOMER_RIG
      ? label
      : 'Inventory'
    : label === 'Companies'
      ? userType === USER_TYPE.SELLER_MARKETPLACE_ADMIN
        ? 'Customers'
        : userType === USER_TYPE.CUSTOMER_USER ||
          userType === USER_TYPE.CUSTOMER_RIG ||
          userType === USER_TYPE.CUSTOMER_MANAGER ||
          userType === USER_TYPE.CUSTOMER_ADMIN
          ? 'Sellers'
          : label
      : label === 'Users'
        ? userType !== USER_TYPE.SELLER_MARKETPLACE_ADMIN
          ? label
          : 'Transaction History'
        : label;

const NavMenu = ({
  user,
  handleCloseNav,
  setSubMenuOpen,
  openSubMenu,
  selectedIndex,
  setSelectedIndex,
}) => {
  const classes = styles();
  const [pages, setPages] = useState(PAGES_DATA);
  const { categories } = useSelector((state) => state.parts);
  const [searchParams] = useSearchParams();
  const category = searchParams.get('cat');

  useEffect(() => {
    addSubmenuItems();
  }, [categories.data]);

  const addSubmenuItems = () => {
    if (categories.data.length > 0) {
      const partsItemIndex = pages.findIndex((page) => page.label === 'Shop');
      const partsPageMeta = pages.find((page) => page.label === 'Shop');
      const companyId = searchParams.get('companyId');
      const vendorId = searchParams.get('vendorId');
      const name = searchParams.get('name');
      const customerRigUserId = searchParams.get('customerRigUser');
      // keep query params for companies and sellers
      let queryParams = '';
      if (companyId) {
        queryParams = queryParams + `&companyId=${encodeURIComponent(companyId)}`;
      }
      if (vendorId) {
        queryParams = queryParams + `&vendorId=${encodeURIComponent(vendorId)}`;
      }
      if (name) {
        queryParams = queryParams + `&name=${encodeURIComponent(name)}`;
      }
      if (customerRigUserId) {
        queryParams = queryParams + `&customerRigUser=${encodeURIComponent(customerRigUserId)}`;
      }

      if (partsPageMeta) {
        partsPageMeta.submenu = [];
        partsPageMeta.submenu.push({
          label: 'ALL',
          link: '/Shop?' + queryParams,
        });
        categories.data.forEach((categoryData) => {
          const link = 'Shop?cat=' + categoryData + queryParams;
          partsPageMeta.submenu.push({
            label: categoryData,
            link,
          });
        });
        setPages((state) => [
          ...state.toSpliced(partsItemIndex, 1, partsPageMeta),
        ]);
      }
    }
  };

  const handleSubmenuToggle = (index, e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (selectedIndex === index) {
      setSelectedIndex(null);
    } else {
      setSelectedIndex(index);
    }
  };

  const handleClickSubMenu = () => {
    if (searchParams.size === 0) {
      setSubMenuOpen(!openSubMenu);
      handleCloseNav();
      return;
    }
    window.location.reload(false);
  };

  return (
    <>
      {pages.map((page, index) =>
        ((page.roles?.includes('*') && page.label) ||
          page.roles?.includes(user.type)) && page.label && page.label !== 'Field Tickets' && page.label !== 'TruFlowSettings'
          ? (
            <Fragment
              key={`fragment_main_menu_${page.label}`}
            >
              <MenuItem
                key={`main_menu_${page.label}`}
                selected={page.selected}
                onClick={() => page.submenu ? handleSubmenuToggle(index) : handleClickSubMenu()}
                className={classes.menuItem}
              >
                { page.submenu
                  ? <span className={classes.navMenuItem}>
                    {renderMenuLabel(page.label, user.type)}
                  </span>
                  : <Link
                      key={`main_menu_link_${page.label}`}
                      to={page.link}
                      className={classes.navMenuItem}
                    >
                    {renderMenuLabel(page.label, user.type)}
                  </Link>
                }
                { page.submenu
                  ? (index === selectedIndex
                      ? <ExpandLess/>
                      : <ExpandMore/>
                    )
                  : null
                }
              </MenuItem>
              { page.submenu
                ? <NavSubMenuItems
                  parentLabel={page.label}
                  subMenu={page.submenu}
                  index={index}
                  selectedIndex={selectedIndex}
                  handleCloseNav={handleCloseNav}
                  category={category}
                />
                : null
              }
            </Fragment>)
          : null
      )}
    </>
  );
};

NavMenu.propTypes = {
  user: PropTypes.object.isRequired,
  handleCloseNav: PropTypes.func.isRequired,
  setSubMenuOpen: PropTypes.func.isRequired,
  openSubMenu: PropTypes.bool.isRequired,
  selectedIndex: PropTypes.number,
  setSelectedIndex: PropTypes.func,
};

export default NavMenu;
