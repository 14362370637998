// eslint-disable-next-line
export const MUI_LICENSEKEY =
  '48351065a4e1800cfd4aa1cf571c5d3aTz04NzAxOSxFPTE3NDI3NDg0OTEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=';

export const GOOGLE_MAPS_KEY = 'AIzaSyAXvH-bI5wQVpOPTFhG1znf3-wEl3VypwE';

export const ORS_API_KEY = '5b3ce3597851110001cf62487737cd96968a4e6698e17e145d20776f';

export const GOOGLE_RECAPTCHA_SITE_KEY = '6LckICcqAAAAAL5FovBNoqu0KYJ5rGSO652uaDSg';

export const DEFAULT_IMG = 'https://premiummarketplaceimages.s3.us-east-2.amazonaws.com/placeholder.png';

export const ACCESS_TYPES = {
  NORMAL: 'normal',
  NEW_USER: 'newUser',
  FORGOTTEN_PASSWORD: 'forgottenPassword',
};

export const ASSET_DATA_TYPES = {
  ACTUATORDATA: 'ActuatorData',
  DRILLINGDATA: 'DrillingData',
};

export const ORDER_STATUS_TYPES = {
  1: 'Approving',
  2: 'Declining',
  3: 'Cancelling'
};

export const MAP_TYPES = {
  GOOGLEMAP: 'googlemap',
  LEAFLETMAP: 'leafletmap'
};

export const REDUX_ACTIONS = {
  ACCESS_TYPE: ACCESS_TYPES.NORMAL,
  SET_USER: 'SET_USER',
  SET_IP_DETAILS: 'SET_IP_DETAILS',
  LOGIN_USER_LOADING: 'LOGIN_USER_LOADING',
  LOGIN_USER_SUCCESS: 'LOGIN_USER_SUCCESS',
  LOGIN_USER_FAILED: 'LOGIN_USER_FAILED',
  REMOVE_USER: 'REMOVE_USER',
  SET_DATA_CHART_PREFERENCES: 'SET_DATA_CHART_PREFERENCES',
  DATA_CHART_PREFERENCES_RESET: 'DATA_CHART_PREFERENCES_RESET',
  SET_ASPECT_RATIO_PAGE: 'SET_ASPECT_RATIO_PAGE',
  SET_SPEED_CHART_PAGE: 'SET_SPEED_CHART_PAGE',
  SET_MARKETPLACE_INVENTORY_VIEW: 'SET_MARKETPLACE_INVENTORY_VIEW',
  GET_UPDATE_USER_PREFERENCES: 'GET_UPDATE_USER_PREFERENCES',
  UPDATE_COLUMNS_USER_PREFERENCES: 'UPDATE_COLUMNS_USER_PREFERENCES',
  SET_CHART_VIEW_MODE: 'SET_CHART_VIEW_MODE',
  SET_TOGGLE_MAPVIEW: 'SET_TOGGLE_MAPVIEW',

  NEW_USER_LOADING: 'NEW_USER_LOADING',
  NEW_USER_PASSWORD_SUCCESS: 'NEW_USER_PASSWORD_SUCCESS',
  NEW_USER_PASSWORD_FAILED: 'NEW_USER_PASSWORD_FAILED',
  NEW_USER_PASSWORD_LOADED: 'NEW_USER_PASSWORD_LOADED',
  NEW_USER_PASSWORD_ERROR_NOTIFIED: 'NEW_USER_PASSWORD_ERROR_NOTIFIED',

  ALARMS_LIST_LOADING: 'ALARMS_LIST_LOADING',
  ALARMS_LIST_ERROR: 'ALARMS_LIST_ERROR',
  SET_CURRENT_ALARM: 'SET_CURRENT_ALARM',
  ALARMS_LIST_SUCCESS: 'ALARMS_LIST_SUCCESS',
  ALARMS_LIST_ERROR_NOTIFIED: 'ALARMS_LIST_ERROR_NOTIFIED',
  ALARMS_RESET: 'ALARMS_RESET',

  JOBS_LIST_LOADING: 'JOBS_LIST_LOADING',
  JOBS_LIST_ERROR: 'JOBS_LIST_ERROR',
  JOBS_LIST_SUCCESS: 'JOBS_LIST_SUCCESS',
  JOBS_LIST_SINGLE_ITEM_SUCCESS: 'JOBS_LIST_SINGLE_ITEM_SUCCESS',
  JOBS_LIST_ERROR_NOTIFIED: 'JOBS_LIST_ERROR_NOTIFIED',
  JOBS_RESET: 'JOBS_RESET',

  JOB_ASSETS_LIST_LOADING: 'JOB_ASSETS_LIST_LOADING',
  JOB_ASSETS_LIST_SUCCESS: 'JOB_ASSETS_LIST_SUCCESS',
  JOB_ASSETS_LIST_ERROR: 'JOB_ASSETS_LIST_ERROR',
  JOB_ASSETS_SAVING: 'JOB_ASSETS_SAVING',
  JOB_ASSETS_SAVE_SUCCESS: 'JOB_ASSETS_SAVE_SUCCESS',
  SET_CURRENT_JOB: 'SET_CURRENT_JOB',
  JOB_SIGNALS_LOADING: 'JOB_SIGNALS_LOADING',
  JOB_SIGNALS_LIST_SUCCESS: 'JOB_SIGNALS_LIST_SUCCESS',
  JOB_SIGNALS_LIST_ERROR: 'JOB_SIGNALS_LIST_ERROR',
  JOB_SIGNALS_DATA_LOADING: 'JOB_SIGNALS_DATA_LOADING',
  JOB_SIGNALS_DATA_LIST_SUCCESS: 'JOB_SIGNALS_DATA_LIST_SUCCESS',
  JOB_SIGNALS_DATA_LIST_ERROR: 'JOB_SIGNALS_DATA_LIST_ERROR',
  JOB_ASSETS_SAVE_ERROR: 'JOB_ASSETS_SAVE_ERROR',
  JOBS_FILTER: 'JOBS_FILTER',
  JOBS_SEARCH_CHANGE: 'JOBS_SEARCH_CHANGE',
  JOB_ASSETS_REMOVE_SUCCESS: 'JOB_ASSETS_REMOVE_SUCCESS',
  JOBS_MARKER_FILTER: 'JOB_MARKER_FILTER',

  JOBS_CUSTOM_REPORT_LIST_SUCCESS: 'JOBS_CUSTOM_REPORT_LIST_SUCCESS',
  JOBS_CUSTOM_REPORT_LIST_LOADING: 'JOBS_CUSTOM_REPORT_LIST_LOADING',
  JOBS_CUSTOM_REPORT_LIST_ERROR: 'JOBS_CUSTOM_REPORT_LIST_ERROR',
  SET_CURRENT_JOBS_CUSTOM_REPORT: 'SET_CURRENT_JOBS_CUSTOM_REPORT',
  JOBS_CUSTOM_REPORT_LIST_ERROR_NOTIFIED: 'JOBS_CUSTOM_REPORT_LIST_ERROR_NOTIFIED',
  JOBS_CUSTOM_REPORT_RESET: 'JOBS_CUSTOM_REPORT_RESET',

  PARTS_LIST_LOADING: 'PARTS_LIST_LOADING',
  PARTS_LIST_ERROR: 'PARTS_LIST_ERROR',
  PARTS_LIST_SUCCESS: 'PARTS_LIST_SUCCESS',
  PARTS_LIST_ERROR_NOTIFIED: 'PARTS_LIST_ERROR_NOTIFIED',
  PARTS_LIST_SAVING: 'PARTS_LIST_SAVING',
  PARTS_LIST_FILTER: 'PARTS_LIST_FILTER',
  PARTS_LIST_MOBILE_FILTER: 'PARTS_LIST_MOBILE_FILTER',
  PARTS_SEARCH_CHANGE: 'PARTS_SEARCH_CHANGE',
  PARTS_SET_CLASSIFICATION: 'PARTS_SET_CLASSIFICATION',
  PARTS_SET_PRICE: 'PARTS_SET_PRICE',
  PARTS_SET_PRICE_ERROR: 'PARTS_SET_PRICE_ERROR',
  PARTS_RESET: 'PARTS_RESET',
  PARTS_FILTER_BY_COMPANY: 'PARTS_FILTER_BY_COMPANY',
  PARTS_CUSTOMER_LIST_LOADING: 'PARTS_CUSTOMER_LIST_LOADING',
  PARTS_CUSTOMER_LIST_ERROR: 'PARTS_CUSTOMER_LIST_ERROR',
  PARTS_CUSTOMER_LIST_SUCCESS: 'PARTS_CUSTOMER_LIST_SUCCESS',
  PARTS_CUSTOMER_LIST_ERROR_NOTIFIED: 'PARTS_CUSTOMER_LIST_ERROR_NOTIFIED',

  PART_CATEGORIES_GET_LOADING: 'PART_CATEGORIES_GET_LOADING',
  PART_CATEGORIES_GET_ERROR: 'PART_CATEGORIES_GET_ERROR',
  PART_CATEGORIES_GET_SUCCESS: 'PART_CATEGORIES_GET_SUCCESS',
  PART_CATEGORIES_RESET: 'PART_CATEGORIES_RESET',

  AUDIT_LOGS_LIST_LOADING: 'AUDIT_LOGS_LIST_LOADING',
  AUDIT_LOGS_LIST_SUCCESS: 'AUDIT_LOGS_LIST_SUCCESS',
  AUDIT_LOGS_LIST_ERROR: 'AUDIT_LOGS_LIST_ERROR',
  AUDIT_LOGS_SET_CURRENT_PAGE: 'AUDIT_LOGS_SET_CURRENT_PAGE',
  AUDIT_LOGS_SEARCH_CONTROL: 'AUDIT_LOGS_SEARCH_CONTROL',
  AUDIT_LOGS_SET_FILTER: 'AUDIT_LOGS_SET_FILTER',
  AUDIT_LOGS_SET_SORT: 'AUDIT_LOGS_SET_SORT',

  MARKETPLACE_LOGS_LIST_LOADING: 'MARKETPLACE_LOGS_LIST_LOADING',
  MARKETPLACE_LOGS_LIST_SUCCESS: 'MARKETPLACE_LOGS_LIST_SUCCESS',
  MARKETPLACE_LOGS_LIST_ERROR: 'MARKETPLACE_LOGS_LIST_ERROR',
  MARKETPLACE_LOGS_SET_CURRENT_PAGE: 'MARKETPLACE_LOGS_SET_CURRENT_PAGE',
  MARKETPLACE_LOGS_SEARCH_CONTROL: 'MARKETPLACE_LOGS_SEARCH_CONTROL',
  MARKETPLACE_LOGS_SET_FILTER: 'MARKETPLACE_LOGS_SET_FILTER',
  MARKETPLACE_LOGS_SET_SORT: 'MARKETPLACE_LOGS_SET_SORT',

  TRANSACTION_HISTORY_LIST_LOADING: 'TRANSACTION_HISTORY_LIST_LOADING',
  TRANSACTION_HISTORY_KPIS_LOADING: 'TRANSACTION_HISTORY_KPIS_LOADING',
  TRANSACTION_HISTORY_DIVISION_KPIS_LOADING: 'TRANSACTION_HISTORY_DIVISION_KPIS_LOADING',
  TRANSACTION_HISTORY_LIST_SUCCESS: 'TRANSACTION_HISTORY_LIST_SUCCESS',
  TRANSACTION_HISTORY_KPIS_SUCCESS: 'TRANSACTION_HISTORY_KPIS_SUCCESS',
  TRANSACTION_HISTORY_DIVISION_KPIS_SUCCESS: 'TRANSACTION_HISTORY_DIVISION_KPIS_SUCCESS',
  TRANSACTION_HISTORY_LIST_ERROR: 'TRANSACTION_HISTORY_LIST_ERROR',
  TRANSACTION_HISTORY_KPIS_ERROR: 'TRANSACTION_HISTORY_KPIS_ERROR',
  TRANSACTION_HISTORY_DIVISION_KPIS_ERROR: 'TRANSACTION_HISTORY_DIVISION_KPIS_ERROR',
  TRANSACTION_HISTORY_ORDER_NUM: 'TRANSACTION_HISTORY_ORDER_NUM',
  TRANSACTION_HISTORY_SEARCH_CONTROL: 'TRANSACTION_HISTORY_SEARCH_CONTROL',
  TRANSACTION_HISTORY_PRINT_STATE: 'TRANSACTION_HISTORY_PRINT_STATE',
  TRANSACTION_HISTORY_SET_CURRENT_PAGE: 'TRANSACTION_HISTORY_SET_CURRENT_PAGE',
  TRANSACTION_HISTORY_SET_FILTER: 'TRANSACTION_HISTORY_SET_FILTER',
  TRANSACTION_HISTORY_SET_SORT: 'TRANSACTION_HISTORY_SET_SORT',

  MARKETPLACE_TRANSACTION_LIST_LOADING: 'MARKETPLACE_TRANSACTION_LIST_LOADING',
  MARKETPLACE_TRANSACTION_LIST_ERROR: 'MARKETPLACE_TRANSACTION_LIST_ERROR',
  MARKETPLACE_TRANSACTION_SET_LIST: 'MARKETPLACE_TRANSACTION_SET_LIST',

  ALARM_HISTORY_LIST_LOADING: 'ALARM_HISTORY_LIST_LOADING',
  ALARM_HISTORY_LIST_SUCCESS: 'ALARM_HISTORY_LIST_SUCCESS',
  ALARM_HISTORY_LIST_ERROR: 'ALARM_HISTORY_LIST_ERROR',
  ALARM_HISTORY_SET_CURRENT_PAGE: 'ALARM_HISTORY_SET_CURRENT_PAGE',
  ALARM_HISTORY_SEARCH_CONTROL: 'ALARM_HISTORY_SEARCH_CONTROL',
  ALARM_HISTORY_SET_FILTER: 'ALARM_HISTORY_SET_FILTER',
  ALARM_HISTORY_SET_SORT: 'ALARM_HISTORY_SET_SORT',

  COMPANIES_LIST_LOADING: 'COMPANIES_LIST_LOADING',
  COMPANIES_LIST_ERROR: 'COMPANIES_LIST_ERROR',
  COMPANIES_LIST_SUCCESS: 'COMPANIES_LIST_SUCCESS',
  COMPANIES_LIST_CLEAR: 'COMPANIES_LIST_CLEAR',
  COMPANIES_LIST_ERROR_NOTIFIED: 'COMPANIES_LIST_ERROR_NOTIFIED',
  COMPANIES_RESET: 'COMPANIES_RESET',

  MARKETPLACE_FEATURED_LIST_LOADING: 'MARKETPLACE_FEATURED_LIST_LOADING',
  MARKETPLACE_FEATURED_LIST_SUCCESS: 'MARKETPLACE_FEATURED_LIST_SUCCESS',

  MARKETPLACE_SEARCH_LOADING: 'MARKETPLACE_SEARCH_LOADING',
  MARKETPLACE_SEARCH_SUCCESS: 'MARKETPLACE_SEARCH_SUCCESS',
  MARKETPLACE_SEARCH_ERROR: 'MARKETPLACE_SEARCH_ERROR',
  MARKETPLACE_SEARCH_RESET: 'MARKETPLACE_SEARCH_RESET',

  ORDERS_LIST_LOADING: 'ORDERS_LIST_LOADING',
  ORDERS_LIST_ERROR: 'ORDERS_LIST_ERROR',
  ORDERS_LIST_SUCCESS: 'ORDERS_LIST_SUCCESS',
  ORDERS_LIST_UPDATE: 'ORDERS_LIST_UPDATE',
  ORDERS_LIST_ERROR_NOTIFIED: 'ORDERS_LIST_ERROR_NOTIFIED',
  ORDERS_SET_GREEN_LIST: 'ORDERS_SET_GREEN_LIST',
  ORDERS_SET_GREEN_ITEM_NOT_FOUND: 'ORDERS_SET_GREEN_ITEM_NOT_FOUND',
  ORDERS_SET_GRAY_LIST: 'ORDERS_SET_GRAY_LIST',
  ORDERS_SET_GRAY_ITEM_NOT_FOUND: 'ORDERS_SET_GRAY_ITEM_NOT_FOUND',
  ORDERS_RESET: 'ORDERS_RESET',
  ORDERS_SET_GREEN_PARTS_DETAILS_SUCCESS:
    'ORDERS_SET_GREEN_PARTS_DETAILS_SUCCESS',
  ORDERS_GREEN_PARTS_DETAILS_LOADING: 'ORDERS_GREEN_PARTS_DETAILS_LOADING',
  ORDERS_GREEN_PARTS_ERROR_NOTIFIED: 'ORDERS_GREEN_PARTS_ERROR_NOTIFIED',
  ORDER_PARTS_POSTING: 'ORDER_PARTS_POSTING',
  ORDER_PARTS_POST_SUCCESS: 'ORDER_PARTS_POST_SUCCESS',
  ORDER_PARTS_POST_ERROR: 'ORDER_PARTS_POST_ERROR',

  QUOTES_LIST_LOADING: 'QUOTES_LIST_LOADING',
  QUOTES_LIST_ERROR: 'QUOTES_LIST_ERROR',
  QUOTES_LIST_SUCCESS: 'QUOTES_LIST_SUCCESS',
  QUOTES_LIST_ERROR_NOTIFIED: 'QUOTES_LIST_ERROR_NOTIFIED',
  QUOTES_RESET: 'QUOTES_RESET',
  QUOTES_SET_GRAY_PARTS_DETAILS_SUCCESS:
    'QUOTES_SET_GRAY_PARTS_DETAILS_SUCCESS',
  QUOTES_GRAY_PARTS_DETAILS_LOADING: 'QUOTES_GRAY_PARTS_DETAILS_LOADING',
  QUOTES_GRAY_PARTS_ERROR_NOTIFIED: 'QUOTES_GRAY_PARTS_ERROR_NOTIFIED',
  QUOTE_PARTS_POSTING: 'QUOTE_PARTS_POSTING',
  QUOTE_PARTS_POST_SUCCESS: 'QUOTE_PARTS_POST_SUCCESS',
  QUOTE_PARTS_POST_ERROR: 'QUOTE_PARTS_POST_ERROR',
  SET_SHOPPING_CART_PREFERENCES: 'SET_SHOPPING_CART_PREFERENCES',

  TICKETS_LIST_LOADING: 'TICKETS_LIST_LOADING',
  SET_TICKETS_LIST: 'SET_TICKETS_LIST',
  TICKETS_LIST_ERROR: 'TICKETS_LIST_ERROR',
  TICKETS_RESET: 'TICKETS_RESET',

  SELLERS_LIST_LOADING: 'SELLERS_LIST_LOADING',
  SELLERS_LIST_SUCCESS: 'SELLERS_LIST_SUCCESS',
  SELLERS_LIST_ERROR: 'SELLERS_LIST_ERROR',
  SELLERS_LIST_ERROR_NOTIFIED: 'SELLERS_LIST_ERROR_NOTIFIED',
  SELLERS_RESET: 'SELLERS_RESET',

  USERS_LIST_LOADING: 'USERS_LIST_LOADING',
  USERS_LIST_ERROR: 'USERS_LIST_ERROR',
  USERS_LIST_SUCCESS: 'USERS_LIST_SUCCESS',
  USERS_LIST_ERROR_NOTIFIED: 'USERS_LIST_ERROR_NOTIFIED',
  USERS_RESET: 'USERS_RESET',

  COMPANIES_USERS_LIST_LOADING: 'COMPANIES_USERS_LIST_LOADING',
  COMPANIES_USERS_LIST_ERROR: 'COMPANIES_USERS_LIST_ERROR',
  COMPANIES_USERS_LIST_SUCCESS: 'COMPANIES_USERS_LIST_SUCCESS',
  COMPANIES_USERS_LIST_ERROR_NOTIFIED: 'COMPANIES_USERS_LIST_ERROR_NOTIFIED',
  COMPANIES_USERS_RESET: 'COMPANIES_USERS_RESET',

  USERS_POD_UPLOAD_LOADING: 'USERS_POD_ LOADING',
  USERS_POD_UPLOAD_ERROR: 'USERS_POD_ERROR',
  USERS_POD_UPLOAD_SUCCESS: 'USERS_POD_SUCCESS',
  USERS_POD_UPLOAD_ERROR_NOTIFIED: 'USERS_POD_ERROR_NOTIFIED',
  USERS_SET_POD: 'USERS_SET_POD',

  ASSET_DATA_LOADING: 'ASSET_DATA_LOADING',
  ASSET_DATA_ERROR: 'ASSET_DATA_ERROR',
  ASSET_DATA_SUCCESS: 'ASSET_DATA_SUCCESS',
  ASSET_DATA_ERROR_NOTIFIED: 'ASSET_DATA_ERROR_NOTIFIED',
  ASSET_DATA_RESET: 'ASSET_DATA_RESET',
  ASSET_DATA_SET_PRESET: 'ASSET_DATA_SET_PRESET',
  ASSET_DATA_SET_DATES: 'ASSET_DATA_SET_DATES',
  ASSET_DATA_TOOGLE_DATES_MODAL: 'ASSET_DATA_TOOGLE_DATES_MODAL',
  ASSET_DATA_TOOGLE_PAUSE: 'ASSET_DATA_TOOGLE_PAUSE',
  ASSET_DATA_PAUSE: 'ASSET_DATA_PAUSE',
  ASSET_DATA_TAG_POST: 'ASSET_DATA_TAG_POST',

  EVENTS_DATA_SUCCESS: 'EVENTS_DATA_SUCCESS',
  EVENTS_DATA_LOADING: 'EVENTS_DATA_LOADING',

  ASSETS_LOADING: 'ASSETS_LOADING',
  ASSETS_SUCCESS: 'ASSETS_SUCCESS',
  ASSETS_ERROR: 'ASSETS_ERROR',
  ASSETS_ERROR_NOTIFIED: 'ASSETS_ERROR_NOTIFIED',
  ASSETS_RESET: 'ASSETS_RESET',
  ASSETS_SAVE_JOB_ASSET_SUCCESS: 'ASSET_DATA_SAVE_ASSET_SUCCESS',
  ASSETS_REMOVE_JOB_ASSET_SUCCESS: 'ASSETS_REMOVE_JOB_ASSET_SUCCESS',

  EPICOR_PARTS_LIST_LOADING: 'EPICOR_PARTS_LIST_LOADING',
  EPICOR_PARTS_LIST_ERROR: 'EPICOR_PARTS_LIST_ERROR',
  EPICOR_PARTS_LIST_SUCCESS: 'EPICOR_PARTS_LIST_SUCCESS',
  EPICOR_PARTS_LIST_ERROR_NOTIFIED: 'EPICOR_PARTS_LIST_ERROR_NOTIFIED',
  EPICOR_PARTS_RESET: 'EPICOR_PARTS_RESET',
  EPICOR_PARTS_SEARCH: 'EPICOR_PARTS_SEARCH',

  EPICOR_CUSTOMERS_LIST_LOADING: 'EPICOR_CUSTOMERS_LIST_LOADING',
  EPICOR_CUSTOMERS_LIST_ERROR: 'EPICOR_CUSTOMERS_LIST_ERROR',
  EPICOR_CUSTOMERS_LIST_SUCCESS: 'EPICOR_CUSTOMERS_LIST_SUCCESS',
  EPICOR_CUSTOMERS_LIST_ERROR_NOTIFIED: 'EPICOR_CUSTOMERS_LIST_ERROR_NOTIFIED',
  EPICOR_CUSTOMERS_RESET: 'EPICOR_CUSTOMERS_RESET',
  EPICOR_CUSTOMERS_SEARCH: 'EPICOR_CUSTOMERS_SEARCH',

  EPICOR_PRICE_LISTS_LIST_LOADING: 'EPICOR_PRICE_LISTS_LIST_LOADING',
  EPICOR_PRICE_LISTS_LIST_SUCCESS: 'EPICOR_PRICE_LISTS_LIST_SUCCESS',
  EPICOR_PRICE_LISTS_LIST_ERROR: 'EPICOR_PRICE_LISTS_LIST_ERROR',
  EPICOR_PRICE_LISTS_LIST_ERROR_NOTIFIED:
    'EPICOR_PRICE_LISTS_LIST_ERROR_NOTIFIED',
  EPICOR_PRICE_LISTS_SEARCH: 'EPICOR_PRICE_LISTS_SEARCH',
  EPICOR_PRICE_LISTS_RESET: 'EPICOR_PRICE_LISTS_RESET',

  EPICOR_SALE_ORDERS_LIST_LOADING: 'EPICOR_SALE_ORDERS_LIST_LOADING',
  EPICOR_SALE_ORDERS_LIST_SUCCESS: 'EPICOR_SALE_ORDERS_LIST_SUCCESS',
  EPICOR_SALE_ORDERS_LIST_ERROR: 'EPICOR_SALE_ORDERS_LIST_ERROR',
  EPICOR_SALE_ORDERS_LIST_ERROR_NOTIFIED:
    'EPICOR_SALE_ORDERS_LIST_ERROR_NOTIFIED',
  EPICOR_SALE_ORDERS_SEARCH: 'EPICOR_SALE_ORDERS_SEARCH',
  EPICOR_SALE_ORDERS_RESET: 'EPICOR_SALE_ORDERS_RESET',

  EPICOR_SALE_ORDERS_POSTING: 'EPICOR_SALE_ORDERS_POSTING',
  EPICOR_SALE_ORDERS_POST_SUCCESS: 'EPICOR_SALE_ORDERS_POST_SUCCESS',
  EPICOR_SALE_ORDERS_POST_ERROR: 'EPICOR_SALE_ORDERS_POST_ERROR',

  EPICOR_WORK_FORCE_LIST_LOADING: 'EPICOR_WORK_FORCE_LIST_LOADING',
  EPICOR_WORK_FORCE_LIST_SUCCESS: 'EPICOR_WORK_FORCE_LIST_SUCCESS',
  EPICOR_WORK_FORCE_LIST_ERROR: 'EPICOR_WORK_FORCE_LIST_ERROR',
  EPICOR_WORK_FORCE_LIST_ERROR_NOTIFIED:
    'EPICOR_WORK_FORCE_LIST_ERROR_NOTIFIED',
  EPICOR_WORK_FORCE_SEARCH: 'EPICOR_WORK_FORCE_SEARCH',
  EPICOR_WORK_FORCE_RESET: 'EPICOR_WORK_FORCE_RESET',

  EPICOR_BINS_LIST_LOADING: 'EPICOR_BINS_LIST_LOADING',
  EPICOR_BINS_LIST_ERROR: 'EPICOR_BINS_LIST_ERROR',
  EPICOR_BINS_LIST_SUCCESS: 'EPICOR_BINS_LIST_SUCCESS',
  EPICOR_BINS_LIST_ERROR_NOTIFIED: 'EPICOR_BINS_LIST_ERROR_NOTIFIED',
  EPICOR_BINS_RESET: 'EPICOR_BINS_RESET',
  EPICOR_BINS_FILTER: 'EPICOR_BINS_FILTER',
  EPICOR_BINS_SEARCH_CHANGE: 'EPICOR_BINS_SEARCH_CHANGE',
  EPICOR_BINS_SEARCH: 'EPICOR_BINS_SEARCH',

  FIELDBOXES_KPIS_LIST_LOADING: 'FIELDBOXES_KPIS_LIST_LOADING',
  FIELDBOXES_KPIS_LIST_ERROR: 'FIELDBOXES_KPIS_LIST_ERROR',
  FIELDBOXES_KPIS_LIST_SUCCESS: 'FIELDBOXES_KPIS_LIST_SUCCESS',
  FIELDBOXES_KPIS_SET_DIVISION: 'FIELDBOXES_KPIS_SET_DIVISION',
  FIELDBOXES_KPIS_LIST_FILTER: 'FIELDBOXES_KPIS_LIST_FILTER',
  FIELDBOXES_KPIS_LIST_SEARCH_CHANGE: 'FIELDBOXES_KPIS_LIST_SEARCH_CHANGE',

  EPICOR_TRUCKS_LIST_LOADING: 'EPICOR_TRUCKS_LIST_LOADING',
  EPICOR_TRUCKS_LIST_ERROR: 'EPICOR_TRUCKS_LIST_ERROR',
  EPICOR_TRUCKS_LIST_SUCCESS: 'EPICOR_TRUCKS_LIST_SUCCESS',
  EPICOR_TRUCKS_LIST_ERROR_NOTIFIED: 'EPICOR_TRUCKS_LIST_ERROR_NOTIFIED',
  EPICOR_TRUCKS_RESET: 'EPICOR_TRUCKS_RESET',

  EPICOR_SET_SELECTED_ROWS: 'EPICOR_SET_SELECTED_ROWS',
  EPICOR_RELEASES_LIST_LOADING: 'EPICOR_RELEASES_LIST_LOADING',
  EPICOR_RELEASES_LIST_SUCCESS: 'EPICOR_RELEASES_LIST_SUCCESS',
  EPICOR_RELEASES_LIST_ERROR: 'EPICOR_RELEASES_LIST_ERROR',
  EPICOR_RELEASES_RESET: 'EPICOR_RELEASES_RESET',

  EPICOR_ORDER_DETAILS_LOADING: 'EPICOR_ORDER_DETAILS_LOADING',
  EPICOR_ORDER_DETAILS_SUCCESS: 'EPICOR_ORDER_DETAILS_SUCCESS',
  EPICOR_ORDER_DETAILS_ERROR: 'EPICOR_ORDER_DETAILS_ERROR',
  EPICOR_ORDER_DETAILS_RESET: 'EPICOR_ORDER_DETAILS_RESET',

  EPICOR_BINS_PARTS_LOADING: 'EPICOR_BINS_PARTS_LOADING',
  EPICOR_BINS_PARTS_ERROR: 'EPICOR_BINS_PARTS_ERROR',
  EPICOR_BINS_PARTS_SUCCESS: 'EPICOR_BINS_PARTS_SUCCESS',
  EPICOR_BINS_PARTS_ERROR_NOTIFIED: 'EPICOR_BINS_PARTS_ERROR_NOTIFIED',

  EPICOR_REPORTS_INVENTORY_PDF_REQUEST: 'EPICOR_REPORTS_INVENTORY_PDF_REQUEST',
  EPICOR_REPORTS_INVENTORY_PDF_LOADING: 'EPICOR_REPORTS_INVENTORY_PDF_LOADING',
  EPICOR_REPORTS_INVENTORY_PDF_SUCCESS: 'EPICOR_REPORTS_INVENTORY_PDF_SUCCESS',
  EPICOR_REPORTS_INVENTORY_PDF_ERROR: 'EPICOR_REPORTS_INVENTORY_PDF_ERROR',
  EPICOR_REPORTS_INVENTORY_PDF_ERROR_NOTIFIED:
    'EPICOR_REPORTS_INVENTORY_PDF_ERROR_NOTIFIED',

  EPICOR_SYNC_MOBILE_DATA_LOADING: 'EPICOR_SYNC_MOBILE_DATA_LOADING',
  EPICOR_SYNC_MOBILE_DATA_IDLE: 'EPICOR_SYNC_MOBILE_DATA_IDLE',
  EPICOR_SYNC_MOBILE_DATA_ERROR: 'EPICOR_SYNC_MOBILE_DATA_ERROR',

  EPICOR_SYNC_BIN_LOADING: 'EPICOR_SYNC_BIN_LOADING',
  EPICOR_SYNC_BIN_IDLE: 'EPICORE_SYNC_BIN_IDLE',
  EPICOR_SYNC_BIN_ERROR: 'EPICORE_SYNC_BIN_ERROR',
  EPICOR_UPDATE_COORDINATES: 'EPICOR_BIN_UPDATE_COORDINATES',
  EPICOR_SYNC_TRUCK_LOADING: 'EPICOR_SYNC_TRUCK_LOADING',
  EPICOR_SYNC_TRUCK_IDLE: 'EPICORE_SYNC_TRUCK_IDLE',
  EPICOR_SYNC_TRUCK_ERROR: 'EPICORE_SYNC_TRUCK_ERROR',

  EPICORE_RIGS_SYNC_SHIPSTO_LOADING: 'EPICORE_RIGS_SYNC_SHIPSTO_LOADING',
  EPICORE_RIGS_SYNC_SHIPSTO_IDLE: 'EPICORE_RIGS_SYNC_SHIPSTO_IDLE',
  EPICORE_RIGS_SYNC_SHIPSTO_ERROR: 'EPICORE_RIGS_SYNC_SHIPSTO_ERROR',

  USER_LOGOUT: 'USER_LOGOUT',

  SNACKBAR_ENQUEUE: 'SNACKBAR_ENQUEUE',
  SNACKBAR_CLOSE: 'SNACKBAR_CLOSE',
  SNACKBAR_REMOVE: 'SNACKBAR_REMOVE',

  DATA_LOADING: 'DATA_LOADING',
  DATA_LOADING_ENDED: 'DATA_LOADING_ENDED',

  SEND_PASSWORD_RESET_EMAIL_LOADING: 'SEND_PASSWORD_RESET_EMAIL_LOADING',
  SEND_PASSWORD_RESET_EMAIL_SUCCESS: 'SEND_PASSWORD_RESET_EMAIL_SUCCESS',
  SEND_PASSWORD_RESET_EMAIL_FAILED: 'SEND_PASSWORD_RESET_EMAIL_FAILED',
  SEND_PASSWORD_RESET_EMAIL_RESET: 'SEND_PASSWORD_RESET_EMAIL_RESET',

  RESET_PASSWORD_LOADING: 'RESET_PASSWORD_LOADING',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILED: 'RESET_PASSWORD_FAILED',
  RESET_PASSWORD_RESET: 'RESET_PASSWORD_RESET',

  SEND_SIGN_UP_REQUEST_LOADING: 'SEND_SIGN_UP_REQUEST_LOADING',
  SEND_SIGN_UP_REQUEST_SUCCESS: 'SEND_SIGN_UP_REQUEST_SUCCESS',
  SEND_SIGN_UP_REQUEST_FAILED: 'SEND_SIGN_UP_REQUEST_FAILED',
  SEND_SIGN_UP_REQUEST_RESET: 'SEND_SIGN_UP_REQUEST_RESET',

  CREATE_ACTUATOR_REPORT_REQUEST: 'CREATE_ACTUATOR_REPORT_REQUEST',
  CREATE_ACTUATOR_REPORT_SUCCESS: 'CREATE_ACTUATOR_REPORT_SUCCESS',
  CREATE_ACTUATOR_REPORT_FAILURE: 'CREATE_ACTUATOR_REPORT_FAILURE',

  MENU_SET_TITLE: 'MENU_SET_TITLE',
  SEARCHBOX_SET_TERMS: 'SEARCHBOX_SET_TERMS',
  MENU_SET_TAB: 'MENU_SET_TAB',

  DOWNLOAD_ORDER_IDLE: 'DOWNLOAD_ORDER_IDLE',
  DOWNLOAD_ORDER_LOADING: 'DOWNLOAD_ORDER_LOADING',
  DOWNLOAD_ORDER_ERROR: 'DOWNLOAD_ORDER_ERROR',

  DOWNLOAD_QUOTE_IDLE: 'DOWNLOAD_QUOTE_IDLE',
  DOWNLOAD_QUOTE_LOADING: 'DOWNLOAD_QUOTE_LOADING',
  DOWNLOAD_QUOTE_ERROR: 'DOWNLOAD_QUOTE_ERROR',

  WELLS_LIST_LOADING: 'WELLS_LIST_LOADING',
  WELLS_LIST_ERROR: 'WELLS_LIST_ERROR',
  WELLS_LIST_SUCCESS: 'WELLS_LIST_SUCCESS',
  WELLS_RESET: 'WELLS_RESET',
  WELLS_FILTER: 'WELLS_FILTER',
  WELLS_MARKER_FILTER: 'WELLS_MARKER_FILTER',
  WELLS_SEARCH_CHANGE: 'WELLS_SEARCH_CHANGE',
  WELLS_SAVE_DETAILS: 'WELLS_SAVE_DETAILS',

  WELLS_SURVEY_LIST_LOADING: 'WELLS_SURVEY_LIST_LOADING',
  WELLS_SURVEY_LIST_ERROR: 'WELLS_SURVEY_LIST_ERROR',
  WELLS_SURVEY_LIST_SUCCESS: 'WELLS_SURVEY_LIST_SUCCESS',
  WELLS_SURVEY_LIST_ERROR_NOTIFIED: 'WELLS_SURVEY_LIST_ERROR_NOTIFIED',
  WELLS_SURVEY_RESET: 'WELLS_SURVEY_RESET',

  WELLS_GEOMETRY_LIST_LOADING: 'WELLS_GEOMETRY_LIST_LOADING',
  WELLS_GEOMETRY_LIST_ERROR: 'WELLS_GEOMETRY_LIST_ERROR',
  WELLS_GEOMETRY_LIST_SUCCESS: 'WELLS_GEOMETRY_LIST_SUCCESS',
  WELLS_GEOMETRY_LIST_ERROR_NOTIFIED: 'WELLS_GEOMETRY_LIST_ERROR_NOTIFIED',
  WELLS_GEOMETRY_RESET: 'WELLS_GEOMETRY_RESET',

  WELLS_DRILL_STRING_LIST_LOADING: 'WELLS_DRILL_STRING_LIST_LOADING',
  WELLS_DRILL_STRING_LIST_ERROR: 'WELLS_DRILL_STRING_LIST_ERROR',
  WELLS_DRILL_STRING_LIST_SUCCESS: 'WELLS_DRILL_STRING_LIST_SUCCESS',
  WELLS_DRILL_STRING_LIST_ERROR_NOTIFIED: 'WELLS_DRILL_STRING_LIST_ERROR_NOTIFIED',
  WELLS_DRILL_STRING_RESET: 'WELLS_DRILL_STRING_RESET',
  WELLS_DRILL_STRING_SET_ID: 'WELLS_DRILL_STRING_SET_ID',

  WELLS_DRILL_STRING_DETAILS_LIST_LOADING: 'WELLS_DRILL_STRING_DETAILS_LIST_LOADING',
  WELLS_DRILL_STRING_DETAILS_LIST_ERROR: 'WELLS_DRILL_STRING_DETAILS_LIST_ERROR',
  WELLS_DRILL_STRING_DETAILS_LIST_SUCCESS: 'WELLS_DRILL_STRING_DETAILS_LIST_SUCCESS',

  WELLS_DRILL_STRING_BIT_DETAILS_LIST_LOADING: 'WELLS_DRILL_STRING_BIT_DETAILS_LIST_LOADING',
  WELLS_DRILL_STRING_BIT_DETAILS_LIST_ERROR: 'WELLS_DRILL_STRING_BIT_DETAILS_LIST_ERROR',
  WELLS_DRILL_STRING_BIT_DETAILS_LIST_SUCCESS: 'WELLS_DRILL_STRING_BIT_DETAILS_LIST_SUCCESS',

  WELLS_DRILL_FLUID_LIST_LOADING: 'WELLS_DRILL_FLUID_LIST_LOADING',
  WELLS_DRILL_FLUID_LIST_ERROR: 'WELLS_DRILL_FLUID_LIST_ERROR',
  WELLS_DRILL_FLUID_LIST_SUCCESS: 'WELLS_DRILL_FLUID_LIST_SUCCESS',
  WELLS_DRILL_FLUID_LIST_ERROR_NOTIFIED: 'WELLS_DRILL_FLUID_LIST_ERROR_NOTIFIED',
  WELLS_DRILL_FLUID_RESET: 'WELLS_DRILL_FLUID_RESET',
  WELLS_DRILL_FLUID_SET_ID: 'WELLS_DRILL_FLUID_SET_ID',

  WELLS_DRILL_FLUID_DETAILS_LIST_LOADING: 'WELLS_DRILL_FLUID_DETAILS_LIST_LOADING',
  WELLS_DRILL_FLUID_DETAILS_LIST_ERROR: 'WELLS_DRILL_FLUID_DETAILS_LIST_ERROR',
  WELLS_DRILL_FLUID_DETAILS_LIST_SUCCESS: 'WELLS_DRILL_FLUID_DETAILS_LIST_SUCCESS',

  RIGS_LIST_LOADING: 'RIGS_LIST_LOADING',
  RIGS_LIST_ERROR: 'RIGS_LIST_ERROR',
  RIGS_LIST_SUCCESS: 'RIGS_LIST_SUCCESS',
  RIGS_RESET: 'RIGS_RESET',
  RIGS_FILTER: 'RIGS_FILTER',
  RIGS_MARKER_FILTER: 'RIGS_MARKER_FILTER',
  RIGS_SEARCH_CHANGE: 'RIGS_SEARCH_CHANGE',
  RIGS_SYNC_LOADING: 'RIGS_SYNC_LOADING',
  RIGS_SYNC_IDLE: 'RIGS_SYNC_IDLE',
  RIGS_SYNC_ERROR: 'RIGS_SYNC_ERROR',

  ASSETTYPE_LIST_LOADING: 'ASSETTYPE_LIST_LOADING',
  ASSETTYPE_LIST_ERROR: 'ASSETTYPE_LIST_ERROR',
  ASSETTYPE_LIST_SUCCESS: 'ASSETTYPE_LIST_SUCCESS',
  ASSETTYPE_RESET: 'ASSETTYPE_RESET',
  ASSETTYPE_FILTER: 'ASSETTYPE_FILTER',
  ASSETTYPE_SEARCH_CHANGE: 'ASSETTYPE_SEARCH_CHANGE',

  ENVERUS_WELLS_LIST_LOADING: 'ENVERUS_WELLS_LIST_LOADING',
  ENVERUS_WELLS_LIST_ERROR: 'ENVERUS_WELLS_LIST_ERROR',
  ENVERUS_WELLS_LIST_SUCCESS: 'ENVERUS_WELLS_LIST_SUCCESS',
  ENVERUS_WELLS_RESET: 'ENVERUS_WELLS_RESET',
  ENVERUS_WELLS_FILTER: 'ENVERUS_WELLS_FILTER',
  ENVERUS_WELLS_MARKER_FILTER: 'ENVERUS_WELLS_MARKER_FILTER',
  ENVERUS_WELLS_SEARCH_CHANGE: 'ENVERUS_WELLS_SEARCH_CHANGE',
  ENVERUS_WELLS_SYNC_LOADING: 'ENVERUS_WELLS_SYNC_LOADING',
  ENVERUS_WELLS_SYNC_IDLE: 'ENVERUS_WELLS_SYNC_IDLE',
  ENVERUS_WELLS_SYNC_ERROR: 'ENVERUS_WELLS_SYNC_ERROR',
  ENVERUS_WELL_DETAILS_LOADING: 'ENVERUS_WELL_DETAILS_LOADING',
  ENVERUS_WELL_DETAILS_SUCCESS: 'ENVERUS_WELL_DETAILS_SUCCESS',
  ENVERUS_WELL_DETAILS_ERROR: 'ENVERUS_WELL_DETAILS_ERROR',
  ENVERUS_WELLS_SET_CURRENT_PAGE: 'ENVERUS_WELLS_SET_CURRENT_PAGE',

  CHECKINS_LIST_LOADING: 'CHECKINS_LIST_LOADING',
  CHECKINS_LIST_ERROR: 'CHECKINS_LIST_ERROR',
  CHECKINS_LIST_SUCCESS: 'CHECKINS_LIST_SUCCESS',
  CHECKINS_RESET: 'CHECKINS_RESET',
  CHECKINS_FILTER: 'CHECKINS_FILTER',
  CHECKINS_MARKER_FILTER: 'CHECKINS_MARKER_FILTER',
  CHECKINS_SEARCH_CHANGE: 'CHECKINS_SEARCH_CHANGE',
  CHECKINS_SYNC_LOADING: 'CHECKINS_SYNC_LOADING',
  CHECKINS_SYNC_IDLE: 'CHECKINS_SYNC_IDLE',
  CHECKINS_SYNC_ERROR: 'CHECKINS_SYNC_ERROR',
  CHECKINS_DETAILS_LOADING: 'CHECKINS_DETAILS_LOADING',
  CHECKINS_DETAILS_SUCCESS: 'CHECKINS_DETAILS_SUCCESS',
  CHECKINS_DETAILS_ERROR: 'CHECKINS_DETAILS_ERROR',
  CHECKINS_SET_CURRENT_PAGE: 'CHECKINS_SET_CURRENT_PAGE',
  CHECKINS_SET_FILTER: 'CHECKINS_SET_FILTER',
  CHECKINS_SET_SORT: 'CHECKINS_SET_SORT',
  CHECKINS_SEARCH_CONTROL: 'CHECKINS_SEARCH_CONTROL',
  CHECK_INS_EMAIL_KPIS_LOADING: 'CHECK_INS_EMAIL_KPIS_LOADING',
  CHECK_INS_EMAIL_KPIS_SUCCESS: 'CHECK_INS_EMAIL_KPIS_SUCCESS',
  CHECK_INS_EMAIL_KPIS_ERROR: 'CHECK_INS_EMAIL_KPIS_ERROR',
  CHECK_INS_DIVISION_KPIS_LOADING: 'CHECK_INS_DIVISION_KPIS_LOADING',
  CHECK_INS_DIVISION_KPIS_SUCCESS: 'CHECK_INS_DIVISION_KPIS_SUCCESS',
  CHECK_INS_DIVISION_KPIS_ERROR: 'CHECK_INS_DIVISION_KPIS_ERROR',
  USERS_PROFILE_PICTURE_UPLOAD_LOADING: 'USERS_PROFILE_PICTURE_LOADING',
  USERS_PROFILE_PICTURE_UPLOAD_ERROR: 'USERS_PROFILE_PICTURE_ERROR',
  USERS_PROFILE_PICTURE_UPLOAD_SUCCESS: 'USERS_PROFILE_PICTURE_SUCCESS',
  USERS_PROFILE_PICTURE_UPLOAD_ERROR_NOTIFIED: 'USERS_PROFILE_PICTURE_ERROR_NOTIFIED',
  USERS_PROFILE_PICTURE_SET_POD: 'USERS_PROFILE_PICTURE_SET_POD',

  HYDRAULICS_LIST_LOADING: 'HYDRAULICS_LIST_LOADING',
  HYDRAULICS_LIST_ERROR: 'HYDRAULICS_LIST_ERROR',
  HYDRAULICS_LIST_SUCCESS: 'HYDRAULICS_LIST_SUCCESS',
  HYDRAULICS_LIST_ERROR_NOTIFIED: 'HYDRAULICS_LIST_ERROR_NOTIFIED',
  HYDRAULICS_RESET: 'HYDRAULICS_RESET',
  HYDRAULICS_DETAILS_LIST_LOADING: 'HYDRAULICS_DETAILS_LIST_LOADING',
  HYDRAULICS_DETAILS_LIST_ERROR: 'HYDRAULICS_DETAILS_LIST_ERROR',
  HYDRAULICS_DETAILS_LIST_SUCCESS: 'HYDRAULICS_DETAILS_LIST_SUCCESS',
  HYDRAULICS_SET_ID: 'HYDRAULICS_SET_ID'

};
