import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Datagrid from '../../ui/Datagrid';
import { FormControl, InputLabel, FilledInput, Grid } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { listEpicorSaleOrders } from '../../../store/actions/epicor/epicorSaleOrders';
import { gridColumnGenerator } from '../../../utils/gridColumnGenerator';
import useStyles from './styles';

const EpicorSaleOrders = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [columns, setColumns] = useState([]);
  const [isColumnResized, setIsColumnResized] = useState(false);
  const epicorSaleOrders = useSelector((state) => state.epicorSaleOrders);
  const apiRef = useGridApiRef();
  const renderCell = (params) => params.value;

  const [visibleColumns, setVisibleColumns] = useState([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [firstLoad, setFirstLoad] = useState(false);
  const [selectValue, setSelectValue] = useState('');
  const [expandValue, setExpandValue] = useState('');
  const [filterValue, setFilterValue] = useState('');
  const [orderByValue, setOrderByValue] = useState('');
  const [topValue, setTopValue] = useState('10');
  const [skipValue, setSkipValue] = useState('');

  const handleVisibiltyModel = (newModel) => {
    // add current columns to the visible columns list
    const currentColumns = extractVisibleFields(apiRef.current.getVisibleColumns());
    setVisibleColumns(currentColumns);
    setColumnVisibilityModel(newModel);
  };

  // returns a list of visible columns to be use in the grid view
  const extractVisibleFields = (objectArray) => {
    if (!Array.isArray(objectArray) || objectArray.length === 0) {
      return [];
    }

    const visibleFields = objectArray
      .filter((item) => item.hide === false)
      .map((item) => item.field);

    return visibleFields;
  };

  useEffect(() => {
    // on first load use top value
    if (!epicorSaleOrders.loading && epicorSaleOrders.data.length === 0) {
      const params = {
        topValue,
      };
      dispatch(listEpicorSaleOrders(params));
      setFirstLoad(true);
    }
  }, []);

  useEffect(() => {
    // If run button is pressed then execute the action
    if (epicorSaleOrders.executeSearch) {
      const params = {
        selectValue,
        expandValue,
        filterValue,
        orderByValue,
        topValue,
        skipValue,
      };

      dispatch(listEpicorSaleOrders(params));
    }
  }, [epicorSaleOrders.executeSearch]);

  useEffect(() => {
    if (!epicorSaleOrders.loading && epicorSaleOrders.data.length > 0) {
      let columnsList = [];

      if (selectValue) {
        columnsList = gridColumnGenerator(
          selectValue.split(', '),
          epicorSaleOrders.data,
          renderCell
        );
      } else {
        columnsList = gridColumnGenerator(
          visibleColumns,
          epicorSaleOrders.data,
          renderCell
        );
      }

      setColumns(columnsList);
    }
  }, [epicorSaleOrders.data]);

  useEffect(() => {
    if (apiRef.current) {
      if (firstLoad) {
        const initialColumnVisibilityModel = columns.map((column) => ({
          field: column.field,
          visible: true,
        }));
        setColumnVisibilityModel(initialColumnVisibilityModel);
        apiRef.current.setColumnVisibilityModel(columnVisibilityModel); // set initial column visibility model
        setFirstLoad(false);
      } else {
        const currentColumnVisibilityModel = columns.map((column) => ({
          field: column.field,
          visible: true,
        }));
        setColumnVisibilityModel(currentColumnVisibilityModel);
        apiRef.current.setColumnVisibilityModel(columnVisibilityModel);
      }
    }
    const fieldsToHide = ['grid_id', '_id'];
    const columnsToHide = columns.filter((column) => {
      return fieldsToHide.includes(column.field);
    });
    columnsToHide.forEach((column) => {
      apiRef.current.setColumnVisibility(column.field, false);
    });
  }, [apiRef, columns]);

  return (
    <>
      <Grid container spacing={2} className={classes.containerGrid}>
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={3}>
              <FormControl
                fullWidth
                variant="filled"
                className={classes.inputField}
                sx={{ bgcolor: 'transparent' }}
              >
                <InputLabel htmlFor="outlined-adornment-select">
                  Select
                </InputLabel>
                <FilledInput
                  id="outlined-adornment-select"
                  value={selectValue}
                  onChange={(e) => setSelectValue(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl
                fullWidth
                variant="filled"
                className={classes.inputField}
                sx={{ bgcolor: 'transparent' }}
              >
                <InputLabel htmlFor="outlined-adornment-expand">
                  Expand
                </InputLabel>
                <FilledInput
                  id="outlined-adornment-expand"
                  value={expandValue}
                  onChange={(e) => setExpandValue(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl
                fullWidth
                variant="filled"
                className={classes.inputField}
                sx={{ bgcolor: 'transparent' }}
              >
                <InputLabel htmlFor="outlined-adornment-filter">
                  Filter
                </InputLabel>
                <FilledInput
                  id="outlined-adornment-filter"
                  value={filterValue}
                  onChange={(e) => setFilterValue(e.target.value)}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={3}>
                  <FormControl
                    fullWidth
                    variant="filled"
                    className={classes.inputField}
                    sx={{ bgcolor: 'transparent' }}
                  >
                    <InputLabel htmlFor="outlined-adornment-orderby">
                      OrderBy
                    </InputLabel>
                    <FilledInput
                      id="outlined-adornment-orderby"
                      value={orderByValue}
                      onChange={(e) => setOrderByValue(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl
                    fullWidth
                    variant="filled"
                    className={classes.inputField}
                    sx={{ bgcolor: 'transparent' }}
                  >
                    <InputLabel htmlFor="outlined-adornment-top">
                      Top
                    </InputLabel>
                    <FilledInput
                      id="outlined-adornment-top"
                      value={topValue}
                      onChange={(e) => setTopValue(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl
                    fullWidth
                    variant="filled"
                    className={classes.inputField}
                    sx={{ bgcolor: 'transparent' }}
                  >
                    <InputLabel htmlFor="outlined-adornment-skip">
                      Skip
                    </InputLabel>
                    <FilledInput
                      id="outlined-adornment-skip"
                      value={skipValue}
                      onChange={(e) => setSkipValue(e.target.value)}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Datagrid
            apiRef={apiRef}
            data={epicorSaleOrders.data}
            columns={columns}
            loading={epicorSaleOrders.loading}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) => {
              //  Check if event is triggered by column resizing
              if (!isColumnResized) {
                handleVisibiltyModel(newModel);
              } else {
                setIsColumnResized(false);
              }
            }}
            onColumnResize={(_params) => {
              setIsColumnResized(true);
            }}
            height="auto"
            hideFooter={false}
            toolbar={{
              options: {
                columns: true,
                filters: true,
                density: true,
                export: false,
              },
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default EpicorSaleOrders;
