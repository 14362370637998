const columns = (renderCell, renderActions, renderValueCell) => [
  {
    field: 'name',
    headerName: 'Name',
    width: 220,
    renderCell,
    editable: true,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'od',
    headerName: 'OD (in)',
    width: 220,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'id',
    headerName: 'ID (in)',
    width: 220,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'uw',
    headerName: 'UW (lb/ft)',
    width: 220,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'tj_od',
    headerName: 'TJ OD (in)',
    width: 220,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'tj_id',
    headerName: 'TJ ID (in)',
    width: 220,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'len',
    headerName: 'LENGTH (ft)',
    width: 220,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'tj_len%',
    headerName: 'TJ LENGTH (%)',
    width: 220,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'actions',
    headerName: 'Actions',
    headerAlign: 'center',
    align: 'center',
    renderCell: renderActions,
  },
];
export default columns;
