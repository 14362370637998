import { makeStyles } from '@mui/styles';

const styles = makeStyles(({ spacing }) => ({
  carouselContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: 0,
    paddingRight: 0,
    gap: spacing(2),
  },
  carouselContainerGridView: {
    display: 'grid',
    gridTemplateColumns: '345px 345px 345px',
    justifyContent: 'center',
    paddingLeft: 0,
    paddingRight: 0,
    gap: spacing(2),
    paddingBottom: '20px'
  }
}));

export default styles;
