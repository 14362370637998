import { AspectRatioRounded, FileOpen, ListAltRounded, TableRowsOutlined, ViewCarouselRounded } from '@mui/icons-material';
import { Box, CardMedia, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { REDUX_ACTIONS } from '../../../../shared/config/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../../../hooks/useAuth';
import { COMPANY_TYPE, USER_TYPE } from '../../../../utils/constants';
import { useEffect, useRef, useState } from 'react';
import { isMobile } from '../../../../utils/isMobile';
import { useLocation } from 'react-router';
import { getCustomersSellers, listCompanies, listSellersCustomers, listSellersUsers } from '../../../../store/actions/companies';
import { listUsers } from '../../../../store/actions/users';
import { listParts, listPartsByCustomer, partsCsvUpload } from '../../../../store/actions/parts';
import styles from './styles';
import useAspectRatio from '../../../../hooks/useAspectRatio';
import WidgetsIcon from '@mui/icons-material/Widgets';
import SyncMobileDevicesModal from './../../ControlPanel/SyncMobileDevices/SyncMobileDevicesModal/index';
import Premium from '../../../../assets/images/PRO logo PMS green only.svg';
import { enqueueSnackbar } from '../../../../store/actions/snackbar';

const InventoryView = () => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const mobile = isMobile();
  const classes = styles();
  const location = useLocation();
  const companies = useSelector((state) => state.companies);
  const users = useSelector((state) => state.users);
  const [companyInventory, setCompanyInventory] = useState('');
  const [selectionList, setSelectionList] = useState(null);
  const { preferences } = useSelector((state) => state.currentUser);

  const [uploadOpen, setUploadOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const dropAreaRef = useRef(null);
  const fileInputRef = useRef(null);

  const handleAspectRatio = useAspectRatio();

  useEffect(() => {
    setCompanyInventory('');
    setSelectionList(null);
  }, [location.pathname]);

  useEffect(() => {
    if (!users.loading && users.data.length === 0) {
      if (user.type === USER_TYPE.SELLER_MARKETPLACE_ADMIN) {
        dispatch(listSellersUsers(user));
      } else {
        dispatch(listUsers(user));
      }
    }
  }, []);

  useEffect(() => {
    if (!companies.loading && companies.data.length === 0) {
      if (user.type === USER_TYPE.SELLER_MARKETPLACE_ADMIN) {
        dispatch(listSellersCustomers(user));
      } else if (user.type === USER_TYPE.CUSTOMER_MANAGER || user.type === USER_TYPE.CUSTOMER_USER) {
        dispatch(getCustomersSellers(user));
      } else {
        dispatch(listCompanies(user));
      }
    }
  }, []);

  useEffect(() => {
    if (companies.data.length && users.data.length) {
      const companyList = companies.data.filter((item) => item.type === COMPANY_TYPE.MARKETPLACE_CUSTOMER);
      const userList = users.data.filter((item) => item.type === USER_TYPE.CUSTOMER_RIG);
      const selectionList = [...userList, ...companyList];
      setSelectionList(selectionList);
    }
  }, [users.data, companies.data]);

  const handleCompanyInventory = (e) => {
    setCompanyInventory(e.target.value);
    dispatch(listPartsByCustomer(e.target.value._id));
  };

  const handleInventory = (currentViewState) => {
    let newInventoryViewState;
    switch (user.type) {
      case USER_TYPE.PREMIUM_ADMIN:
      case USER_TYPE.PREMIUM_MARKETPLACE_ADMIN:
        newInventoryViewState = (currentViewState + 1) % 3;
        break;
      default:
        newInventoryViewState = (currentViewState + 1) % 2;
        break;
    }
    dispatch({
      type: REDUX_ACTIONS.SET_MARKETPLACE_INVENTORY_VIEW,
      payload: { marketPlaceInventoryView: newInventoryViewState },
    });
  };

  const handleOpenUploadModal = () => {
    setUploadOpen(true);
  };

  const handleCloseUpdateModal = () => {
    setUploadOpen(false);
    setSelectedFile(null);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === 'text/csv') {
      setSelectedFile(file);
      setError(null);
    } else {
      setSelectedFile(null);
      setError('Only .csv files are allowed');
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    if (file && file.type === 'text/csv') {
      setSelectedFile(file);
      setError(null);
    } else {
      setSelectedFile(null);
      setError('Only .csv files are allowed');
    }
    e.dataTransfer.clearData();
  };

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const handleUploadSuccess = () => {
    setUploadOpen(false);
    setSelectedFile(null);
    setError(null);
    dispatch(
      enqueueSnackbar(
        'CSV uploaded successfully',
        'success',
        new Date().getTime() + Math.random()
      )
    );

    if (companyInventory) {
      dispatch(listPartsByCustomer(companyInventory._id));
    } else {
      dispatch(listParts());
    }
  };

  const handleUploadCsv = () => {
    if (!selectedFile) {
      setError('Select a csv file to upload');
      return;
    }
    dispatch(partsCsvUpload(selectedFile, companyInventory._id, setIsLoading, handleUploadSuccess));
  };

  return (
    <>
    <>
    {user.marketPlaceInventoryView === 2 && (<Grid>
      <Box sx={{ marginLeft: mobile ? 0 : 'auto' }} className={classes.container}>
      <FormControl
      fullWidth
      variant="filled"
      className={classes.inputField}
      sx={{ bgcolor: 'transparent' }}
    >
      <InputLabel id={'comapny-input-label'}>Select</InputLabel>
      <Select
        labelId={'companny-select-id'}
        id={'company-id'}
        value={companyInventory}
        onChange={handleCompanyInventory}
        label={'Select'}
        disabled={users.loading || companies.loading }
        sx={{
          backgroundColor: 'unset',
        }}
      >
        {selectionList?.map((item, idItem) => (
          <MenuItem key={`item_${item}_${idItem}`} value={item}>
            {item?.firstName || item?.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
      </Box>
      </Grid>)}
      </>
      {user.marketPlaceInventoryView === 2 && (
         <Box sx={{ ml: '-10px', mr: '10px' }}>
         <InputAdornment position="end">
           <IconButton
             disabled={!companyInventory._id}
             edge="end"
             onClick={() => {
               handleOpenUploadModal();
             }}
           >
             <WidgetsIcon />
           </IconButton>
         </InputAdornment>
        </Box>
      )}
      <Box sx={{ marginRight: '-5px' }}>
        <InputAdornment position="start">
          <IconButton
            edge="end"
            onClick={() => {
              handleInventory(user.marketPlaceInventoryView);
            }}
          >
            {user.marketPlaceInventoryView === 0 && <ListAltRounded/>}
            {user.marketPlaceInventoryView === 1 && <ViewCarouselRounded />}
            {user.marketPlaceInventoryView === 2 && <TableRowsOutlined/>}
          </IconButton>
        </InputAdornment>
      </Box>
      <Box sx={{ marginLeft: 'auto' }}>
        <InputAdornment position="end">
          <IconButton
            edge="end"
            onClick={() => {
              handleAspectRatio(!preferences.aspectChanged);
            }}
          >
            <AspectRatioRounded />
          </IconButton>
        </InputAdornment>
      </Box>
      <SyncMobileDevicesModal
        open={uploadOpen}
        onClose={handleCloseUpdateModal}
        handleClose={handleCloseUpdateModal}
        cta1Label={'Close'}
        cta1Action={handleCloseUpdateModal}
        cta2Label={'Upload'}
        cta2Action={handleUploadCsv}
        showCta2={true}
        showDateTimeRange={false}
        cta2Disabled={isLoading}
      >
        <CardMedia
          component="img"
          image={Premium}
          className={classes.logo}
        />
        <Typography variant="h4" gutterBottom component="div" style={{ textAlign: 'center' }}>
          {isLoading ? 'Uploading...' : 'Add or select media'}
        </Typography>
        <Box
          className={isDragging ? classes.dragDrop : classes.dropArea}
          ref={dropAreaRef}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <input
            type="file"
            onChange={handleFileChange}
            style={{ display: 'none' }}
            id="fileInput"
            ref={fileInputRef}
          />
          <label htmlFor="fileInput" style={{ cursor: 'pointer' }}>
            Drop files here to upload them or press button
            <br />
            <br />
            <IconButton
              edge="end"
              sx={{ marginRight: '1%' }}
              onClick={handleIconClick}
            >
              <FileOpen />
           </IconButton>
          </label>
        </Box>
        {error && <Box className={classes.errorMessage}>{error}</Box>}
        {selectedFile && <Box>Selected file: {selectedFile.name}</Box>}
        <p>
          Allowed file upload: <strong>one file only</strong>
          <br />
          File must be less than <strong>2 MB</strong>
          <br />
          Allowed file types: <strong>csv</strong>
        </p>
      </SyncMobileDevicesModal>
    </>
  );
};

export default InventoryView;
