import React, { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  Box,
  FormControl,
  InputLabel,
  FilledInput,
  styled,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../styles';
import { useNavigate } from 'react-router-dom';
import Premium from '../../../assets/images/PRO logo PMS green only.svg';
import {
  sendPasswordResetEmail,
  sendPasswordResetEmailReset,
} from './../../../store/actions/authorization';
import { isMobile } from '../../../utils/isMobile';
import ButtonComponent from '../../Button';

const ResetPasswordSendEmail = () => {
  const classes = styles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [emailAddress, setEmailAddress] = useState('');
  const [error, setError] = useState('');
  const { loading, resetEmailSuccess, resetEmailError, errorMessage } =
    useSelector((state) => state.currentUser);
  const SuccessTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.Authentication_Success.main,
  }));

  useEffect(() => {
    let timer;
    if (resetEmailSuccess || resetEmailError) {
      timer = setTimeout(() => {
        dispatch(sendPasswordResetEmailReset());
        navigate('/Login');
      }, 5000);
    }
    return () => clearTimeout(timer);
  }, [resetEmailSuccess, resetEmailError, navigate]);

  const handleSend = (event) => {
    event.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(emailAddress)) {
      setError('Please enter a valid email address');
      return;
    }
    setError('');
    dispatch(sendPasswordResetEmail(emailAddress));
  };

  return (
    <Grid
      container
      alignItems="center"
      textAlign="center"
      justifyContent="center"
      className={classes.mainContainer}
    >
      <Box component="form" onSubmit={handleSend} noValidate
       sx={{ height: isMobile() ? '100%' : 'none' }}
      >
        <Grid
          item
          container
          xs={isMobile() ? 12 : 8}
          margin="auto"
          justifyContent="center"
          className={isMobile() ? '' : classes.loginContainer}
          alignItems="center"
        >
          <Grid item container xs={12} justifyContent="center">
            <Box
              component="img"
              alt="Logo"
              src={Premium}
              className={classes.logo}
            />
          </Grid>
          <Grid item xs={12} padding={1} className={classes.formContainer}>
            <FormControl
              sx={{
                bgcolor: 'transparent',
              }}
              fullWidth
              variant="filled"
              onChange={(value) => {
                setEmailAddress(value.target.value);
              }}
            >
              <InputLabel htmlFor="outlined-adornment-email">
                Email Address
              </InputLabel>
              <FilledInput
                className={classes.emailInput}
                id="outlined-adornment-email"
                type="email"
                label="Email Address"
                autoFocus
                disableUnderline={true}
              />
            </FormControl>
            <Typography color="error">{error}</Typography>
            {resetEmailSuccess && (
              <SuccessTypography> Email sent successfully </SuccessTypography>
            )}
            {resetEmailError && (
              <Typography color="error"> {errorMessage}</Typography>
            )}
          </Grid>
          <Grid item xs={isMobile() ? 10 : 6} className={isMobile() ? classes.loginButton : ''}>
            <ButtonComponent
             label="Send"
              fullWidth
              className={classes.button}
              type="submit"
              disabled={loading || resetEmailSuccess || resetEmailError}
            >
            </ButtonComponent>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default ResetPasswordSendEmail;
