/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React from 'react';
import { Button, DialogActions, Grid, IconButton } from '@mui/material';
import { CancelPresentationRounded } from '@mui/icons-material';
import PropTypes from 'prop-types';
import styles from './style';
import ModalDialog from '../../ui/ModalDialog';
const DrillStringEditModal = ({
  handleClose,
  open,
  setShowBHADialog,
  setShowBITDialog,
}) => {
  const classes = styles();

  const getModalTitle = () => {
    return (
            <>
                <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                    {'Attention!'}
                </Grid>
                <Grid item>
                    <IconButton
                        onClick={handleClose}
                    >
                        <CancelPresentationRounded fontSize='large' />
                    </IconButton>
                </Grid>
                </Grid>
            </>
    );
  };

  return (
        <ModalDialog
            title={getModalTitle()}
            isOpen={open}
            onClose={handleClose}
            maxWidth='sm'
            dialogClass={classes.dialogModal}
        >
            <Grid container item justifyContent='center' margin='7% 0'>
                <DialogActions>
                    <Button
                        style={{ backgroundColor: '#444', marginRight: '30px', width: '100px' }}
                        onClick={setShowBHADialog}
                    >
                        Drillstring
                    </Button>
                    <Button
                        style={{ backgroundColor: '#444', marginLeft: '30px', width: '100px' }}
                        onClick={() => {
                          setShowBITDialog(true);
                        }}
                    >
                        Bit
                    </Button>
                </DialogActions>
            </Grid>
        </ModalDialog>
  );
};
DrillStringEditModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setShowBHADialog: PropTypes.func,
  setShowBITDialog: PropTypes.func,
};

export default DrillStringEditModal;
