import fetch from '../../../api/fetch';
import { REDUX_ACTIONS } from '../../../shared/config/constants';
import { parseError } from '../../../utils/fetchErrorParser';
import { enqueueSnackbar } from '../snackbar';

export const listEpicorPriceLists = (params) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.EPICOR_PRICE_LISTS_LIST_LOADING,
  });

  let body = {};
  if (params) {
    const filteredParams = Object.entries({
      top: params.topValue,
      company: 'POT',
      select: params.selectValue,
      expand: params.expandValue,
      filter: params.filterValue,
      orderBy: params.orderByValue,
      skip: params.skipValue,
    }).reduce((acc, [key, value]) => {
      if (typeof value === 'string') {
        value = value.trim();
      }
      if (value !== '' && value !== undefined) {
        acc[key] = value;
      }
      return acc;
    }, {});

    body = {
      type: 'GET',
      url: '/priceLists',
      params: filteredParams,
    };
  } else {
    body = {
      type: 'GET',
      url: '/priceLists',
      params: {
        company: 'POT'
      }
    };
  }

  return fetch
    .post('epicorProxy', body)
    .then((resp) => {
      if (!resp.ok) {
        return Promise.reject(resp);
      }
      return resp.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.EPICOR_PRICE_LISTS_LIST_SUCCESS,
        payload: {
          epicorPriceLists: data.data,
          paginatedElements: data.data.length,
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);

      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.EPICOR_PRICE_LISTS_LIST_ERROR,
        payload: { ...error, message: errorMessage },
      });
    });
};
