import { makeStyles } from '@mui/styles';

// Define keyframes for the shine animation
const shineKeyframes = {
  '0%': {
    backgroundPosition: 'left -40px top 0',
  },
  '100%': {
    backgroundPosition: 'right -40px top 0',
  },
};

const useStyles = makeStyles(() => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '360px',
    flexShrink: 0,
    flexGrow: 0,
    maxWidth: '100%',
    backgroundColor: '#FFF',
    boxShadow: '0 5px 10px 0 rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',
    overflow: 'hidden',
    margin: '1.25rem',
    maxHeight: '438px',
  },
  cardImg: {
    padding: '22px',
    paddingBottom: '88%',
    position: 'relative',
    '& img': {
      position: 'absolute',
      width: '100%',
    },
  },
  cardBody: {
    padding: '14px',
  },
  cardTitle: {
    fontSize: '1.25rem',
    lineHeight: '1.33',
    fontWeight: 700,
  },
  cardTitleSkeleton: {
    minHeight: '28px',
    borderRadius: '4px',
  },
  cardIntro: {
    marginTop: '0.75rem',
    lineHeight: '1.5',
  },
  cardIntroSkeleton: {
    minHeight: '72px',
    borderRadius: '4px',
  },
  skeleton: {
    backgroundColor: '#e2e5e7',
    backgroundImage: 'linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))',
    backgroundSize: '40px 100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left -40px top 0',
    animation: '$shine 1s ease infinite',
  },
  '@keyframes shine': shineKeyframes,
}));

export default useStyles;
