import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Datagrid from '../ui/Datagrid';
import { gridClasses } from '@mui/x-data-grid';
import columns from './table_config';
import { listAuditLogs } from '../../store/actions/auditLogs';
import { Grid, TablePagination } from '@mui/material';
import { renderCell } from '../ui/Datagrid/table_config';
import PropTypes from 'prop-types';
import ReactJson from 'react-json-view';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { REDUX_ACTIONS } from '../../shared/config/constants';

// eslint-disable-next-line react/prop-types
function CustomPagination({ currentPage, limit, totalCount, handlePagination, loading }) {
  return (
    <TablePagination
      component="div"
      count={totalCount}
      page={currentPage}
      onPageChange={(event, page) => !loading && handlePagination(page)}
      rowsPerPage={limit}
      rowsPerPageOptions={[]}
    />
  );
}

const AuditLog = () => {
  const auditLogs = useSelector((state) => state.auditLogs);

  const dispatch = useDispatch();
  const apiRef = useGridApiRef();
  const [filter, setFilter] = useState([]);

  const renderJsonField = ({ value }) => {
    return <ReactJson src={value} name={false} collapsed={true} sortKeys={true} />;
  };

  const handlePagination = (page) => {
    if (page < auditLogs.currentPage) {
      dispatch(listAuditLogs({ page: auditLogs.currentPage - 1 }));
      dispatch({
        type: REDUX_ACTIONS.AUDIT_LOGS_SET_CURRENT_PAGE,
        payload: auditLogs.currentPage - 1
      });
    } else {
      dispatch(listAuditLogs({ page: auditLogs.currentPage + 1 }));
      dispatch({
        type: REDUX_ACTIONS.AUDIT_LOGS_SET_CURRENT_PAGE,
        payload: page + 1
      });
    }
    apiRef.current.setFilterModel({
      items: filter
    });
    apiRef.current.scrollToIndexes({
      rowIndex: 0,
      colIndex: 0,
    });
  };

  useEffect(() => {
    if (filter.length === 0 && auditLogs.data.length) {
      dispatch(listAuditLogs());
    }
  }, [filter]);

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Datagrid
          apiRef={apiRef}
          data={auditLogs.data}
          columns={columns(renderCell, renderJsonField)}
          loading={auditLogs.loading}
          disableStickyHeader={false}
          height={'90vh'}
          getRowId={(row) => row.grid_id}
          getRowHeight={() => 'auto'}
          autoPageSize
          autoHeight={false}
          pagination={false}
          hideFooter
          experimentalFeatures={{ newEditingApi: true }}
          filterMode="server"
          onFilterModelChange={ (model) => {
            setFilter(model.items);
            const items = model.items.map(filter => {
              if (filter.columnField === 'userEmail' || filter.columnField === 'caller' || filter.columnField === 'payload') {
                return { ...filter, columnField: 'details.' + filter.columnField };
              } else {
                return filter;
              }
            });
            const filterModels = { ...model, items };
            dispatch({
              type: REDUX_ACTIONS.AUDIT_LOGS_SET_FILTER,
              payload: filterModels.items.length ? filterModels : {}
            });
          }}
          sortingMode="server"
          onSortModelChange={(sortModel) => {
            const sort = sortModel.reduce((result, item) => {
              if (item.field === 'userEmail' || item.field === 'payload' || item.field === 'response') {
                result['details.' + item.field] = item.sort;
              } else if (item.field === 'orderNumber') {
                result['details.response.value.SaleOrder.OrderNum'] = item.sort;
              } else {
                result[item.field] = item.sort;
              }
              return result;
            }, {});
            dispatch({
              type: REDUX_ACTIONS.AUDIT_LOGS_SET_SORT,
              payload: sort
            });
            dispatch({
              type: REDUX_ACTIONS.AUDIT_LOGS_SET_CURRENT_PAGE,
              payload: 1
            });
            dispatch(listAuditLogs());
          }}
          sx={{
            '.MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold !important',
            },
            [`& .${gridClasses.cell}`]: {
              py: 1,
            },
          }}
          toolbar={{
            options: {
              columns: true,
              filters: true,
              density: true,
              export: true,
            },
          }}
        />
        <CustomPagination
          currentPage={auditLogs.currentPage - 1}
          limit={auditLogs.limit}
          totalCount={auditLogs.totalCount}
          handlePagination={handlePagination}
          loading={auditLogs.loading}
        />
      </Grid>
    </Grid>
  );
};

AuditLog.propTypes = {
  row: PropTypes.object,
};

export default AuditLog;
