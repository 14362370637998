import { makeStyles } from '@mui/styles';

const styles = makeStyles(() => ({
  titleBox: { margin: '10px' },
  ordersDatagrid: {
    fontWeight: 'bold',
    backgroundColor: '#f0f0f0',
  },
  selectedOrderDatagrid: {
    fontWeight: 'bold',
    backgroundColor: '#f0f0f0',
  },
  dialogModal: {
    '& .MuiPaper-root': {
      '& .makeStyles-dialogTitle-21': {
        padding: '4px 8px !important',
      }
    },
    '& .MuiTypography-subtitle1': {
      fontSize: '1.3rem',
      fontWeight: 'bold',
    }
  },
  orderTitleBox: {
    display: 'flex',
    flexDirection: 'row',
    margin: '10px',
    alignItems: 'center',
  },
  printButtonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingRight: '10px'
  },
}));

export default styles;
