import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Datagrid from '../ui/Datagrid';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { gridColumnGenerator } from '../../utils/gridColumnGenerator';

const TrucksDetail = () => {
  const apiRef = useGridApiRef();
  const epicorBins = useSelector((state) => state.epicorBins);
  const [columnsPartDetails, setColumnsPartDetails] = useState([]);
  const renderCell = (params) => <>{params.value}</>;

  useEffect(() => {
    if (
      !epicorBins.loading &&
      epicorBins.parts.length > 0 &&
      columnsPartDetails.length === 0
    ) {
      let columnsList = [];

      columnsList = gridColumnGenerator([], epicorBins.parts, renderCell);

      const nColumnsList = columnsList.map((column) => {
        if (column.field === '_id' || column.field === 'grid_id') {
          column.hide = true;
        } else if (column.field === 'BasePrice') {
          column.headerName = 'UnitPrice';
          column.hide = false;
        } else {
          column.hide = false;
        }
        return column;
      });
      setColumnsPartDetails(nColumnsList);
      apiRef.current.updateColumns(nColumnsList);
    }
  }, [epicorBins.parts]);

  return (
    <Datagrid
      apiRef={apiRef}
      data={epicorBins.parts}
      columns={columnsPartDetails}
      loading={epicorBins.loading}
      disableStickyHeader={false}
      autoHeight={false}
      pageSize={100}
      height={'45vh'}
      hideFooter={false}
    />
  );
};

export default TrucksDetail;
