import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import styles from './styles';
import useAspectRatio from '../../../../hooks/useAspectRatio';
import { IconButton } from '@mui/material';
import { AspectRatioRounded } from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import { REDUX_ACTIONS } from '../../../../shared/config/constants';

const TruFlowControls = () => {
  const { preferences } = useSelector((state) => state.currentUser);
  const handleAspectRatio = useAspectRatio();
  const [value, setValue] = React.useState(0);
  const classes = styles();
  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const setTabOption = (tabSelected) => {
    dispatch({
      type: REDUX_ACTIONS.MENU_SET_TAB,
      payload: tabSelected,
    });
  };

  const tabOptions = [
    'Ops',
    'Ramps',
    'Control',
    '4-20mA',
    'Motor',
    'WITS',
    'Alarms',
  ];

  return (<>
    <Box sx={{ maxWidth: { xs: 320, sm: 480 } }}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        aria-label="scrollable controlls for truflow"
        className={classes.tabsMenu}
        classes={{ scrollButtons: classes.scrollButtons }}

      >
        {tabOptions.map((tab) => (
          <Tab
            key={`tab-${tab}`}
            className={classes.tabsMenuItem}
            label={tab}
            onClick={() => setTabOption(tab)}
          />
        ))}
      </Tabs>
    </Box>
    <Box>
      <IconButton
        edge="end"
        onClick={() => {
          handleAspectRatio(!preferences.aspectChanged);
        }}
      >
        <AspectRatioRounded />
      </IconButton>
    </Box>
    </>
  );
};

export default TruFlowControls;
