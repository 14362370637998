import { useSelector } from 'react-redux';

const columns = (renderCell, renderJsonField) => {
  const user = useSelector((state) => state.currentUser);

  return [
    {
      field: 'timestamp',
      headerName: 'Timestamp',
      width: 250,
      hide: false,
      renderCell,
      headerAlign: 'center',
    },
    {
      field: 'userEmail',
      headerName: 'Email',
      width: 300,
      renderCell,
      headerAlign: 'center',
    },
    {
      field: 'payload',
      headerName: 'Input',
      width: 300,
      renderCell: renderJsonField,
      headerAlign: 'center',
      align: 'left',
      flex: user.preferences.aspectChanged ? 1 : 'none'
    },
    {
      field: 'response',
      headerName: 'Response',
      width: 300,
      renderCell: renderJsonField,
      headerAlign: 'center',
      align: 'left',
      flex: user.preferences.aspectChanged ? 1 : 'none'
    },
  ];
};

export default columns;
