import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGridApiRef, gridFilteredSortedRowEntriesSelector } from '@mui/x-data-grid-pro';
import pinGreen from '../../../assets/images/pin_green.png';
import pinRed from '../../../assets/images/pin_red.png';
import pinAmber from '../../../assets/images/pin_amber.png';
import pinBlue from '../../../assets/images/pin.png';
import Datagrid from '../../ui/Datagrid';
import { masterColumn } from './table_config';
import { Grid } from '@mui/material';
import { renderCell } from '../../ui/Datagrid/table_config';
import { FIELDBOX_LASTCHANGED } from '../../../utils/constants';
import PropTypes from 'prop-types';
import { gridColumnGenerator } from '../../../utils/gridColumnGenerator';
import { MAP_TYPES, REDUX_ACTIONS } from '../../../shared/config/constants';
import { listRigs } from '../../../store/actions/rigs';
import Map from '../../map';
import { Icon } from 'leaflet';

const pins = {
  blue: pinBlue,
  green: pinGreen,
  red: pinRed,
  amber: pinAmber,
};

const EnverusRigs = () => {
  const dispatch = useDispatch();
  const apiRef = useGridApiRef();
  const rigs = useSelector((state) => state.rigs);
  const [columns, setColumns] = useState([]);
  const [rowId, setRowId] = useState(null);
  const [gmapRowId, setGmapRowId] = useState(null);
  const mapType = process.env.REACT_APP_ENVERUS_RIGS_PAGE_MAP;

  useEffect(() => {
    if (!rigs.loading && rigs.data.length === 0) {
      dispatch({
        type: REDUX_ACTIONS.RIGS_LIST_LOADING,
      });
      dispatch(listRigs());
    }
  }, []);

  useEffect(() => {
    if (!rigs.loading && rigs.data.length > 0) {
      const columnsList = gridColumnGenerator(
        masterColumn,
        rigs.data,
        renderCell
      );
      setColumns(columnsList);
      apiRef.current.updateColumns(columnsList);
    }
  }, [rigs.data]);

  const getPinColor = (status) => {
    switch (status) {
      case FIELDBOX_LASTCHANGED.STATUS_ONE:
        return 'green';
      case FIELDBOX_LASTCHANGED.STATUS_TWO:
        return 'amber';
      case FIELDBOX_LASTCHANGED.STATUS_THREE:
        return 'red';
      default:
        return 'red';
    }
  };

  const getGoogleMapPin = (status) => {
    const pinColor = getPinColor(status);
    const pinIcon = pinColor ? pins[pinColor] : pins.default;
    return {
      url: pinIcon,
      // eslint-disable-next-line no-undef
      scaledSize: new google.maps.Size(15, 25),
      // eslint-disable-next-line no-undef
      size: new google.maps.Size(15, 25)
    };
  };

  const getMapPin = (status) => {
    const pinColor = getPinColor(status);
    const pinIcon = pinColor ? pins[pinColor] : pins.default;

    return (
      pinIcon &&
      new Icon({
        iconUrl: pinIcon,
        iconSize: [15, 25],
        popupAnchor: [0, -5],
      })
    );
  };

  return (
    <Grid container justifyContent="center">
      <Map
        data={rigs}
        mapType={mapType}
        mapData={rigs.marker_data}
        markerData={rigs.marker_data}
        latitudeField={'RigLatitudeWGS84'}
        longitudeField={'RigLongitudeWGS84'}
        mapPinField={'lastChangedStatus'}
        popUpLabel={['RigName_Number']}
        getGoogleMapPin={getGoogleMapPin}
        getMapPin={getMapPin}
        gmapRowId={gmapRowId}
        rowId={rowId}
      />
      <Grid item xs={12}>
        <Datagrid
          apiRef={apiRef}
          data={rigs.data}
          columns={columns}
          onCol
          loading={rigs.loading}
          onRowClick={({ row: rig }) => {
            if (mapType === MAP_TYPES.GOOGLEMAP) {
              setGmapRowId(rig._id);
            } else {
              setRowId(rig);
            }
          }}
          experimentalFeatures={{ newEditingApi: true }}
          toolbar={{
            options: {
              columns: true,
              filters: true,
              export: false,
            },
          }}
          onFilterModelChange={() => {
            setTimeout(() => {
              const filterData = gridFilteredSortedRowEntriesSelector(apiRef.current.state, apiRef.current.instanceId).map(item => {
                return { ...item.model };
              });
              if (filterData) {
                dispatch({
                  type: REDUX_ACTIONS.RIGS_MARKER_FILTER,
                  payload: {
                    data: filterData,
                  }
                });
              }
            }, 300);
          }}
        />
      </Grid>
    </Grid>
  );
};

EnverusRigs.propTypes = {
  row: PropTypes.object,
};

export default EnverusRigs;
