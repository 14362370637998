import fetch from '../../api/fetch';
import { REDUX_ACTIONS } from '../../shared/config/constants';
import { parseError } from '../../utils/fetchErrorParser';
import { enqueueSnackbar } from './snackbar';
import store from '../store';

export const listAlarmHistory =
  (payload) =>
    (dispatch) => {
      const { alarmHistory } = store.getState();
      dispatch({
        type: REDUX_ACTIONS.ALARM_HISTORY_LIST_LOADING,
      });

      const body = {
        input: {
          startDate: payload?.startDate || alarmHistory?.startDate,
          endDate: payload?.endDate || alarmHistory?.endDate,
          searchValue: payload?.searchValue || alarmHistory?.searchValue,
          limit: 100,
          page: payload?.page || 1,
          filters: alarmHistory?.filters?.items || [],
          linkOperator: alarmHistory?.filters?.linkOperator || '',
          sort: alarmHistory?.sort || null
        }
      };

      const path = 'alarms/triggeredAlarms';
      return fetch
        .post(path, body)
        .then((resp) => {
          if (!resp.ok) {
            return Promise.reject(resp);
          }
          return resp.json();
        })
        .then((data) => {
          dispatch({
            type: REDUX_ACTIONS.ALARM_HISTORY_LIST_SUCCESS,
            payload: {
              alarmHistory: data.data.data,
              paginatedElements: data.data.count,
              totalCount: data.data.count
            },
          });
        })
        .catch(async (error) => {
          const errorMessage = await parseError(error);

          dispatch(
            enqueueSnackbar(
              errorMessage,
              'error',
              new Date().getTime() + Math.random()
            )
          );
          dispatch({
            type: REDUX_ACTIONS.ALARM_HISTORY_LIST_ERROR,
            payload: { ...error, message: errorMessage },
          });
        });
    };
