import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { REDUX_ACTIONS } from '../../shared/config/constants';
import Datagrid from '../ui/Datagrid';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { listEpicorReleases } from '../../store/actions/epicor/epicorReleases';
import { listEpicorOrderDetails } from '../../store/actions/epicor/epicorOrderDetails';
import { gridColumnGenerator } from '../../utils/gridColumnGenerator';
import { extractVisibleFields } from '../../utils/extractVisibleFields';
import { useSearchParams } from 'react-router-dom';
import OrderDetail from './OrderDetails';
import { masterColumn } from './table_config';

const FieldTickets = () => {
  const apiRef = useGridApiRef();
  const dispatch = useDispatch();
  const epicorReleases = useSelector((state) => state.epicorReleases);

  const [searchParams] = useSearchParams();
  const [columns, setColumns] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState(masterColumn);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [selectionModel, setSelectionModel] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const binNum = searchParams.get('BinNum');
  const OrderNum = searchParams.get('OrderNum');

  const renderCell = (params) => <>{params.value}</>;

  useEffect(() => {
    dispatch({
      type: REDUX_ACTIONS.EPICOR_SET_SELECTED_ROWS,
      payload: selectedRows,
    });
  }, [selectedRows]);

  useEffect(() => {
    dispatch({
      type: REDUX_ACTIONS.MENU_SET_TITLE,
      payload: binNum,
    });
    dispatch(listEpicorReleases({ binNumValue: binNum }));
    if (OrderNum) {
      dispatch(listEpicorOrderDetails({ orderNumValue: OrderNum }));
    }
  }, []);

  useEffect(() => {
    if (!epicorReleases.loading && epicorReleases.data.length > 0) {
      const columnsList = gridColumnGenerator(
        visibleColumns,
        epicorReleases.data,
        renderCell
      );
      setColumns(columnsList);
      apiRef.current.updateColumns(columnsList);
      if (!OrderNum) {
        dispatch(listEpicorOrderDetails({ orderNumValue: epicorReleases.data[0].OrderNum }));
      }
    }

    setSelectionModel(() => {
      const list = epicorReleases.data
        .filter((r) => {
          return r.OrderNum === parseInt(OrderNum ?? epicorReleases.data[0].OrderNum);
        })
        .map((r) => r._id);
      return list[0];
    });
  }, [epicorReleases.data]);

  const handleVisibiltyModel = (newModel) => {
    const currentColumns = extractVisibleFields(
      apiRef.current.getVisibleColumns()
    );
    setVisibleColumns(currentColumns);
    setColumnVisibilityModel(newModel);
  };

  const handleReleaseSelected = (orderNum) => {
    dispatch(listEpicorOrderDetails({ orderNumValue: orderNum }));
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Datagrid
          apiRef={apiRef}
          data={epicorReleases.data}
          columns={columns}
          loading={epicorReleases.loading}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) => {
            handleVisibiltyModel(newModel);
          }}
          onCol
          disableStickyHeader={false}
          autoHeight={false}
          pageSize={1000}
          height={'45vh'}
          hideFooter={false}
          onRowClick={({ row }) => {
            handleReleaseSelected(row.OrderNum);
          }}
          selectionModel={selectionModel}
          onSelectionModelChange={(e) => {
            setSelectionModel(e);
            const selectedIDs = new Set(e);
            const selectedRows = epicorReleases.data.filter((r) => {
              return selectedIDs.has(r._id);
            });
            setSelectedRows(selectedRows);
          }}
          toolbar={{
            options: {
              columns: true,
              filters: true,
              density: true,
              export: false,
            },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <OrderDetail />
      </Grid>
    </Grid>
  );
};

export default FieldTickets;
