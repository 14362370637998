import { makeStyles } from '@mui/styles';

const styles = makeStyles(({ spacing }) => {
  return {
    navMenuItem: {
      textDecoration: 'none',
      width: '220px',
      fontSize: '20px',
      color: '#000000',
      fontWeight: 'bold',
      padding: spacing(1),
    },
    menuItem: {
      padding: '0 !Important',
    }
  };
});

export default styles;
