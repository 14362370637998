import { makeStyles } from '@mui/styles';

const styles = makeStyles(({ spacing }) => {
  return {
    logo: {
      width: '30vh',
      border: 'none',
      display: 'flex',
      margin: '0 auto',
    },

    actionButton: {
      paddingLeft: `${spacing(1)} !important`,
      paddingRight: `${spacing(1)} !important`,
      minWidth: '100px !important',
    },
  };
});

export default styles;
