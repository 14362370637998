import { REDUX_ACTIONS } from '../../shared/config/constants';

const initialState = {
  loading: false,
};

const dataLoadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.DATA_LOADING:
      return {
        ...state,
        loading: true
      };
    case REDUX_ACTIONS.DATA_LOADING_ENDED:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

export default dataLoadingReducer;
