// src/hooks/useAspectRatio.js
import { useDispatch } from 'react-redux';
import { epicorSyncMobileDevices } from '../store/actions/epicor/epicorSyncMobileDevices';

const useSyncMobileDevices = () => {
  const dispatch = useDispatch();
  const handleSyncMobileDevices = () => {
    dispatch(epicorSyncMobileDevices());
  };

  return handleSyncMobileDevices;
};

export default useSyncMobileDevices;
