import { REDUX_ACTIONS } from '../../shared/config/constants';
import fetch from '../../api/fetch';
import { parseError } from '../../utils/fetchErrorParser';
import { enqueueSnackbar } from './snackbar';

export const createActuatorReportRequest = () => ({
  type: REDUX_ACTIONS.CREATE_ACTUATOR_REPORT_REQUEST,
});

export const createActuatorReportSuccess = (data) => ({
  type: REDUX_ACTIONS.CREATE_ACTUATOR_REPORT_SUCCESS,
  payload: data,
});

export const createActuatorReportFailure = (error) => ({
  type: REDUX_ACTIONS.CREATE_ACTUATOR_REPORT_FAILURE,
  payload: error,
});

export const createActuatorReport = (start, end, jobId) => (dispatch) => {
  dispatch(createActuatorReportRequest());

  const body = { start, end, jobId };
  return fetch
    .downloadPost('reports/actuatorData', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.blob();
    })
    .then((blob) => {
      // Create a download link for the PDF file
      const url = window.URL.createObjectURL(blob);
      let dateObj;
      dateObj = new Date(start);
      const numericStartDate = dateObj.getTime();
      dateObj = new Date(end);
      const numericEndDate = dateObj.getTime();
      const fileName = `actuator_report_${jobId}_${numericStartDate}${numericEndDate}.pdf`;

      // Create an anchor element and set the download attribute
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;

      // Append the link to the document, click it, and remove it
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      dispatch(createActuatorReportSuccess({ message: 'PDF downloaded successfully' }));
      dispatch(
        enqueueSnackbar(
          'File Downloaded Successfully.',
          'success',
          new Date().getTime() + Math.random()
        )
      );
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(createActuatorReportFailure(errorMessage));
      dispatch(
        enqueueSnackbar(
          'Error in downloading file',
          'error',
          new Date().getTime() + Math.random()
        )
      );
    });
};

export const createCustomActuatorReport = (body) => (dispatch) => {
  dispatch(createActuatorReportRequest());

  const { start, end, jobId, collection } = body;

  return fetch
    .downloadPost('reports/custom', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.blob();
    })
    .then((blob) => {
      // Create a download link for the PDF file
      const url = window.URL.createObjectURL(blob);
      let dateObj;
      dateObj = new Date(start);
      const numericStartDate = dateObj.getTime();
      dateObj = new Date(end);
      const numericEndDate = dateObj.getTime();
      const fileName = `${collection}_report_${jobId}_${numericStartDate}${numericEndDate}.pdf`;

      // Create an anchor element and set the download attribute
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;

      // Append the link to the document, click it, and remove it
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      dispatch(createActuatorReportSuccess({ message: 'PDF downloaded successfully' }));
      dispatch(
        enqueueSnackbar(
          'File Downloaded Successfully.',
          'success',
          new Date().getTime() + Math.random()
        )
      );
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(createActuatorReportFailure(errorMessage));
      dispatch(
        enqueueSnackbar(
          'Error in downloading file',
          'error',
          new Date().getTime() + Math.random()
        )
      );
    });
};
