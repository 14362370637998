import { useState } from 'react';
import { RestartAlt, Check, Close } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Tooltip,
  Popover,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import styles from './styles';

const CustomPopoover = (props) => {
  const classes = styles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [eventText, setEventText] = useState('');

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleOnTag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleOnChange = (e) => {
    const regex = /^-?[0-9\b]+$/;
    if (e.target.value === '' || regex.test(e.target.value)) {
      setEventText(e.target.value);
    }
  };

  const handleOnSubmit = () => {
    const event = eventText;
    props.onSubmit(event, props.tootipTitle);
    setAnchorEl(null);
    setEventText('');
  };

  const handleOnReset = () => {
    props.onReset(props.tootipTitle);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ marginLeft: 'auto' }}>
      <Tooltip title={props.tootipTitle} placement="top">
        <IconButton
          color="inherit"
          onClick={handleOnTag}
          style={{ padding: 0 }}
        >
          {props.icon}
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {props.isMobile && (
            <IconButton
              edge="end"
              onClick={handleClose}
              sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}
            >
              <Close />
            </IconButton>
        )}
        <Typography sx={{ p: props.isMobile ? 2 : 1 }}>
          <TextareaAutosize
            onChange={handleOnChange}
            aria-label="minimum height"
            minRows={1}
            maxLength={5}
            value={eventText}
            placeholder={`${props.tootipTitle}`}
            className={classes.customTextArea}
            style={props.isMobile ? { margin: 2 } : {}}
          />
          <IconButton
            edge="end"
            className={classes.actionButton}
            onClick={handleOnReset}
          >
            <RestartAlt />
          </IconButton>
          <IconButton
            edge="end"
            className={classes.actionButton}
            onClick={handleOnSubmit}
          >
            <Check />
          </IconButton>
          {!props.isMobile && (
            <IconButton
              edge="end"
              onClick={handleClose}
              className={classes.actionButton}
            >
              <Close />
            </IconButton>
          )}
        </Typography>
      </Popover>
    </Box>
  );
};

CustomPopoover.propTypes = {
  icon: PropTypes.object,
  tootipTitle: PropTypes.string,
  onSubmit: PropTypes.func,
  onReset: PropTypes.func,
  isMobile: PropTypes.bool
};

CustomPopoover.defaultProps = {
  isMobile: false
};

export default CustomPopoover;
