import store from '../store';
import fetch from '../../api/fetch';
import { REDUX_ACTIONS } from '../../shared/config/constants';
import { parseError } from '../../utils/fetchErrorParser';
import { enqueueSnackbar } from './snackbar';

export const listAssetTypes = () => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.ASSETTYPE_LIST_LOADING,
  });

  return fetch
    .post('asset-types/fetch')
    .then((resp) => {
      if (!resp.ok) {
        return Promise.reject(resp);
      }
      return resp.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.ASSETTYPE_LIST_SUCCESS,
        payload: {
          data: data.data,
          paginatedElements: data.data.length,
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);

      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.ASSETTYPE_LIST_ERROR,
        payload: { ...error, message: errorMessage },
      });
    });
};

export const addNewAssetType = () => (dispatch) => {
  const { assetTypes } = store.getState();
  const newAssetTypes = [...assetTypes.data];
  const nextId = newAssetTypes.length - 1;

  function generateRandom() {
    const length = 24;
    const charset = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let retVal = '';
    for (let i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  const newRow = {
    name: '',
    details: [],
    lastUpdate: new Date(),
    grid_id: nextId + 1,
    _id: generateRandom(),
    isNewRow: true,
  };
  newAssetTypes.unshift(newRow);

  dispatch({
    type: REDUX_ACTIONS.ASSETTYPE_LIST_SUCCESS,
    payload: {
      data: newAssetTypes,
      paginatedElements: newAssetTypes.length,
    },
  });
};

export const updateAssetTypeDetails = (data) => (dispatch) => {
  const { assetTypes } = store.getState();
  const currentAssetTypes = [...assetTypes.data];
  const index = currentAssetTypes.findIndex(assetType => assetType._id === data._id);
  currentAssetTypes[index] = data;

  dispatch({
    type: REDUX_ACTIONS.ASSETTYPE_LIST_SUCCESS,
    payload: {
      data: currentAssetTypes,
      paginatedElements: currentAssetTypes.length,
    },
  });
};

export const createAssetType = (body) => (dispatch) => {
  return fetch
    .post('asset-types/create', { input: body })
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Asset Type Created Successfully.',
          'success',
          new Date().getTime() + Math.random()
        )
      );
      dispatch(listAssetTypes());
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
    });
};

export const updateAssetType = (body, details) => (dispatch) => {
  return fetch
    .put('asset-types', { input: body })
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Asset Type Updated Successfully.',
          'success',
          new Date().getTime() + Math.random()
        )
      );
      if (!details) {
        dispatch(listAssetTypes());
      }
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
    });
};

export const deleteAssetType = (body, handleClose) => (dispatch) => {
  return fetch
    .deleteAction('asset-types', { input: body })
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(listAssetTypes());
      handleClose();
      dispatch(
        enqueueSnackbar(
          'Asset Type deleted',
          'success',
          new Date().getTime() + Math.random()
        )
      );
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      handleClose();
    });
};
