const columns = (renderCell, renderActions, renderTypeCell, renderValueCell) => [
  {
    field: 'type',
    headerName: 'Type',
    width: 250,
    renderEditCell: renderTypeCell,
    editable: true,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'top_md',
    headerName: 'Start MD (ft)',
    width: 150,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'shoe_md',
    headerName: 'End MD (ft)',
    width: 150,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'id',
    headerName: 'ID (in)',
    width: 150,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'actions',
    headerName: 'Actions',
    headerAlign: 'center',
    align: 'center',
    renderCell: renderActions,
  },
];
export default columns;
