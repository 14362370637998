import { makeStyles } from '@mui/styles';

const styles = makeStyles((theme) => ({
  main: {
    width: '100%',
  },
  carousel: {
    height: '100%',
    '& ul': {
      margin: 0,
    },
  },
  carouselContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: 0,
    paddingRight: 0,
    gap: theme.spacing(2),
  },
}));

export default styles;
