import * as React from 'react';
import { useSelector } from 'react-redux';
import styles from './styles';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Toolbar,
} from '@mui/material';

import ControlPanel from '../ControlPanel';
import Background from '../../../assets/images/diamond-cascade-white.png';
import { useLocation } from 'react-router-dom';

export default function MenuAppBar() {
  const classes = styles();
  const { pathname } = useLocation();
  const marketPlaceInventoryCarouselView = useSelector((state) => state.currentUser?.marketPlaceInventoryView);

  return (
    <>
      <Box sx={{ flexGrow: 1 }} className={marketPlaceInventoryCarouselView === 0 && pathname === '/Shop' && classes.fixedAppbar}>
        <AppBar elevation={0} position="static">
          <Toolbar
            style={{
              backgroundImage: `linear-gradient(to right, #ffffff, #cacaca), rgba(0, 0, 0, 0.5)), url(${Background})`,
              backgroundSize: 'cover',
            }}
            className={clsx(classes.toolBar)}
          >
            <ControlPanel />
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
}
