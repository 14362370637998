import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGridApiRef, gridFilteredSortedRowEntriesSelector } from '@mui/x-data-grid-pro';
import Datagrid from '../ui/Datagrid';
import columns from './table_config';
import { Box, Dialog, DialogActions, DialogTitle, Grid, IconButton, gridClasses } from '@mui/material';
import { renderCell } from '../ui/Datagrid/table_config';
import PropTypes from 'prop-types';
import { createAssetType, deleteAssetType, listAssetTypes, updateAssetType } from '../../store/actions/assetTypes';
import { SaveRounded, EditOutlined, DeleteRounded, WarningAmberRounded } from '@mui/icons-material';
import { REDUX_ACTIONS } from '../../shared/config/constants';
import ReactJson from 'react-json-view';
import AssetDetails from '../assetDetails';
import { enqueueSnackbar } from '../../store/actions/snackbar';
import ButtonComponent from '../Button';

const AssetTypes = () => {
  const dispatch = useDispatch();
  const apiRef = useGridApiRef();
  const [editDialog, setEditDialog] = useState(false);
  const [editData, setEditData] = useState({});
  const [confirmDelete, setConfirmDelete] = useState({
    open: false,
    data: null
  });
  const assetTypes = useSelector((state) => state.assetTypes);

  useEffect(() => {
    if (!assetTypes.loading) {
      dispatch(listAssetTypes());
    }
  }, []);

  useEffect(() => {
    if (assetTypes?.data[0]?.name === '') {
      const id = assetTypes?.data[0]._id;
      setTimeout(() => {
        apiRef.current.scrollToIndexes({
          rowIndex: 1
        });
        apiRef.current.startCellEditMode({ id, field: 'name' });
        setTimeout(() => {
          apiRef.current.setCellFocus(id, 'name');
        }, 50);
      }, 50);
    }
  }, [assetTypes.data.length]);

  const handleSave = (row) => {
    if (!row?.name) {
      dispatch(
        enqueueSnackbar(
          'Please enter asset type name',
          'error',
          new Date().getTime() + Math.random()
        )
      );
      return;
    }
    const body = {
      _id: row?._id,
      name: row?.name,
      details: row?.details
    };
    if (!row?.isNewRow) {
      dispatch(updateAssetType(body));
    } else {
      delete body._id;
      dispatch(createAssetType(body));
    }
  };

  const handleDelete = () => {
    dispatch(deleteAssetType({ _id: confirmDelete.data._id }, () => {
      setConfirmDelete({
        open: false,
        data: null
      });
    }));
  };

  const renderActionsCell = ({ row }) => {
    return (
      <Grid container justifyContent="center">
        <IconButton
          variant="solid"
          onClick={() => {
            setEditData(row);
            setEditDialog(true);
          }}
        >
          <EditOutlined />
        </IconButton>
        <IconButton
          variant="solid"
          onClick={() => {
            handleSave(row);
          }}
        >
          <SaveRounded />
        </IconButton>
        <IconButton
          disabled={row?.isNewRow}
          variant="solid"
          onClick={() => {
            setConfirmDelete({
              open: true,
              data: row
            });
          }}
        >
          <DeleteRounded />
        </IconButton>
      </Grid>
    );
  };

  const RenderJsonField = ({ value }) => {
    const data = useRef(value);

    return (
      <ReactJson
        src={data.current}
        name={false}
        sortKeys={true}
        collapsed={false}
        displayDataTypes={false}
        quotesOnKeys={false}
      />
    );
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Datagrid
          apiRef={apiRef}
          data={assetTypes.data}
          getRowId={(row) => row._id}
          columns={columns(renderCell, RenderJsonField, renderActionsCell)}
          loading={assetTypes.loading}
          experimentalFeatures={{ newEditingApi: true }}
          toolbar={{
            options: {
              columns: true,
              filters: true,
              export: false,
            },
          }}
          getRowHeight={() => 'auto'}
          sx={{
            '.MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold !important',
            },
            [`& .${gridClasses.cell}`]: {
              py: 1,
            },
          }}
          onFilterModelChange={() => {
            setTimeout(() => {
              const filterData = gridFilteredSortedRowEntriesSelector(apiRef.current.state, apiRef.current.instanceId).map(item => {
                return { ...item.model };
              });

              if (filterData) {
                dispatch({
                  type: REDUX_ACTIONS.ASSETTYPE_FILTER,
                  payload: {
                    data: filterData,
                  }
                });
              }
            }, 300);
          }}
        />
      </Grid>
      {editDialog && editData?.name
        ? (
        <AssetDetails
          open={editDialog}
          handleClose={() => {
            setEditDialog(false);
            setEditData({});
          }}
          data={editData}
        />
          )
        : null}
      {confirmDelete.open
        ? (
        <Dialog
          open={confirmDelete.open}
          onClose={() => {
            setConfirmDelete({
              open: false,
              data: null
            });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth='md'
        >
          <Box
            sx={{ display: 'flex', padding: '20px' }}
          >
            <DialogTitle id="alert-dialog-title" display='flex' alignItems='center' fontSize='20px' fontWeight='bold'>
              <WarningAmberRounded fontSize='large' sx={{ marginBottom: '4px', marginRight: '4px' }} />
              {`Delete ${confirmDelete?.data.name}?`}
            </DialogTitle>
            <DialogActions>
              <ButtonComponent
                label="Delete"
                size="small"
                onClick={() => {
                  setConfirmDelete({
                    open: false,
                    data: null
                  });
                }}></ButtonComponent>
              <ButtonComponent
              label="Confirm"
              size="small"
                onClick={() => {
                  handleDelete();
                }} autoFocus>
              </ButtonComponent>
            </DialogActions>
          </Box>
        </Dialog>
          )
        : null}
    </Grid>
  );
};

AssetTypes.propTypes = {
  row: PropTypes.object,
  value: PropTypes.object
};

export default AssetTypes;
