import { makeStyles } from '@mui/styles';

const styles = makeStyles((theme) => ({
  title: {
    textAlign: 'center',
    width: '600px',
  },
  logo: {
    width: '70%',
    margin: 'auto',
  },
  statusMessage: {
    textAlign: 'center',
  },
  searchBar: {
    backgroundColor: 'transparent',
    width: '25vh',
    marginRight: '1vh',
    '&.MuiFilledInput-root:hover': {
      backgroundColor: 'transparent',
    },
    '&.MuiInputBase-root :hover': {
      backgroundColor: 'transparent',
    },
    fontSize: 'larger',
  },
  dateTimePicker: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    alignSelf: 'flex-start',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

export default styles;
