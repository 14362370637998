import { makeStyles } from '@mui/styles';

const styles = makeStyles(() => {
  return {
    toolBar: {
      background: 'linear-gradient(to left, #ffffff, #cacaca)',
      fontSize: '12px',
    },
  };
});

export default styles;
