import { makeStyles } from '@mui/styles';
const styles = makeStyles(() => ({
  customBoxContainer: {
    minWidth: '545px',
    marginBottom: '10px',
    marginLeft: '1%'
  },
  customBoxImageContainer: {
    minWidth: '350px',
    marginTop: '0.5%',
    marginLeft: '1%'
  },
  customScrollContainer: {
    overflowX: 'hidden',
    display: 'flex',
    overflowY: 'hidden'
  }
}));

export default styles;
