import React, { useEffect, useRef, useState } from 'react';
import styles from './styles';
import {
  Box,
  IconButton,
} from '@mui/material';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import PropTypes from 'prop-types';
import Carousel from 'react-material-ui-carousel';
import JobsCustomCard from '../jobsCustomCard';
import JobsReportCustomCard from '../jobsCustomReportCard';

const JobsCarouselReportCard = ({ reportJobId, drillingDataSignals, actuatorDataSignals, reportEndDate, reportStartDate, handleReportPrintIconClick }) => {
  const classes = styles();
  const carouselRef = useRef(null);

  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [isPrevDisabled, setIsPrevDisabled] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (carouselRef.current) {
        const container = carouselRef.current;
        setIsNextDisabled(container.scrollLeft + container.clientWidth >= container.scrollWidth);
        setIsPrevDisabled(container.scrollLeft === 0);
      }
    };
    carouselRef.current?.addEventListener('scroll', handleScroll);
    return () => {
      carouselRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleNextClick = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({
        left: carouselRef.current.clientWidth,
        behavior: 'smooth'
      });
    }
  };

  const handlePrevClick = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({
        left: -carouselRef.current.clientWidth,
        behavior: 'smooth'
      });
    }
  };

  return (
        <Carousel
            slidesToShow={3}
            NextIcon={<NavigateNext />}
            PrevIcon={<NavigateBefore />}
            autoPlay={false}
            index={0}
            fullHeightHover={false}
            selectedItemId={0}
            indicators={false}
            navButtonsAlwaysInvisible={false}
            NavButton={({ style, next, prev }) => {
              return (
                    <IconButton disableRipple
                    onClick={next ? handleNextClick : handlePrevClick}
                    className={next ? 'carousel-next' : 'carousel-prev'} style={{
                      ...style,
                      opacity: (next && isNextDisabled) || (prev && isPrevDisabled) ? 0.5 : 1 // Decrease opacity if button is disabled
                    }}
                      disabled={(next && isNextDisabled) || (prev && isPrevDisabled)} >
                        {next && <NavigateNext sx={{ fontSize: '50px' }} color='#000' />}
                        {prev && <NavigateBefore sx={{ fontSize: '50px' }} color='#000' />}
                    </IconButton>
              );
            }}
            navButtonsProps={{
              style: {
                backgroundColor: 'transparent',
                borderRadius: '50%',
                padding: '15px',
                color: '#000',
                fontSize: '50px'
              }
            }}
            sx={{
              overflow: 'unset',
              marginTop: '2%',
              '& .carousel-prev': {
                left: '-58px',
              },
              '& .carousel-next': {
                right: '-58px',
              },

            }}
        >
            <Box key={'custom-modal'} className={classes.customScrollContainer} ref={carouselRef}>
                <Box key={'filter_card_box_0'} className={classes.customBoxImageContainer}>
                                <JobsCustomCard
                                    reportEndDate={reportEndDate}
                                    reportStartDate={reportStartDate}
                                    key={'custom_card_0'}
                                    getReports={handleReportPrintIconClick}
                                    header={'MPD Utilization'}
                                    itemId={0}
                                    fieldName={'Specify date range and click on the Report button'}
                                />
                </Box>
                <Box key={'filter_card_box_1'} className={classes.customBoxImageContainer}>
                                <JobsCustomCard
                                    reportEndDate={reportEndDate}
                                    reportStartDate={reportStartDate}
                                    key={'custom_card_1'}
                                    header={'RCD (Bearing Assy)'}
                                    getReports={handleReportPrintIconClick}
                                    itemId={1}
                                    disablePrint
                                    fieldName={'Specify date range when bearing assembly was installed and removed then click on the Report button'}
                                />
                            </Box>
                <Box key={'actuator-data'} className={classes.customBoxContainer}>
                    <JobsReportCustomCard
                        title={'Custom - Actuator Data'}
                        jobId={reportJobId}
                        signals={actuatorDataSignals}
                        reportStartDate={reportStartDate}
                        reportEndDate={reportEndDate}
                        collection={'ActuatorData'}
                    />
                </Box>
                <Box key={'drilling-data'} className={classes.customBoxContainer}>
                    <JobsReportCustomCard
                        title={'Custom - Drilling Data'}
                        jobId={reportJobId}
                        signals={drillingDataSignals}
                        reportStartDate={reportStartDate}
                        reportEndDate={reportEndDate}
                        collection={'DrillingData'}
                    />
                </Box>
            </Box>
        </Carousel>
  );
};

JobsCarouselReportCard.propTypes = {
  reportJobId: PropTypes.string,
  drillingDataSignals: PropTypes.array,
  actuatorDataSignals: PropTypes.array,
  reportStartDate: PropTypes.any,
  reportEndDate: PropTypes.any,
  collection: PropTypes.string,
  handleReportPrintIconClick: PropTypes.func
};

export default JobsCarouselReportCard;
