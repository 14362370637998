import React from 'react';
import PropTypes from 'prop-types';
import ModalDialog from '../ModalDialog';
import { Box, Grid, Typography, Slider, SliderThumb } from '@mui/material';
import styles from './styles';
import styled from '@emotion/styled';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ButtonComponent from '../../Button';

const CustomSlider = styled(Slider)(() => ({
  '& .MuiSlider-thumb': {
    boxShadow: 'none !important',
    background: 'none',
    height: '0px',
    width: '0px',
  },
  '& .MuiSlider-track': {
    height: 3,
  },
}));

function SliderThumbComponent(props) {
  const { children, ...other } = props;
  return (
      <SliderThumb {...other}>
        {children}
        <ArrowRightIcon style={{ fontSize: '100px' }} />
      </SliderThumb>
  );
}
SliderThumbComponent.propTypes = {
  children: PropTypes.node,
};

const SliderOrderConfirmModalDialog = ({
  isOpen,
  setSliderModal,
  placeOrder,
  title,
}) => {
  const classes = styles();
  const [sliderValue, setSliderVaue] = React.useState(0);
  const [orderPlaced, setOrderPlaced] = React.useState(false);
  const [isPast85, setIsPast85] = React.useState(false);
  const [buttonAction, setButtonAction] = React.useState(false);

  React.useEffect(() => {
    if (isPast85) {
      setButtonAction(true);
    }
  }, [isPast85]);
  const handleSliderValue = (e) => {
    if (e.type === 'mousedown') {
      return;
    }
    if (e.target.value < 85 && isPast85) {
      return;
    }
    if (!isPast85 && e.target.value >= 85) {
      setIsPast85(true);
    }
    if (e.target.value >= 85 && !orderPlaced) {
      setOrderPlaced(true);
      placeOrder();
    }
    setSliderVaue(e.target.value);
  };

  return (
        <ModalDialog
            isOpen={isOpen}
            isFullScreen={false}
            dialogClass={classes.dialogModal}>
          <Grid item xs={12} container className={classes.contentContainer}>
            <Grid style={{ marginTop: '5%' }} item xs={12} container justifyContent="center" alignItems="center">
              <Box style={{}} textAlign="center">
                <Typography variant="h6" style={{ fontSize: '35px', fontWeight: 500 }}>
                    {`Slide to ${title}`}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} container className={classes.sliderContainer}>
                <Grid item xs={10} >
                <CustomSlider
                      slots={{ thumb: SliderThumbComponent }}
                      value={sliderValue} disabled={sliderValue === 100} valueLabelDisplay="off" aria-label="Default" onChange={handleSliderValue}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: '0.7%' }}>
                <hr />
                    <Box textAlign="center">
                        <ButtonComponent
                            label='cancel'
                            autoFocus
                            variant={'contained'}
                            size={'large'}
                            disabled={buttonAction}
                            onClick={() => {
                              setSliderModal(false);
                            }}
                        />
                    </Box>
            </Grid>
            </Grid>
        </ModalDialog>
  );
};

SliderOrderConfirmModalDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string,
  setSliderModal: PropTypes.func,
  placeOrder: PropTypes.func,
};

export default SliderOrderConfirmModalDialog;
