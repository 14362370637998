import { makeStyles } from '@mui/styles';

const styles = makeStyles(({ spacing }) => ({
  dialogTitle: {
    lineHeight: '2 !important',
    padding: `${spacing(2)} ${spacing(3)} !important`,
  },

  closeButton: {
    position: 'relative',
    float: 'right',
  },

  actionButton: {
    paddingLeft: `${spacing(1)} !important`,
    paddingRight: `${spacing(1)} !important`,
    minWidth: '100px !important',
  },

  iconButton: {
    backgroundColor: '#fff !important',
    color: '#212121 !important',
    padding: 0,
  },

  dialogModal: {
    maxWidth: '80%',
    margin: '0 auto',
  },

  dialogContent: {
    padding: '0 !important',
    borderBottom: 'none !important',
  },
  inputField: {
    width: '100%',
  },
  requiredMessage: {
    color: '#ff0000',
  },
}));

export default styles;
