import React from 'react';
import PropTypes from 'prop-types';
import ModalDialog from '../ModalDialog';
import Datagrid from '../Datagrid';
import { Grid } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import columns from './table_config';
import { isFullArray } from '../../../utils/validators';

const JobStatusModalDialog = ({
  isOpen,
  onCloseClick,
  selectedJob,
}) => {
  const apiRef = useGridApiRef();
  const systemStatusDetails = selectedJob?.systemStatusDetails || {};

  const data = (() => {
    const { faults = [], warnings = [] } = systemStatusDetails;

    const faultsRows = faults.map((f, idx) => ({
      _id: `fault_${idx}`,
      status: 'Fault',
      message: f
    }));

    const warningRows = warnings.map((w, idx) => ({
      _id: `warning_${idx}`,
      status: 'Warning',
      message: w
    }));

    return [...faultsRows, ...warningRows];
  })();

  const createDialogButtons = () => ([
    {
      text: 'Close',
      placeholder: 'Close',
      'aria-label': 'Close',
      onClick: onCloseClick,
    },
  ]);

  return isFullArray(data) && (
    <ModalDialog
        title={'Alerts'}
        isOpen={isOpen}
        onClose={onCloseClick}
        buttons={createDialogButtons()}
      >
      <Grid item xs={12}>
        <Datagrid
          data={data}
          columns={columns}
          apiRef={apiRef}
        />
      </Grid>
    </ModalDialog>
  );
};

JobStatusModalDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  selectedJob: PropTypes.object,
  onCloseClick: PropTypes.func.isRequired,
};

JobStatusModalDialog.defaultProps = {
  selectedJob: {},
};

export default JobStatusModalDialog;
