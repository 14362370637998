import { AspectRatioRounded, DirectionsRunRounded, PieChartOutlineRounded } from '@mui/icons-material';
import { Box, FilledInput, FormControl, IconButton, InputLabel, TextField, Grid, Typography, Select, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import useAspectRatio from '../../../../hooks/useAspectRatio';
import { useEffect, useState } from 'react';
import styles from './styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { REDUX_ACTIONS } from '../../../../shared/config/constants';
import { listCheckIns } from '../../../../store/actions/checkins';
import { CALENDER_PROPS, DIVISIONS } from '../../../../utils/constants';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { useLocation } from 'react-router-dom';

const checkInsKPIUsers = ['PREMIUM_FIELD_TICKETING_ADMIN', 'PREMIUM_ADMIN', 'PREMIUM_FIELD_TICKETING_MANAGER'];

const CheckInsControls = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const handleAspectRatio = useAspectRatio();
  const user = useSelector((state) => state.currentUser);
  const { preferences } = useSelector((state) => state.currentUser);
  const classes = styles();
  const [selectedStartDateTime, setSelectedStartDateTime] = useState(null);
  const [selectedEndDateTime, setSelectedEnDateTime] = useState(null);
  const [search, setSearch] = useState(null);
  const [showDateTimePicker, setShowDateTimePicker] = useState(false);
  const [division, setDivision] = useState(DIVISIONS[0]);

  const handleSearchTerm = (e) => {
    setSearch(e.target.value);
  };

  const handleStartTime = (date) => {
    setSelectedStartDateTime(date);
  };

  const handleEndTime = (date) => {
    setSelectedEnDateTime(date);
  };

  const handleChange = (e) => {
    setDivision(e.target.value);
  };

  const handleSearch = () => {
    const start = showDateTimePicker ? selectedStartDateTime : null;
    const end = showDateTimePicker ? selectedEndDateTime : null;
    dispatch({
      type: REDUX_ACTIONS.CHECKINS_SEARCH_CONTROL,
      payload: {
        searchKey: search,
        startDate: start,
        endDate: end,
        division
      },
    });
    dispatch({
      type: REDUX_ACTIONS.CHECKINS_SET_CURRENT_PAGE,
      payload: 1
    });
    dispatch(
      listCheckIns({
        search,
        start,
        end,
        division
      }
      )
    );
  };
  useEffect(() => {
    setSelectedStartDateTime(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000));
    setSelectedEnDateTime(new Date());
  }, [location.pathname]);

  return (
    <Grid alignItems="flex-end" justifyContent="flex-end" sx={{ marginLeft: 'auto' }}>
      <Box className={classes.container}>
        <Box className={classes.divisionContainer}>
          <Typography variant='body2' className={classes.divisionText}>Division</Typography>
          <FormControl variant='outlined' sx={{ minWidth: '180px' }}>
            <Select
              labelId="simple-select-standard-label"
              id="simple-select-standard"
              value={division}
              onChange={handleChange}
              size='small'
              sx={{
                '& .MuiSelect-select': {
                  padding: '14px',
                },
              }}
            >
              {DIVISIONS?.sort().map((item) => (
                <MenuItem
                  key={`item_${item}`}
                  value={item}
                >
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box>
        <FormControl>
          <InputLabel
            htmlFor="outlined-adornment-search"
            inputprops={{ classes: { root: classes.searchBar } }}
          >
            Search
          </InputLabel>
          <FilledInput
            className={classes.searchBar}
            id="outlined-adornment-search"
            type="text"
            label="Search"
            value={search || ''}
            onChange={handleSearchTerm}
          />
        </FormControl>
        </Box>
        {showDateTimePicker
          ? (
        <Box className={classes.dateContainer}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            {...CALENDER_PROPS}
            disableFuture
            label="Start Date"
            value={selectedStartDateTime}
            onChange={handleStartTime}
            className={classes.dateTimePicker}
            classes={{
              switchHeader: classes.datePickerHeader,
            }}
            textField={(params) => <TextField {...params} />}
          />
          <DateTimePicker
            label="End Date"
            {...CALENDER_PROPS}
            value={selectedEndDateTime}
            onChange={handleEndTime}
            className={classes.dateTimePicker}
            format="MM/dd/yyyy hh:mm:ss a"
            disabled={!selectedStartDateTime}
            minDateTime={selectedStartDateTime}
            disableFuture
            textField={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        </Box>
            )
          : null}
        <Box className={classes.runActionContainer}>
          <IconButton
            size="large"
            onClick={() => setShowDateTimePicker(!showDateTimePicker)}
          >
            <DateRangeIcon />
          </IconButton>
        </Box>
        <Box className={classes.runActionContainer}>
          <IconButton
            disabled={showDateTimePicker && (!selectedStartDateTime || !selectedEndDateTime)}
            size="large"
            onClick={handleSearch}
          >
            <DirectionsRunRounded />
          </IconButton>
        </Box>
        <Box sx={{ marginLeft: !checkInsKPIUsers.includes(user.type) ? '13px' : 0 }} className={classes.piechartContainer}>
        <IconButton
            disabled={selectedEndDateTime < selectedStartDateTime}
            sx={{ display: !checkInsKPIUsers.includes(user.type) ? 'none' : '' }}
            size="large"
            aria-label="orders"
            onClick={() => {
              window.open(
                `/CheckIns/KPIs?startDate=${selectedStartDateTime.toISOString()}&endDate=${selectedEndDateTime.toISOString()}`,
                '_blank'
              );
            }}
          >
            <PieChartOutlineRounded />
          </IconButton>
        </Box>
        <Box className={classes.aspectRatioContainer}>
        <IconButton
          edge="end"
          onClick={() => {
            handleAspectRatio(!preferences.aspectChanged);
          }}
        >
          <AspectRatioRounded />
        </IconButton>
        </Box>
      </Box>
    </Grid>
  );
};

export default CheckInsControls;
