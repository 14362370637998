import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import Premium from '../../../assets/images/PRO logo PMS green only.svg';
import PremiumCerts from '../../../assets/images/premium-certs.webp';
import styles from './styles';

// social icons
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import { PREMIUM_SOCIALS } from '../../../utils/constants';

function Footer() {
  const classes = styles();

  const socials = [
    {
      icon: <LinkedInIcon />,
      link: PREMIUM_SOCIALS.linkedin
    },
    {
      icon: <YouTubeIcon />,
      link: PREMIUM_SOCIALS.youtube
    },
    {
      icon: <InstagramIcon />,
      link: PREMIUM_SOCIALS.instagram
    },
    {
      icon: <FacebookIcon />,
      link: PREMIUM_SOCIALS.facebook
    }
  ];

  return (
    <Box className={classes.footerWrapper}>
      <Grid container className={classes.sectionContainer}>
          <Grid className={classes.sectionItem} item xs={12} sm={4} md={4} lg={4}>
              <Box
                component="img"
                alt="Logo"
                src={Premium}
                className={classes.logo}
                sx={{ ml: '-10px', mt: '-10px' }}
              />
              <Typography className={classes.abouttext}>
                Since 2003, Premium Oilfield Technologies has been dedicated to the development, testing, and enhancement of mud pump components, top drives, mud valves, RCD/MPD systems, shaker screens, and other high-performance drilling products. Our solutions are specifically engineered to reduce downtime, enhance safety, and deliver exceptional performance even in the most challenging drilling environments.
              </Typography>
          </Grid>
          <Grid className={classes.sectionItem} item xs={12} sm={3} md={3} lg={3} >
              <Typography className={classes.text} sx={{ fontWeight: 'bold', mb: 2 }}>
                CORPORATE HEADQUARTERS
              </Typography>
              <Typography className={classes.text} sx={{ mb: 2 }}>
                10600 W Sam Houston Pkwy N, Houston, TX 77064
              </Typography>
              <Typography className={classes.text}>
                Tel: 281.679.6500
              </Typography>
              <Typography className={classes.text}>
                Fax: 281.679.6576
              </Typography>
              <Typography className={classes.text}>
              Email: <a style={{ color: '#005499', textDecoration: 'none' }} href="mailto:sales@premiumoilfield.com">sales@premiumoilfield.com</a>
              </Typography>
              <Grid className={classes.socialsContainer} container sx={{ mt: 4 }}>
                {socials.map((item) => {
                  return (
                    <a key={item.link} target='_blank' rel="noreferrer" href={item.link} style={{ textDecoration: 'none' }}>
                      <Box className={classes.socialIcon}>
                        {item.icon}
                      </Box>
                    </a>
                  );
                })}
              </Grid>
          </Grid>
          <Grid className={classes.sectionItem} item xs={12} sm={3.5} md={3.5} lg={3.5} >
              <Typography className={classes.text} sx={{ fontWeight: 'bold', mb: 2 }}>
                CERTIFICATIONS
              </Typography>
              <Typography className={classes.text} sx={{ mb: 2 }}>
                Premium Oilfield Technologies<br />
                Q1-3977<br />
                ISO 9001:2015 Certificate Number: 5028<br />
              </Typography>
              <Typography className={classes.text} sx={{ mb: 2 }}>
                GDS International, LLC
                DBA Premium Drilling Equipment and Support<br />
                API 8C-0378<br />
                API Q1 Certificate number Q1-5307<br />
                ISO 9001:2015 Registration Number: 5296<br />
              </Typography>
              <Typography className={classes.text}>
                PMR Mud Riggers in India<br />
                API Q1- Certificate/License Number Q1-4453<br />
              </Typography>
          </Grid>
          <Grid className={classes.sectionItem} item xs={12} sm={1.5} md={1.5} lg={1.5} >
            <Box
                component="img"
                alt="Logo"
                src={PremiumCerts}
                className={classes.certlogo}
                sx={{ filter: 'invert(1)' }}
              />
          </Grid>
      </Grid>
      <Grid sx={{ backgroundColor: '#58595b', color: '#FFF', p: '30px', mt: 5 }}>
        <Typography className={classes.text} sx={{ textAlign: 'justify', maxWidth: '1280px', margin: '0 auto' }}>
          The Premium Oilfield Technologies logo and the Caliber logo are registered trademarks of Premium Oilfield Technologies, LLC.
          Gardner Denver, Lewco, NOV, Oilwell, National, Varco, Woolley, Baash-Ross, Demco, Bomco, Oteco, Brown & Sharpe, Ideco, P-Quip,
          and Continental Emsco, and the product models referenced on this website are trademarks or registered trademarks of their respective companies.
          Premium Oilfield Technologies is not authorized by or affiliated with any of these companies, and no business relationship, affiliation,
          or endorsement is claimed or implied.
        </Typography>
      </Grid>
      <Grid sx={{ backgroundColor: '#F0F0F0' }}>
        <Grid className={classes.termsContainer}>
          <Typography className={classes.text}>
            <span>&#169;</span> 2024 PREMIUM OILFIELD TECHNOLOGIES | ALL RIGHTS RESERVED.
          </Typography>
          <a style={{ textDecoration: 'none', color: '#000' }} target='_blank' rel="noreferrer" href={'https://premiumoilfield.com/terms-conditions'}>
          <Typography className={classes.text}>
              TERMS AND CONDITIONS
          </Typography>
          </a>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Footer;
