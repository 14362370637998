import { Typography, Grid, Box, List, ListItem, Divider } from '@mui/material';
import styles from './styles';
import Premium from '../../assets/images/PRO logo PMS green only.svg';

export default function PrivacyPolicy() {
  const classes = styles();
  return (
    <Grid container justifyContent="center">
      <Grid item container xs={12} justifyContent="center">
        <Box
          component="img"
          alt="Logo"
          src={Premium}
          className={classes.logo}
        />
        <Divider className={classes.divider}/>
      </Grid>
      <Grid item container xs={12} justifyContent="center" flex flexDirection={'column'}>
        <Typography variant="body1" className={classes.text}>
          Your privacy is important to us. Premium Oilfield Technologies believes it is important to disclose how we collect, store, share and use information which directly or indirectly identifies our clients (e.g., “Personal Data”).
        </Typography>
        <Typography variant="body1" className={classes.text}>
          We collect the following Personal Data:
        </Typography>
        <List className={classes.list} disablePadding>
          <ListItem disablePadding className={classes.listItem}>Name</ListItem>
          <ListItem disablePadding className={classes.listItem}>Address</ListItem>
          <ListItem disablePadding className={classes.listItem}>Telephone Number</ListItem>
          <ListItem disablePadding className={classes.listItem}>Email Address</ListItem>
        </List>
        <Typography variant="body1" className={classes.text}>
          We do not disclose any Personal Data about our current or former clients to any third parties, except as required by law.
        </Typography>
        <Typography variant="body1" className={classes.text}>
          We restrict access to your Personal Data, except to our employees who need such information in order to provide products and services to you.
        </Typography>
        <Typography variant="body1" className={classes.text}>
          We maintain physical, electronic and procedural safeguards to protect your Personal Data.
        </Typography>
        <Typography variant="body1" className={classes.text}>
          If you have any questions about our privacy policy, please contact us.
        </Typography>
      </Grid>
    </Grid>
  );
}
