import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import {
  Card,
  CardActions,
  CardHeader,
  CardMedia,
  Grid,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/styles';
import image014 from '../../assets/images/placeholder.png';
import drillingBackground from '../../assets/images/drilling_performance_background.png';
import mpdBackground from '../../assets/images/mpd_background.png';

const images = [image014, mpdBackground, drillingBackground];

const FilterCarouselItem = ({ item, onClick }) => {
  const classes = styles();
  const theme = useTheme();
  const [isDescriptionHidden, setDescriptionHidden] = React.useState(true);
  let placeholderImage = '';
  if (item.header === 'MPD') {
    placeholderImage = images.find((element) => element.includes('mpd'));
  } else if (item.header === 'Drilling Performance') {
    placeholderImage = images.find((element) => element.includes('drilling'));
  } else {
    placeholderImage = images.find((element) =>
      element.includes('placeholder'));
  }

  const handleCardItemClick = () => {
    onClick && onClick(item.id);
  };

  return (
    <Card
      component="li"
      onClick={handleCardItemClick}
      sx={{ maxWidth: 345, flexGrow: 1 }}
      className={classes.card}
      key={`filter_card_${item.id}`}
      onMouseEnter={() => setDescriptionHidden(() => false)}
      onMouseLeave={() => setDescriptionHidden(() => true)}
    >
      <CardHeader
        title={item.header}
        titleTypographyProps={{ variant: 'h6' }}
      />
      <CardMedia
        component="img"
        width="90px"
        height="350px"
        image={placeholderImage}
        className={classes.media}
        sx={{ objectFit: 'contain', p: theme.spacing(2) }}
      />
      <Grid className={classes.overlay} hidden={isDescriptionHidden}>
        {item.fields.map((field, i) => (
          <Typography key={`field_${i}`} variant="h6">{field.fieldName}</Typography>
        ))}
      </Grid>
      <CardActions className={classes.footer} />
    </Card>
  );
};

FilterCarouselItem.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func,
};

export default FilterCarouselItem;
