import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { MenuItem, Collapse } from '@mui/material';
import styles from './styles';

export const NavSubMenuItems = (props) => {
  const classes = styles();
  const { parentLabel, subMenu, index, selectedIndex, handleCloseNav, category } = props;
  return (
    <Collapse
      in={index === selectedIndex}
      timeout="auto"
    >
      {subMenu.map((item) => (
        <Link
          key={`${parentLabel}_submenu_link_${item.label}`}
          to={item.link}
          onClick={handleCloseNav}
          className={classes.subMenuItem}
        >
          <MenuItem
            key={`${parentLabel}_submenu_item_${item.label}`}
            className={classes.subMenuItemText}
            sx={{ color: category && category === item.label ? 'rgb(0 86 153) !important' : '#000' }}
          >
            {item.label}
          </MenuItem>
        </Link>
      ))}
    </Collapse>
  );
};

NavSubMenuItems.propTypes = {
  parentLabel: PropTypes.string.isRequired,
  subMenu: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  selectedIndex: PropTypes.number,
  handleCloseNav: PropTypes.func.isRequired,
  category: PropTypes.string
};
