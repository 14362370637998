import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import styles from './styles';

const SelectEditInputTextField = (props) => {
  const { id, value = '', field, updateData, numberFormatOnly } = props;
  const apiRef = useGridApiContext();
  const classes = styles();

  const handleChange = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    const newValue = event.target.value;
    const isValidNumber = /^-?\d*\.?\d*$/.test(newValue);
    if (!numberFormatOnly || isValidNumber) {
      await apiRef.current.setEditCellValue({ id, field, value: newValue });
      updateData(id, field, newValue);
    }
  };

  return (
     <TextField
      id={`signal-textfield-${id}`}
      variant="filled"
      value={value}
      onChange={handleChange}
      fullWidth
      className={classes.inputField}
      InputProps={{
        disableUnderline: true,
      }}
    />
  );
};

SelectEditInputTextField.propTypes = {
  field: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  value: PropTypes.any,
  updateData: PropTypes.func,
  closeCellAfterSelect: PropTypes.any,
  numberFormatOnly: PropTypes.any
};

export default SelectEditInputTextField;
