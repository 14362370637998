import { makeStyles } from '@mui/styles';

const styles = makeStyles(({ spacing, shape }) => {
  return {
    menuButton: {
      color: 'black',
      cursor: 'pointer',
      padding: spacing(1),
      borderRadius: shape.borderRadius,
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
    navMenuItem: {
      textDecoration: 'none',
      fontSize: '20px',
      color: '#000000',
      fontWeight: 'bold',
      padding: spacing(1),
    },
    menuItem: {
      padding: '0 !Important',
    },
    logo: {
      height: 55,
      width: '110px',
      border: 'none',
    },
    popupMenu: {
      marginTop: '60px',
      marginLeft: '10px'
    },
    popoverPaper: {
      minWidth: '250px',
      maxWidth: '250px',
    },
    menuTogglerBtn: {
      position: 'absolute',
      bottom: '10vh',
      zIndex: 2000,
      right: '-20px',
      backgroundColor: '#e0e0d1',
      borderRadius: '50%',
      '&:hover': {
        backgroundColor: '#c3c3c3',
      },
    },
  };
});

export default styles;
