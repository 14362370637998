import { ORS_API_KEY } from '../shared/config/constants';

async function getDistance(url = '', data = {}) {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept:
          'application/json, application/geo+json, application/gpx+xml, img/png; charset=utf-8',
      Authorization: ORS_API_KEY,
    },
    body: JSON.stringify(data),
  });
  const distance = await response.json();
  const latlng1 = data.coordinates[0];
  const latlng2 = data.coordinates[1];
  if (distance?.error) {
    const earthRadiusMiles = 3958.8;
    const dLat = (latlng2[0] - latlng1[0]) * (Math.PI / 180);
    const dLon = (latlng2[1] - latlng1[1]) * (Math.PI / 180);
    const lat1 = latlng1[0] * (Math.PI / 180);
    const lat2 = latlng2[0] * (Math.PI / 180);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const miles = earthRadiusMiles * c;
    return {
      distance: miles,
      route: [latlng1, latlng2]
    };
  }
  return {
    distance: distance.features[0].properties.summary.distance * 0.000621371192, // convert to miles from meters
    route: distance.features[0].geometry.coordinates
  };
}

export default getDistance;
