import React, { useEffect, useRef } from 'react';
import { Box, CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import CanvasJSReact from '../ui/canvasjs/canvasjs.react';
import { useDispatch, useSelector } from 'react-redux';
import Datagrid from '../ui/Datagrid';
import { gridClasses } from '@mui/x-data-grid';
import columns from './table_config';
import { renderCell } from '../ui/Datagrid/table_config';
import { getFieldboxesKPIs, updateFieldboxesKPIs } from '../../store/actions/epicor/epicorBins';
import { FIELDBOX_LASTCHANGED } from '../../utils/constants';
import { RoomRounded } from '@mui/icons-material';

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const chartDefaults = {
  exportEnabled: true,
  animationEnabled: true,
  theme: 'light2',
};

const FieldBoxesKPI = () => {
  const dispatch = useDispatch();
  const epicorBins = JSON.parse(localStorage.getItem('fieldboxes'));
  const fieldboxKPIs = useSelector((state) => state.epicorBins.kpis);
  const totalCount = fieldboxKPIs.chartData?.reduce((total, { y }) => total + y, 0);

  const chartRef = useRef();

  useEffect(() => {
    if (epicorBins?.length) {
      dispatch(updateFieldboxesKPIs(epicorBins));
    } else {
      dispatch(getFieldboxesKPIs());
    }
  }, []);

  const getPinColor = (status) => {
    switch (status) {
      case FIELDBOX_LASTCHANGED.STATUS_ONE:
        return 'green';
      case FIELDBOX_LASTCHANGED.STATUS_TWO:
        return 'amber';
      case FIELDBOX_LASTCHANGED.STATUS_THREE:
        return 'red';
      default:
        return 'red';
    }
  };

  const renderStatusCell = (_props) => {
    const status = _props.row.lastChangedStatus;
    const pinColor = getPinColor(status);

    return (
      pinColor && (
        <Grid container justifyContent="center">
          <IconButton
            key="lastChangedStatus"
            variant="solid"
          >
            <RoomRounded color={`${pinColor}Status`} />
          </IconButton>
        </Grid>
      )
    );
  };

  return (
    <Grid display={'flex'} flexDirection={'column'}>
      {fieldboxKPIs.loading
        ? (
          <Grid container justifyContent={'center'} alignItems={'center'} sx={{ height: '400px' }}>
            <CircularProgress />
          </Grid>
          )
        : (
          <>
          {/* Bar chart - fieldbox KPIs */}
            {fieldboxKPIs.chartData.length
              ? (
              <>
                <Box sx={{ flex: 1 }}>
                  <CanvasJSChart
                  ref={chartRef}
                  options={{
                    ...chartDefaults,
                    height: 300,
                    axisX: {
                      title: 'Days since last visit',
                      labelFontSize: 16,
                      reversed: true
                    },
                    axisY: {
                      title: 'Count',
                      includeZero: true,
                      labelFontSize: 16,
                    },
                    data: [{
                      type: 'bar',
                      indexLabelPlacement: 'outside',
                      indexLabelFontColor: '#000',
                      indexLabelFontWeight: 'bold',
                      indexLabelFontSize: 16,
                      dataPoints: fieldboxKPIs.chartData
                    }]
                  }} />
                  <Typography
                  variant='body1'
                  sx={{
                    background: '#FFF',
                    textAlign: 'end',
                    fontWeight: 'bold',
                    fontSize: '16px',
                    p: 1
                  }}>
                    Total count: {totalCount}
                  </Typography>
                </Box>
              </>
                )
              : (
              <Typography variant='h6' sx={{ textAlign: 'center' }}>
                No data
              </Typography>
                )}

            {/* Pie chart - fieldbox KPIs */}
            {fieldboxKPIs.chartData.length
              ? (
                <>
                  <Box sx={{ flex: 1, marginTop: 1 }}>
                    <CanvasJSChart
                      options={{
                        ...chartDefaults,
                        height: 300,
                        data: [{
                          type: 'pie',
                          startAngle: 75,
                          showInLegend: 'true',
                          toolTipContent: '<b>{label}</b>: {y}',
                          legendText: '{label}',
                          indexLabelFontSize: 16,
                          indexLabel: '{label} - #percent%',
                          dataPoints: fieldboxKPIs.chartData.map(item => ({
                            ...item,
                            indexLabel: ''
                          }))
                        }]
                      }} />
                  </Box>
                </>
                )
              : (
                <Typography variant='h6' sx={{ textAlign: 'center' }}>
                  No data
                </Typography>
                )}
            <Grid item xs={12}>
              <Datagrid
                data={fieldboxKPIs.data}
                getRowId={(row) => row.BinNum}
                columns={columns(renderCell, renderStatusCell)}
                loading={fieldboxKPIs.loading}
                autoHeight={false}
                pageSize={10}
                height={'45vh'}
                getRowHeight={() => 'auto'}
                sx={{
                  '.MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 'bold !important',
                  },
                  [`& .${gridClasses.cell}`]: {
                    py: 1,
                  },
                }}
                onRowClick={({ row }) => {
                  chartRef?.current?.chart?.toolTip?.showAtX(Number(row.x));
                }}
                toolbar={{
                  options: {
                    columns: false,
                    filters: true,
                    export: {
                      enabled: true
                    },
                  },
                }}
              />
            </Grid>
          </>
          )}
    </Grid>
  );
};

export default FieldBoxesKPI;
