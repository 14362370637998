import fetch from '../../../api/fetch';
import { enqueueSnackbar } from '../snackbar';
import { REDUX_ACTIONS } from '../../../shared/config/constants';
import { listEpicorBins } from './epicorBins';

export const epicorSyncShipsTo = () => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.EPICORE_RIGS_SYNC_SHIPSTO_LOADING,
    payload: {
      message: 'Please wait...',
    },
  });

  return fetch
    .post('enverus/syncShipTos')
    .then((resp) => {
      if (!resp.ok) {
        return Promise.reject(resp);
      }
      return resp.json();
    })
    .then((res) => {
      dispatch({
        type: REDUX_ACTIONS.EPICORE_RIGS_SYNC_SHIPSTO_IDLE,
        payload: {
          message: res.message,
        },
      });
      dispatch(listEpicorBins());
    })
    .catch(async (error) => {
      dispatch(
        enqueueSnackbar(
          error.message || 'Error syncing ShipsTo',
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.EPICORE_RIGS_SYNC_SHIPSTO_ERROR,
        payload: {
          message: error.message || 'Error syncing ShipsTo',
        },
      });
    });
};
