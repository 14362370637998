import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import { Box, CircularProgress } from '@mui/material';

function InfiniteScrollList({ currentPage, loadMore, refParentScroll, hasMore, children }) {
  return (
   <InfiniteScroll
    pageStart={currentPage}
    loadMore={loadMore}
    hasMore={hasMore}
    useWindow={!refParentScroll}
    loader={<Box key='loader' display='flex' justifyContent='center' alignItems='center' sx={{ height: '50px', width: '100%' }}>
    <CircularProgress />
    </Box>}
    style={{
      width: '100%',
      margin: '0 auto',
      maxWidth: '420px'
    }}
    >
     {children}
    </InfiniteScroll>
  );
}

InfiniteScrollList.propTypes = {
  currentPage: PropTypes.number.isRequired,
  loadMore: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  refParentScroll: PropTypes.bool
};

InfiniteScrollList.defaultProps = {
  refParentScroll: false
};

export default InfiniteScrollList;
