import React from 'react';
import PropTypes from 'prop-types';
import ModalDialog from '../ModalDialog';
import { Box, Grid, Typography } from '@mui/material';

import Premium from '../../../assets/images/PRO logo PMS green only.svg';
import styles from './styles';
import classNames from 'classnames';
import ButtonComponent from '../../Button';

const OrderProcessingModalDialog = ({
  isOpen,
  isProcessing,
  closeLoadingDialog,
}) => {
  const classes = styles();

  return (
    <ModalDialog isOpen={isOpen} isFullScreen={false}>
      <Grid container>
        <Grid item xs={12}>
          <Box
            component="img"
            alt="Logo"
            src={Premium}
            className={classes.logo}
          />
        </Grid>{' '}
        <Grid item xs={12}>
          <Box style={{ margin: '70px 10px 120px 10px' }} textAlign="center">
            {isProcessing
              ? <Typography variant="h6" style={{ fontSize: '35px' }}>
                  Processing
                </Typography>
              : <Typography variant="h6" style={{ fontSize: '35px' }}>
                Thank You!
              </Typography>
            }
            <Typography
              variant="h6"
              style={{ visibility: isProcessing ? 'hidden' : 'visible' }}
            >
              A confirmation has been sent via Email.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box style={{ margin: '10px' }} textAlign="center">
            <ButtonComponent
              label='Close'
              autoFocus
              variant={'contained'}
              size={'small'}
              className={classNames(classes.actionButton)}
              disabled={isProcessing}
              onClick={() => {
                closeLoadingDialog();
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </ModalDialog>
  );
};

OrderProcessingModalDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool,
  title: PropTypes.string,
  closeLoadingDialog: PropTypes.func,
};

export default OrderProcessingModalDialog;
