/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import styles from './styles';
import { Box, Fade, Grid, IconButton, Modal, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { CancelPresentationRounded, PrintRounded } from '@mui/icons-material';
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import { enqueueSnackbar } from '../../store/actions/snackbar';
import { useDispatch } from 'react-redux';
import { CALENDER_PROPS } from '../../utils/constants';

const CustomModal = (props) => {
  const {
    title,
    open,
    onClose,
    children,
    cta1Action,
    showCta2,
    cta2Disabled,
    cta2Action,
    showDateTimeRange,
    onDateTimeRangeChange,
    showForwardRewindIcon
  } = props;
  const classes = styles();
  const [selectedStartDateTime, setSelectedStartDateTime] = useState(new Date());
  const [selectedEndDateTime, setSelectedEnDateTime] = useState(new Date());
  const [timeDiff, setTimeDiff] = useState(0);
  const dispatch = useDispatch();
  const handleClose = () => {
    setSelectedStartDateTime(null);
    setSelectedEnDateTime(null);
    onClose && onClose();
  };

  React.useEffect(() => {
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 1);
    setSelectedStartDateTime(startDate);
    setSelectedEnDateTime(new Date());
  }, [open]);

  React.useEffect(() => {
    const currentStartDate = new Date(selectedStartDateTime);
    const currentEndDate = new Date(selectedEndDateTime);
    if (currentEndDate < currentStartDate) {
      dispatch(
        enqueueSnackbar(
          'Please select valid end date',
          'error',
          new Date().getTime() + Math.random()
        )
      );
      return;
    }
    if (selectedStartDateTime && selectedEndDateTime) {
      setTimeDiff((currentEndDate.getTime() - currentStartDate.getTime()) / 1000);
    }
  }, [selectedStartDateTime, selectedEndDateTime]);

  const handleStartTime = (date) => {
    setSelectedStartDateTime(date);
    onDateTimeRangeChange && onDateTimeRangeChange(date, selectedEndDateTime);
  };
  const handleEndTime = (date) => {
    setSelectedEnDateTime(date);
    onDateTimeRangeChange && onDateTimeRangeChange(selectedStartDateTime, date);
  };

  const handleTimeShift = (type) => {
    const currentStartDate = new Date(selectedStartDateTime);
    const currentEndDate = new Date(selectedEndDateTime);
    const updatedStartDateTime = new Date(currentStartDate.setSeconds(type === 'increase' ? (currentStartDate.getSeconds() + timeDiff) : (currentStartDate.getSeconds() - timeDiff)));
    const updatedEndDateTime = new Date(currentEndDate.setSeconds(type === 'increase' ? (currentEndDate.getSeconds() + timeDiff) : (currentEndDate.getSeconds() - timeDiff)));
    if (updatedEndDateTime > new Date()) {
      dispatch(
        enqueueSnackbar(
          'Please select valid end date',
          'error',
          new Date().getTime() + Math.random()
        )
      );
    }
    setSelectedStartDateTime(updatedStartDateTime);
    setSelectedEnDateTime(updatedEndDateTime);
    onDateTimeRangeChange && onDateTimeRangeChange(updatedStartDateTime, updatedEndDateTime);
  };

  const getTimeDifference = timeDiff / (24 * 60 * 60);

  return (
    <Modal
     disableEnforceFocus
      open={open}
      onClose={handleClose}
      className={classes.modal}
      closeAfterTransition
    >
      <Fade in={open}>
        <div className={classes.paper}>
        <Grid item xs={12} container
          style={{
            flexWrap: title ? 'nowrap' : 'none'
          }}
        >
          <Grid item xs={5} container alignItems='center'>
          {title
            ? (
          <Typography variant='h4' id="transition-modal-title">{title}</Typography>
              )
            : null}
          </Grid>
          <Grid item xs={12} container justifyContent='flex-end'>
            {showForwardRewindIcon && <Box className={classes.container}>
            <IconButton
              onClick={() => handleTimeShift('decrease')}
              disabled={getTimeDifference > 8}
            >
              <FastRewindIcon
                  fontSize='large'/>
            </IconButton>
            <IconButton
                onClick={() => handleTimeShift('increase')}
                disabled={getTimeDifference > 8}
            >
              <FastForwardIcon
                  fontSize='large'/>
            </IconButton>
            </Box>}
            {showDateTimeRange && (
              <Box className={classes.container}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    {...CALENDER_PROPS}
                    disableFuture
                    label="Start Date"
                    value={selectedStartDateTime}
                    onChange={handleStartTime}
                    className={classes.dateTimePicker}
                    classes={{
                      switchHeader: classes.datePickerHeader,
                    }}
                    textField={(params) => <TextField {...params} />}
                  />
                  <DateTimePicker
                   {...CALENDER_PROPS}
                    disableFuture
                    label="End Date"
                    value={selectedEndDateTime}
                    onChange={handleEndTime}
                    className={classes.dateTimePicker}
                    disabled={!selectedStartDateTime}
                    minDate={selectedStartDateTime}
                    maxDateTime={selectedStartDateTime ? new Date(selectedStartDateTime.getTime() + (7 * 24 * 60 * 60 * 1000) + (59 * 1000)) : null}
                    textField={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Box>
            )}
            {showCta2 && (
              <IconButton
                disabled={cta2Disabled}
                onClick={cta2Action}
               >
                 <PrintRounded fontSize='large'/>
               </IconButton>
            )}
            <Box>
            <IconButton
             onClick={cta1Action || handleClose}
            >
              <CancelPresentationRounded fontSize='large'/>
            </IconButton>
            </Box>
          </Grid>
        </Grid>
          {children}
        </div>
      </Fade>
    </Modal>
  );
};

export default CustomModal;
