import { REDUX_ACTIONS } from '../../shared/config/constants';

const initialState = {
  loading: false,
  deleting: false,
  data: [],
  errorMessage: '',
  loadingDelete: false,
  saved: false,
  paginatedElements: 0,
  limit: 100,
  currentPage: 1,
  totalCount: 0,
  searchValue: null,
  startDate: null,
  endDate: null,
  filters: [],
  sort: null,
};

const alarmHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.ALARM_HISTORY_LIST_LOADING:
      return {
        ...state,
        loading: true,
        errorMessage: '',
      };
    case REDUX_ACTIONS.ALARM_HISTORY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...action.payload.alarmHistory],
        paginatedElements: action.payload.paginatedElements,
        totalCount: action.payload.totalCount,
        errorMessage: '',
      };
    case REDUX_ACTIONS.ALARM_HISTORY_LIST_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.message,
        data: [],
      };
    case REDUX_ACTIONS.ALARM_HISTORY_SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case REDUX_ACTIONS.ALARM_HISTORY_SEARCH_CONTROL:
      return {
        ...state,
        searchValue: action.payload.searchValue,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate
      };
    case REDUX_ACTIONS.ALARM_HISTORY_SET_FILTER:
      return {
        ...state,
        filters: action.payload
      };
    case REDUX_ACTIONS.ALARM_HISTORY_SET_SORT:
      return {
        ...state,
        sort: action.payload
      };
    default:
      return state;
  }
};

export default alarmHistoryReducer;
