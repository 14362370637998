import React from 'react';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';

export default function PageTitle() {
  const { pageTitle } = useSelector((state) => state.menu);

  return <>
    <Typography
      variant="h5"
      component="div"
      sx={{ flexGrow: 1 }}
      color="ButtonText"
    >
      {pageTitle}
    </Typography>
  </>;
}
