export const gridColumnGenerator = (defaultVisibleFields, data, renderCell, renderSyncCell) => {
  if (!Array.isArray(data) || data.length === 0) {
    return [];
  }

  const firstDataItem = data[0];
  const columnKeys = Object.keys(firstDataItem);
  if (renderSyncCell) {
    columnKeys.splice(4, 0, 'sync');
  }

  const columns = columnKeys.map((key) => {
    const fieldConfig = defaultVisibleFields.find((field) => field.field === key);
    const isVisible = fieldConfig !== undefined;
    return {
      field: key,
      headerName: key.charAt(0).toUpperCase() + key.slice(1),
      width: isVisible ? (fieldConfig.width || 200) : 200,
      minWidth: 120,
      hide: !isVisible,
      renderCell: key === 'sync' ? renderSyncCell : renderCell,
      verticalAlign: 'middle',
      headerAlign: 'center',
      align: 'center',
      editable: fieldConfig?.editable ?? fieldConfig?.editable,
    };
  });

  return columns;
};
