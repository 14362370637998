import { AddLocationAltRounded, AspectRatioRounded } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import useAspectRatio from '../../../../hooks/useAspectRatio';
import { isMobile } from 'react-device-detect';
import { addNewJob } from '../../../../store/actions/jobs';

const JobsControls = () => {
  const dispatch = useDispatch();
  const handleAspectRatio = useAspectRatio();
  const { preferences } = useSelector((state) => state.currentUser);

  if (isMobile) return null;

  const handleAddToJobs = () => {
    dispatch(addNewJob());
  };

  return (
    <Box>
      <IconButton
      edge="end"
      onClick={handleAddToJobs}
    >
      <AddLocationAltRounded />
      </IconButton>
      <IconButton
        edge="end"
        onClick={() => {
          handleAspectRatio(!preferences.aspectChanged);
        }}
        sx={{ marginLeft: 2 }}
      >
        <AspectRatioRounded />
      </IconButton>
    </Box>
  );
};

export default JobsControls;
