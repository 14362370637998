import React, { useEffect, useState } from 'react';
import { COMPANY_TYPE, USER_TYPE } from '../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import Datagrid from '../ui/Datagrid';
import columns from './table_config';
import {
  listCompanies,
  listSellersCustomers,
  getCustomersSellers,
  updateCompany,
  createCompany
} from '../../store/actions/companies';
import { useAuth } from '../../hooks/useAuth';
import { Grid, IconButton } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { renderCell } from '../ui/Datagrid/table_config';
import { SellRounded, WidgetsRounded, SaveRounded, ContactMailRounded, PlaylistAddCheck, Person } from '@mui/icons-material';
import SellersModalDialog from '../ui/SellersModalDialog';
import PropTypes from 'prop-types';
import { REDUX_ACTIONS } from '../../shared/config/constants';
import SelectEditInputCell from '../ui/SelectEditInputCell';
import { enqueueSnackbar } from '../../store/actions/snackbar';
import SpecialInstructionsDialog from '../ui/SpecialInstructionsDialog';
import PersonnelDialog from '../ui/PersonnelDialog';

const Company = () => {
  const companies = useSelector((state) => state.companies);
  const [currentCompany, setCurrentCompany] = useState();
  const [isSellerDialogOpen, setIsSellerDialogOpen] = useState(false);
  const [specialInstructionDialogOpen, setSpecialInstructionDialogOpen] = useState({
    open: false,
    data: null
  });
  const [personnelDialogOpen, setPersonnelDialogOpen] = useState({
    open: false,
    data: null
  });
  const [emailEditable, setEmailEditable] = useState(false);
  const [gridColumns, setGridColumns] = useState([]);

  const { user } = useAuth();

  const apiRef = useGridApiRef();
  const dispatch = useDispatch();

  useEffect(() => {
    // clear companies list from redux store
    dispatch({
      type: REDUX_ACTIONS.COMPANIES_LIST_CLEAR,
    });
    // return if is loading
    if (companies.loading) {
      return;
    }
    // role base api call for companies
    if (user.type === USER_TYPE.SELLER_MARKETPLACE_ADMIN) {
      dispatch(listSellersCustomers(user));
    } else if (user.type === USER_TYPE.CUSTOMER_MANAGER || user.type === USER_TYPE.CUSTOMER_ADMIN || user.type === USER_TYPE.CUSTOMER_USER || user.type === USER_TYPE.CUSTOMER_RIG) {
      dispatch(getCustomersSellers(user));
    } else {
      dispatch(listCompanies(user));
    }
  }, []);

  useEffect(() => {
    if (companies?.data[companies?.data?.length - 1]?.name === '') {
      const id = companies?.data[companies?.data?.length - 1]._id;
      setTimeout(() => {
        apiRef.current.scrollToIndexes({
          rowIndex: apiRef.current.getRowsCount() - 1
        });
        apiRef.current.startCellEditMode({ id, field: 'name' });
        setTimeout(() => {
          apiRef.current.setCellFocus(id, 'name');
        }, 50);
      }, 50);
    }
  }, [companies.data.length]);

  useEffect(() => {
    if (!companies.loading && companies.data.length > 0) {
      const showSellersList = ['PREMIUM_MARKETPLACE_ADMIN', 'PREMIUM_ADMIN'];
      let columnsList = columns(renderCell, renderSellersCell, renderInventoryCell, renderActionCell, renderTypeCell, renderContactCell, user.type, renderSpecialInstructionsCell, emailEditable, renderPersonnelCell);

      if (user.type !== USER_TYPE.PREMIUM_ADMIN) {
        const filterTerms = ['legalName', 'type', 'phone', 'action'];
        columnsList = columnsList.filter(obj => !filterTerms.includes(obj.field));
      }

      if (!showSellersList.includes(user.type)) {
        const filterTerms = ['sellers'];
        columnsList = columnsList.filter(obj => !filterTerms.includes(obj.field));
      }

      setGridColumns(columnsList);
      apiRef.current.updateColumns(columnsList);
    }
  }, [companies.data, emailEditable]);

  const showSellersDialog = (company) => {
    setCurrentCompany(company);
    setIsSellerDialogOpen(true);
  };

  const renderSellersCell = (props) => {
    return (
      <Grid
        container
        justifyContent="center"
        style={
          props.row.type === COMPANY_TYPE.CUSTOMER && !props?.row?.isNewRow
            ? { visibility: 'visible' }
            : { visibility: 'hidden' }
        }
      >
        <IconButton
          variant="solid"
          onClick={(_evt) => {
            if (user.type === USER_TYPE.SELLER_MARKETPLACE_ADMIN) {
              window.open(
                `/Shop?companyId=${props.row._id}&vendorId=${user.companyId}&name=${props.row.name}`,
                '_blank'
              );
            } else {
              showSellersDialog(props.row);
            }
          }}
        >
          <SellRounded />
        </IconButton>
      </Grid>
    );
  };

  const renderSpecialInstructionsCell = (props) => {
    return (
      <Grid
        container
        justifyContent="center"
        style={
          props.row.type === 'MARKETPLACE_CUSTOMER' && !props?.row?.isNewRow
            ? { visibility: 'visible' }
            : { visibility: 'hidden' }
        }
      >
        <IconButton
          variant="solid"
          sx={{ color: !_.isEmpty(props.row?.specialInstructions) && 'green' }}
          onClick={(_evt) => {
            setSpecialInstructionDialogOpen({
              open: true,
              data: props.row
            });
          }}
        >
          <PlaylistAddCheck fontSize='large' />
        </IconButton>
      </Grid>
    );
  };

  const renderPersonnelCell = (props) => {
    return (
      <Grid
        container
        justifyContent="center"
        style={
          props.row.type === 'MARKETPLACE_CUSTOMER' && !props?.row?.isNewRow
            ? { visibility: 'visible' }
            : { visibility: 'hidden' }
        }
      >
        <IconButton
          variant="solid"
          sx={{ color: !_.isEmpty(props.row?.orderApprovers || props.row?.quoteApprovers || props.row?.admins) && 'green' }}
          onClick={(_evt) => {
            setPersonnelDialogOpen({
              open: true,
              data: props.row
            });
          }}
        >
          <Person fontSize='large' />
        </IconButton>
      </Grid>
    );
  };

  const renderTypeCell = (params) => {
    return <SelectEditInputCell {...params} closeCellAfterSelect={true} data={['CUSTOMER', 'SELLER', 'MPD_CUSTOMER', 'MARKETPLACE_CUSTOMER']} />;
  };

  const checkValidFields = (row) => {
    const requiredFields = ['name', 'legalName', 'type', 'address1', 'postalCode', 'country', 'state'];
    let isValid = true;

    requiredFields.forEach(fieldName => {
      if (!row?.[fieldName]) {
        dispatch(
          enqueueSnackbar(
            `Please enter ${fieldName === 'address1' ? 'Address' : fieldName}`,
            'error',
            new Date().getTime() + Math.random()
          )
        );
        isValid = false;
      }
    });

    return isValid;
  };

  const saveCompanyDetails = (row) => {
    if (checkValidFields(row)) {
      const companyRow = { ...row };
      if (companyRow?.type !== 'MARKETPLACE_CUSTOMER') {
        companyRow.specialInstructions = {};
      }
      const body = {
        company: companyRow,
        companyId: companyRow?._id
      };
      if (!companyRow?.isNewRow) {
        dispatch(updateCompany(body));
      } else {
        delete body.companyId;
        dispatch(createCompany(body));
      }
    }
  };

  const renderContactCell = (props) => {
    return (
      <Grid container justifyContent="center">
        <IconButton
              variant="solid"
              onClick={() => {
                window.location.href = `mailto:${props?.row?.marketplaceAdminEmail ?? ''}`;
              }}>
          <ContactMailRounded />
        </IconButton>
      </Grid>);
  };

  const renderActionCell = ({ row }) => {
    return (
      <Grid container justifyContent="center">
        <IconButton
          variant="solid"
          onClick={() => {
            saveCompanyDetails(row);
          }}
        >
          <SaveRounded />
        </IconButton>
      </Grid>
    );
  };

  const renderInventoryCell = (props) => {
    return (
      <Grid
        container
        justifyContent="center"
        style={ user.type === USER_TYPE.PREMIUM_ADMIN || user.type === USER_TYPE.PREMIUM_MARKETPLACE_ADMIN || user.type === USER_TYPE.SELLER_MARKETPLACE_ADMIN
          ? props.row.type === COMPANY_TYPE.CUSTOMER || props.row.type === COMPANY_TYPE.PREMIUM
            ? { visibility: 'visible' }
            : { visibility: 'hidden' }
          : user.type === USER_TYPE.CUSTOMER_MANAGER || user.type === USER_TYPE.CUSTOMER_ADMIN || user.type === USER_TYPE.CUSTOMER_USER || user.type === USER_TYPE.CUSTOMER_RIG
            ? props.row.type === COMPANY_TYPE.SELLER || props.row.type === COMPANY_TYPE.PREMIUM
              ? { visibility: 'visible' }
              : { visibility: 'hidden' }
            : { visibility: 'hidden' }
        }
      >
        <IconButton
          variant="solid"
          onClick={(_evt) => {
            let url = `/Shop?companyId=${props.row._id}&vendorId=${user.companyId}&name=${props.row.name}`;
            if (user.type === USER_TYPE.CUSTOMER_MANAGER || user.type === USER_TYPE.CUSTOMER_ADMIN || user.type === USER_TYPE.CUSTOMER_USER) {
              url = `/Shop?companyId=${user.companyId}&vendorId=${props.row._id}&name=${props.row.name}`;
            }
            if (user.type === USER_TYPE.CUSTOMER_RIG) {
              url = `/Shop?companyId=${user.userId}&vendorId=${props.row._id}&name=${props.row.name}`;
            }
            window.open(url, '_blank');
          }}
        >
          <WidgetsRounded />
        </IconButton>
      </Grid>
    );
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Datagrid
          data={companies.data}
          autoHeight={false}
          columns={gridColumns}
          loading={companies.loading}
          pageSize={100}
          disableStickyHeader={false}
          apiRef={apiRef}
          height={'90vh'}
          toolbar={{
            options: {
              columns: true,
              filters: true,
              density: true,
              export: false,
            },
          }}
          experimentalFeatures={{ newEditingApi: true }}
          onSelectionModelChange={(ids) => {
            const type = apiRef?.current.getSelectedRows().get(ids[0])?.type;
            const isEmailEditable = type === COMPANY_TYPE.SELLER || type === COMPANY_TYPE.PREMIUM;
            setEmailEditable(isEmailEditable);
          }}
        />
      </Grid>
      <SellersModalDialog
        isOpen={isSellerDialogOpen}
        onActionClick={() => {}}
        onCloseClick={() => {
          setIsSellerDialogOpen(false);
        }}
        selectedCompany={currentCompany}
      />
      {specialInstructionDialogOpen.open && (
        <SpecialInstructionsDialog
          isOpen={specialInstructionDialogOpen.open}
          data={specialInstructionDialogOpen.data}
          onCloseClick={() => {
            setSpecialInstructionDialogOpen({
              open: false,
              data: {}
            });
          }}
          handleSave={saveCompanyDetails}
        />
      )}
      {personnelDialogOpen.open && (
        <PersonnelDialog
          open={personnelDialogOpen.open}
          handleClose={() => {
            setPersonnelDialogOpen({
              open: false,
              data: {}
            });
          }}
          rowData={personnelDialogOpen.data}
          handleSave={saveCompanyDetails}
        />
      )}
    </Grid>
  );
};

Company.propTypes = {
  row: PropTypes.object,
};

export default Company;
