const masterColumn = [
  {
    field: 'OrderNum',
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'ReqDate',
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'CustomerName',
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'BinNum_c',
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center',
  }
];

const columns = [
  {
    field: 'PartNum',
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'LineDesc',
    width: 800,
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'UnitPrice',
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'OrderQty',
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'ExtPriceDtl',
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center',
  }
];

export { columns, masterColumn };
