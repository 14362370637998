import React, { useEffect, useState } from 'react';
import { Typography, IconButton } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { NotificationsNoneRounded } from '@mui/icons-material';
import AlarmsModalDialog from '../../../ui/AlarmsModalDialog';
import { getJobById } from '../../../../store/actions/jobs';

export default function AlarmNotification() {
  const dispatch = useDispatch();
  const { pageTitle } = useSelector((state) => state.menu);
  const [alarmModalOpen, setAlarmModalOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState(false);
  const jobs = useSelector((state) => state.jobs);

  // update jobs data every 2 minutes to get updated alarms
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getJobById(jobs.currentJob._id));
    }, 120 * 1000);
    return () => clearInterval(interval);
  }, []);

  const handleAlarmsModal = (overallStatus) => {
    if (overallStatus > 0) {
      setAlarmModalOpen(true);
    }
  };

  useEffect(() => {
    const selectedJob = jobs.data.find((element) => {
      return element._id === jobs.currentJob._id;
    });
    setSelectedJob(selectedJob);
  }, [jobs.data]);

  return <>
    <Typography
      variant="h5"
      component="div"
      sx={{ flexGrow: 1 }}
      color="ButtonText"
    >
      {pageTitle}
      {jobs.data.length > 0 && selectedJob && (
        <IconButton
          key="actuator_data"
          variant="solid"
          style={{ marginLeft: 10 }}
          onClick={() => {
            handleAlarmsModal(selectedJob?.alarms.overallStatus);
          }}
        >
          <NotificationsNoneRounded
            color={`alarm${selectedJob.alarms.overallStatus}`}
            style={{ fontSize: 24 }}
          />
        </IconButton>
      )}
      {selectedJob && (
        <AlarmsModalDialog
          isOpen={alarmModalOpen}
          onCloseClick={() => setAlarmModalOpen(false)}
          selectedJob={selectedJob}
        />
      )}
    </Typography>
  </>;
}
