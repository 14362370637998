import { GridEditInputCell } from '@mui/x-data-grid-pro';
import { renderCustomPrice } from '../../../utils/renderCustomPrice';

const columns = (
  renderCell,
  renderCustomCell,
  truckValue
) => [
  {
    field: 'PartNum',
    headerName: 'Part Number',
    width: 150,
    renderCell,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'PartDescription',
    headerName: 'Description',
    width: 300,
    renderCell: renderCustomCell,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'BasePrice',
    headerName: 'Price',
    width: 100,
    renderCell: renderCustomPrice,
    headerAlign: 'center',
    align: 'center',
    type: 'number',
  },
  {
    field: 'QtyOnHand',
    headerName: 'Last Count',
    width: 100,
    renderCell,
    headerAlign: 'center',
    align: 'center',
    type: 'number',
  },
  {
    field: 'newCount',
    headerName: 'New Count',
    width: 100,
    renderCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    type: 'number',
    renderEditCell: (params) => (
      <GridEditInputCell
        {...params}
        inputProps={{
          min: 0,
          max: params.row.QtyOnHand - 1,
        }}
      />
    ),
  },
  {
    field: 'billOut',
    headerName: 'Bill Out',
    width: 100,
    renderCell,
    headerAlign: 'center',
    align: 'center',
    type: 'number',
    renderEditCell: (params) => (
      <GridEditInputCell
        {...params}
        inputProps={{
          min: 0,
        }}
      />
    ),
  },
  {
    field: 'added',
    headerName: 'Added',
    width: 100,
    renderCell,
    editable: truckValue || false,
    headerAlign: 'center',
    align: 'center',
    type: 'number',
    renderEditCell: (params) => (
      <GridEditInputCell
        {...params}
        inputProps={{
          min: 1,
        }}
      />
    ),
  },
  {
    field: 'total',
    headerName: 'Total',
    width: 200,
    renderCell,
    headerAlign: 'center',
    align: 'center',
    type: 'number',
  },
];

export default columns;
