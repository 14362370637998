import { makeStyles } from '@mui/styles';

const styles = makeStyles((theme) => {
  return {
    dateTimePicker: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
      alignSelf: 'flex-start',
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  };
});

export default styles;
